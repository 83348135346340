import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { TipoServico } from './Servicos'
interface PropsModal {
  show: boolean
  onClose: () => void
  servico: TipoServico
}

interface DetalhesLavagem {
  nome: string
  image: string
  idealPara: string
  quandoFazer: string
  inclui: string[]
}

export default class Modal extends React.Component<PropsModal> {
  private database: Map<TipoServico, DetalhesLavagem>

  constructor(props: PropsModal) {
    super(props)
    this.database = new Map()
    this.database.set(TipoServico.LAVAGEM_INTERNA, {
      nome: 'LAVAGEM ECOLÓGICA INTERNA',
      image: 'serv_poltrona',
      idealPara: 'Quando seu carro está limpo por fora e sujo por dentro.',
      quandoFazer: 'Quatro vezes por mês.',
      inclui: [
        'Limpeza Interna completa com revitalização dos plásticos no interior do veículo e aspiração interna completa e porta malas.',
      ],
    })

    this.database.set(TipoServico.LAVAGEM_EXTERNA, {
      nome: 'LAVAGEM ECOLÓGICA EXTERNA',
      image: 'serv_carro',
      idealPara:
        'Quando seu carro está limpo por dentro e sujo por fora. Mantém seu carro limpo e pronto para o dia a dia.',
      quandoFazer: 'Até três vezes ao mês.',
      inclui: ['Lavagem externa a seco.', 'Limpeza caixa de rodas, rodas e pneus'],
    })

    this.database.set(TipoServico.LAVAGEM_COMPLETA, {
      nome: 'LAVAGEM ECOLÓGICA COMPLETA',
      image: 'serv_spray',
      idealPara: 'Manter seu carro totalmente limpo e pronto para o dia a dia.',
      quandoFazer: 'Até três vezes ao mês.',
      inclui: [
        'Limpeza Interna completa com revitalizado dos plásticos no interior do veículo.',
        'Aspiração interna completa e porta malas.',
        'Lavagem externa a seco.',
        'Limpeza caixa de Rodas, rodas e Pneus.',
      ],
    })

    this.database.set(TipoServico.PROTECT, {
      nome: 'PROTECT',
      image: 'serv_protect',
      idealPara: 'Manter seu carro totalmente limpo e com a lataria totalmente protegida, pronto para o dia a dia.',
      quandoFazer: 'Até três vezes ao mês.',
      inclui: [
        'Limpeza Interna completa com revitalizado dos plásticos no interior do veículo.',
        'Aspiração interna completa e porta malas.',
        'Lavagem externa a seco.',
        'Limpeza caixa de Rodas, rodas e Pneus.',
        'Enceramento lataria com cera carnaúba.',
      ],
    })

    this.database.set(TipoServico.MEGA, {
      nome: 'MEGA',
      image: 'serv_mega',
      idealPara: 'Manter seu carro totalmente limpo, com a lataria protegida e vidros desembaçados.',
      quandoFazer: 'Até três vezes ao mês.',
      inclui: [
        'Limpeza Interna completa com revitalizado dos plásticos no interior do veículo.',
        'Aspiração interna completa e porta malas.',
        'Lavagem externa a seco.',
        'Limpeza caixa de Rodas, rodas e Pneus.',
        'Enceramento lataria com cera carnaúba.',
        'Anti embaçante para brisa interno.',
      ],
    })

    this.database.set(TipoServico.PREMIUM, {
      nome: 'PREMIUM',
      image: 'serv_premium',
      idealPara:
        'Manter seu carro totalmente limpo, com a lataria protegida, para brisa limpos, vidros desembaçados e cristalizados e odores neutralizados.',
      quandoFazer: 'Até duas vezes por mês.',
      inclui: [
        'Limpeza Interna completa com revitalizado dos plásticos no interior do veículo.',
        'Aspiração interna completa e porta malas.',
        'Lavagem externa a seco.',
        'Limpeza caixa de Rodas, rodas e Pneus.',
        'Enceramento lataria com cera carnaúba.',
        'Anti embaçante para brisa interno.',
        'Limpa Para Brisa.',
        'Cristalização de Vidros.',
        'Neutralizador de Odores.',
      ],
    })

    this.database.set(TipoServico.PRO, {
      nome: 'PRO',
      image: 'serv_pro',
      idealPara:
        'Manter seu carro totalmente limpo, com a lataria protegida, para brisa limpos, vidros desembaçados e cristalizados, odores neutralizados e bancos de tecido ou de couro limpos, hidratados e higienizados.',
      quandoFazer: 'A cada dois meses.',
      inclui: [
        'Limpeza Interna completa com revitalizado dos plásticos no interior do veículo.',
        'Aspiração interna completa e porta malas.',
        'Lavagem externa a seco.',
        'Limpeza caixa de Rodas, rodas e Pneus.',
        'Enceramento lataria com cera carnaúba.',
        'Anti embaçante para brisa interno.',
        'Limpa Para Brisa.',
        'Cristalização de Vidros.',
        'Neutralizador de Odores.',
        'Limpeza e Hidratação / Higienização Banco de Couro ou Tecido.',
      ],
    })
  }

  render(): JSX.Element {
    if (!this.props.show) {
      return <div></div>
    }

    const detalhes = this.database.get(this.props.servico)

    if (!detalhes) {
      return <div></div>
    }

    return (
      <div>
        <div className="escurecer"></div>
        <div
          className="portfolio-modal modal show fade"
          role="dialog"
          id="portfolioModal1"
          tabIndex={1000}
          aria-labelledby="portfolioModal1Label"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <button
                onClick={this.props.onClose}
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span>
                  <FontAwesomeIcon className="verde-claro" icon={faTimes} color="white" size="xs" />
                </span>
              </button>
              <div className="modal-body">
                <div className="container">
                  <div className="row justify-content-center">
                    <div>
                      <div className="text-center">
                        <h2 className="portfolio-modal-title text-secondary text-uppercase" id="portfolioModal1Label">
                          {detalhes.nome}
                        </h2>
                        <img
                          className="img-fluid rounded mb-5"
                          src={process.env.PUBLIC_URL + `/assets/img/${detalhes.image}.png`}
                          alt=""
                        />
                      </div>
                      <p className="modaltexto">
                        <span className="modaltextodetalhe">Ideal para:</span> {detalhes.idealPara}
                      </p>
                      <p className="modaltexto">
                        <span className="modaltextodetalhe">Quando fazer:</span> {detalhes.quandoFazer}
                      </p>
                      <p className="modaltexto">
                        <span className="modaltextodetalhe">Inclui:</span>{' '}
                        {detalhes.inclui.map<JSX.Element>((value, idx) => {
                          return (
                            <span key={idx}>
                              {value}
                              <br />
                            </span>
                          )
                        })}
                      </p>

                      <div className="text-center">
                        <button onClick={this.props.onClose} className="btn btn-primary" data-dismiss="modal">
                          <FontAwesomeIcon icon={faTimes} color="white" size="1x" /> Fechar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
