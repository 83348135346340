import { createStore, Store, combineReducers } from 'redux'
import loginReducer from './administrador/reducer'
import modelsReducer from './models/reducer'
import { persistStore } from 'redux-persist'
import { ServicosPrestadosState } from './models/servicosprestados/types'
import { TiposServicosState } from './models/tiposservicos/types'
import { LoginState } from './administrador/login/types'
import { CuponsState } from './models/cupons/types'
import { ParceirosState } from './models/parceiros/types'
import { ClientesState } from './models/clientes/types'
import { TiposVeiculosState } from './models/tiposveiculos/types'
import { ProdutosState } from './models/produtos/types'
import { ExtratosPagamentoState } from './models/extratospagamento/types'
import { CidadesAtuaState } from './models/cidadesatua/types'
import { EnderecosState } from './models/enderecos/types'

export interface ApplicationState {
  login: LoginState
  models: ModelsState
}

export interface ModelsState {
  servicosPrestados: ServicosPrestadosState
  tiposServicos: TiposServicosState
  cupons: CuponsState
  parceiros: ParceirosState
  enderecos: EnderecosState
  clientes: ClientesState
  tiposVeiculos: TiposVeiculosState
  produtos: ProdutosState
  extratosPagamento: ExtratosPagamentoState
  cidadesAtua: CidadesAtuaState
}
const combinedReducers = combineReducers({
  login: loginReducer,
  models: modelsReducer,
})

const store: Store<ApplicationState> = createStore(combinedReducers)
const persistor = persistStore(store)

export { store, persistor }
