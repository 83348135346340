import TipoServico from '../../../models/TipoServico'

export enum TiposServicosTypes {
  ATUALIZAR_TIPOS_SERVICOS = '@tiposservicos/ATUALIZAR_TODOS',
  ADICIONAR_TIPO_SERVICO = '@tiposservicos/ADICIONAR',
  REMOVER_TIPO_SERVICO = '@tiposservicos/REMOVER',
  MODIFICAR_TIPO_SERVICO_SELECIONADO = '@tiposservicos/MODIFICAR_SELECIONADO',
  LIMPAR_TIPOS_SERVICOS = '@tiposservicos/LIMPAR',
  MODIFICAR_LISTA_CARREGADA = '@tiposservicos/MODIFICAR_LISTA_CARREGADA',
}

export interface TiposServicosState {
  readonly data: TipoServico[]
  readonly selecionado: TipoServico
  readonly listaCarregada: boolean
}

export interface AtualizarTiposServicos {
  type: typeof TiposServicosTypes.ATUALIZAR_TIPOS_SERVICOS
}

export interface AdicionarTipoServico {
  type: typeof TiposServicosTypes.ADICIONAR_TIPO_SERVICO
}

export interface RemoverTipoServico {
  type: typeof TiposServicosTypes.REMOVER_TIPO_SERVICO
}

export interface LimparTiposServicos {
  type: typeof TiposServicosTypes.LIMPAR_TIPOS_SERVICOS
}

export interface ModificarTipoServicoSelecionado {
  type: typeof TiposServicosTypes.MODIFICAR_TIPO_SERVICO_SELECIONADO
}

export interface ModificarListaCarregadaTipoServicos {
  type: typeof TiposServicosTypes.MODIFICAR_LISTA_CARREGADA
}
