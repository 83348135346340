import Cliente from '../models/Cliente'
import ClienteRepository from '../repositories/ClienteRepository'

class ClienteController {
  pegarTodos(recursive?: string[]): AsyncGenerator<Cliente, void, Cliente> {
    return ClienteRepository.pegarTodos(recursive)
  }

  pegarQuantidadeDeClientes(): Promise<number> {
    return ClienteRepository.pegarQuantidadeDeClientes()
  }

  pegarPorId(documentId: string, recursive?: string[]): Promise<Cliente> {
    return ClienteRepository.pegarPorId(documentId, recursive)
  }

  pegarInformacoesClientePorPid(cliente: Cliente): Promise<Cliente> {
    return ClienteRepository.pegarInformacoesCliente(cliente)
  }

  alterar(cliente: Cliente): Promise<Cliente> {
    return ClienteRepository.alterar(cliente)
  }

  deletar(cliente: Cliente): Promise<Cliente> {
    return ClienteRepository.deletar(cliente)
  }
}

export default new ClienteController()
