import Parceiro from '../../../models/Parceiro'

export enum ParceirosTypes {
  ATUALIZAR_PARCEIROS = '@parceiros/ATUALIZAR_TODOS',
  ADICIONAR_PARCEIRO = '@parceiros/ADICIONAR',
  REMOVER_PARCEIRO = '@parceiros/REMOVER',
  MODIFICAR_PARCEIRO_SELECIONADO = '@parceiros/MODIFICAR_SELECIONADO',
  MODIFICAR_LISTA_CARREGADA = '@parceiros/MODIFICAR_LISTA_CARREGADA',
  LIMPAR_PARCEIROS = '@parceiros/LIMPAR',
}

export interface ParceirosState {
  readonly data: Parceiro[]
  readonly selecionado: Parceiro
  readonly listaCarregada: boolean
}

export interface AtualizarParceiros {
  type: typeof ParceirosTypes.ATUALIZAR_PARCEIROS
}

export interface AdicionarParceiro {
  type: typeof ParceirosTypes.ADICIONAR_PARCEIRO
}

export interface RemoverParceiro {
  type: typeof ParceirosTypes.REMOVER_PARCEIRO
}

export interface LimparParceiros {
  type: typeof ParceirosTypes.LIMPAR_PARCEIROS
}

export interface ModificarListaCarregadaParceiros {
  type: typeof ParceirosTypes.MODIFICAR_LISTA_CARREGADA
}

export interface ModificarParceiroSelecionado {
  type: typeof ParceirosTypes.MODIFICAR_PARCEIRO_SELECIONADO
}
