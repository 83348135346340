import {
  ParceiroInterface,
  Pagamento,
  AvariaInterface,
  CancelamentoInterface,
  ServicoPrestadoInterface,
  ClienteInterface,
} from './model_interfaces'
import BaseDocument from './BaseDocument'
import Situacao from './Situacao'
import { firestore } from 'firebase'
import Cliente from './Cliente'
import Parceiro from './Parceiro'
import Cancelamento from './Cancelamento'
import CarrinhoCompra from './CarrinhoCompra'
import TipoServico from './TipoServico'
import Cupom from './Cupom'
import Endereco from './Endereco'

export default class ServicoPrestado extends BaseDocument<ServicoPrestado> implements ServicoPrestadoInterface {
  setAtualTipoServico(): void {
    const newCarrinhosCompras: CarrinhoCompra[] = []
    for (const carrinhoCompra of this.carrinhoCompra) {
      const newTiposServicos: TipoServico[] = []
      for (const tipoServico of carrinhoCompra.tipoServicos) {
        for (const info of tipoServico.infosTipoServico) {
          if (info.tamanho === this.dados.tamanho) {
            tipoServico.atualInfoTipoServico = info
            newTiposServicos.push(tipoServico)
          }
        }
      }
      const newCarrinho = Object.assign(new CarrinhoCompra(), carrinhoCompra)
      newCarrinho.tipoServicos = newTiposServicos
      newCarrinhosCompras.push(newCarrinho)
    }
    this.carrinhoCompra = newCarrinhosCompras
  }

  async fillFromJson(json?: any | undefined, recursive?: string[] | undefined): Promise<ServicoPrestado> {
    recursive =
      recursive !== undefined
        ? recursive
        : [
            'avaria',
            'veiculo',
            'situacao',
            'carrinhoCompras',
            'cancelamento',
            'endereco',
            'enderecoSelecionado',
            'cliente',
            'parceiro',
            'tipoServico',
          ]

    json = !json ? this.dados : json
    if (!json) {
      throw new Error('Não há json')
    }

    const situacoes: firestore.DocumentReference[] = json.situacoes
    if ('situacoes' in json && recursive.includes('situacao') && situacoes) {
      this.situacoes = []
      for (const situacao of situacoes) {
        const snapshot = await situacao.get()
        const newSituacao = await new Situacao().convert(snapshot, recursive)
        if (newSituacao instanceof Situacao) {
          this.situacoes.push(newSituacao)
          this.situacaoInicial = this.situacoes[0]
          this.situacaoAtual = this.situacoes[situacoes.length - 1]
        }
      }
    } else if (situacoes) {
      if (recursive.includes('situacaoInicial') || recursive.includes('situacaoAtual')) {
        this.situacoes = []
      }
      if (recursive.includes('situacaoInicial')) {
        const situacao = situacoes[0]
        const documentSnap = await situacao.get()
        const newSituacao = await new Situacao().convert(documentSnap, recursive)
        if (newSituacao instanceof Situacao) {
          this.situacoes.push(newSituacao)
          this.situacaoInicial = newSituacao
        }
      }

      if (recursive.includes('situacaoAtual')) {
        const situacao = situacoes[situacoes.length - 1]
        const documentSnap = await situacao.get()
        const newSituacao = await new Situacao().convert(documentSnap, recursive)
        if (newSituacao instanceof Situacao) {
          this.situacoes.push(newSituacao)
          this.situacaoAtual = newSituacao
        }
      }
    }

    const cliente: firestore.DocumentReference = json.cliente
    if (cliente !== undefined && recursive.includes('cliente')) {
      const clienteSnap = await cliente.get()
      const newCliente = await new Cliente().convert(clienteSnap, recursive)
      if (newCliente instanceof Cliente) {
        this.cliente = newCliente
      }
    }

    const parceiro: firestore.DocumentReference = json.parceiro
    if (parceiro !== undefined && recursive.includes('parceiro')) {
      const parceiroSnap = await parceiro.get()
      const newParceiro = await new Parceiro().convert(parceiroSnap, recursive)
      if (newParceiro instanceof Parceiro) {
        this.parceiro = newParceiro
      }
    }

    const cupom: firestore.DocumentReference = json.cupom
    if (cupom !== undefined && recursive.includes('cupom')) {
      const cupomSnap = await cupom.get()
      const newCupom = await new Cupom().convert(cupomSnap, recursive)
      if (newCupom instanceof Cupom) {
        this.cupom = newCupom
      }
    }

    const cancelamento: firestore.DocumentReference = json.cancelamento
    if (cancelamento !== undefined && recursive.includes('cancelamento')) {
      const cancelamentoSnap = await cancelamento.get()
      const newCancelamento = await new Cancelamento().convert(cancelamentoSnap, recursive)
      if (newCancelamento instanceof Cancelamento) {
        this.cancelamento = newCancelamento
      }
    }
    const endereco: firestore.DocumentReference = json.endereco
    if (endereco !== undefined && recursive.includes('enderecoSelecionado')) {
      const enderecoSnap = await endereco.get()
      const newEndereco = await new Endereco().convert(enderecoSnap, recursive)
      if (newEndereco instanceof Endereco) {
        this.endereco = newEndereco
      }
    }

    const carrinhoCompras: firestore.DocumentReference[] = json.carrinhoCompras
    if (carrinhoCompras !== undefined && recursive.includes('carrinhoCompras')) {
      const tempCarrinhoCompras: CarrinhoCompra[] = []
      for (const carrinhoCompra of carrinhoCompras) {
        const similar: CarrinhoCompra[] =
          this.carrinhoCompra !== undefined ? this.carrinhoCompra.filter(value => value.ref === carrinhoCompra) : []
        if (similar.length > 0) {
          tempCarrinhoCompras.push(await similar[0].fillFromJson(undefined, recursive))
        } else {
          const snapCarrinhoCompra = await carrinhoCompra.get()
          const newCarrinhoCompra = await new CarrinhoCompra().convert(snapCarrinhoCompra, recursive)
          if (newCarrinhoCompra instanceof CarrinhoCompra) {
            tempCarrinhoCompras.push(newCarrinhoCompra)
          }
        }
      }
      this.carrinhoCompra = tempCarrinhoCompras
    }
    this.statusPagamento = json.statusPagamento
    this.tipoPagamento = json.tipoPagamento
    this.comentario = json.comentario
    this.cidade = json.cidade
    this.nota = json.nota
    if (!this.dados) {
      this.dados = json
    }
    return this
  }

  situacaoInicial!: Situacao
  situacaoAtual!: Situacao
  agendamento!: Date
  cliente!: ClienteInterface
  parceiro!: ParceiroInterface
  situacoes!: Situacao[]
  avaliacaoCliente!: number
  avaliacaoParceiroInterface!: number
  carrinhoCompra!: CarrinhoCompra[]
  pagamento!: Pagamento
  avaria!: AvariaInterface
  cancelamento!: CancelamentoInterface
  tipoPagamento!: number
  statusPagamento!: number
  comentario!: string
  cidade!: string
  cupom!: Cupom
  nota!: number
  endereco!: Endereco
}
