// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Grid
import { SearchState, IntegratedFiltering, IntegratedPaging, PagingState } from '@devexpress/dx-react-grid'
import { Grid, Table, Toolbar, SearchPanel, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui'
import { ActionColumns, ActionColumn } from '../utils/TableActionButtons'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// Parceiro
import * as ParceirosActions from '../../store/models/parceiros/actions'
import ParceiroController from '../../controller/ParceiroController'
// Pega os icones
import Create from '@material-ui/icons/Create'
// import Delete from '@material-ui/icons/Delete'
// import Send from '@material-ui/icons/Send'
import Parceiro from '../../models/Parceiro'

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState & LoginState & typeof LoginActions & typeof ParceirosActions & RouteComponentProps<any>

// Determinar cabecalho do grid
const cabecalho = [{ name: 'alterar' }, { name: 'nome', title: 'Nome' }, { name: 'validado', title: 'Validado' }]

interface RowParceiro {
  nome: string
  validado: string
  parceiro: Parceiro
}

// Valores padrão no grid
const tableMessages = {
  noData: 'Sem registros',
}
const searchMessages = {
  searchPlaceholder: 'Pesquisar',
}

class HomeParceiros extends Component<Props> {
  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    // Vincula palavra this a função
    this.goToAdicionar = this.goToAdicionar.bind(this)
    this.handleClickAlterar = this.handleClickAlterar.bind(this)
    this.handleClickExcluir = this.handleClickExcluir.bind(this)
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  renderLogged(): JSX.Element {
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Validação dos Parceiros</h1>
          {this.renderGrid()}
        </div>
      </div>
    )
  }

  goToAdicionar(): void {
    this.props.history.push('/parceiros/adicionar')
  }

  renderNotLogged(): JSX.Element {
    return (
      <div>
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  // Recupera lista Parceiros
  async componentDidMount(): Promise<void> {
    if (!this.props.models.parceiros.listaCarregada) {
      let done: boolean | undefined = false
      this.props.limparParceiros()
      const asyncGeneratorParceiro = ParceiroController.pegarTodos(this.props.match.params.cidadeAtua)
      while (!done) {
        const iteratorResult = await asyncGeneratorParceiro.next()
        if (iteratorResult.value) {
          this.props.adicionarParceiro(iteratorResult.value)
        }
        done = iteratorResult.done
      }
      this.props.modificarListaCarregadaParceiros(true)
    }
  }

  private handleClickAlterar(row: RowParceiro): void {
    this.props.modificarParceiroSelecionado(row.parceiro)
    this.props.history.push('parceiros/' + row.parceiro.uid + '/alterar')
  }

  private handleClickEnviarVerificacao(row: RowParceiro): void {
    this.props.modificarParceiroSelecionado(row.parceiro)
    this.props.history.push('parceiros/' + row.parceiro.uid + '/alterar')
  }

  private handleClickExcluir(row: RowParceiro): void {
    ParceiroController.deletar(row.parceiro)
    this.props.removerParceiro(row.parceiro)
  }

  renderGrid(): JSX.Element {
    // Busca serviços e estrutura registros
    const listParceiros = this.props.models.parceiros ? this.props.models.parceiros.data : []
    let tuplas: readonly RowParceiro[] = []
    if (listParceiros) {
      tuplas = listParceiros.map(parceiro => {
        return {
          nome: parceiro.nome,
          validado: parceiro.validado ? 'Sim' : 'Não',
          parceiro: parceiro,
        }
      })
    }
    const actionColumns: ActionColumn[] = [
      { columnName: 'alterar', label: 'Abrir modo edição', onClick: this.handleClickAlterar, icon: <Create /> },
    ]
    // const actionColumns: ActionColumn[] = [
    //  { columnName: 'alterar', label: 'Abrir modo edição', onClick: this.handleClickAlterar, icon: <Create /> },
    //  {
    //    columnName: 'verificacao',
    //    label: 'Enviar verificação',
    //    onClick: this.handleClickEnviarVerificacao,
    //    icon: <Send />,
    //  },
    //  { columnName: 'excluir', label: 'Excluir linha', onClick: this.handleClickExcluir, icon: <Delete /> },
    // ]
    // Monta Grid
    return (
      <div className="grid">
        <Grid rows={tuplas} columns={cabecalho}>
          <PagingState defaultCurrentPage={0} defaultPageSize={5} />
          <SearchState defaultValue="" />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table messages={tableMessages} />
          <TableHeaderRow />
          <Toolbar />
          <SearchPanel messages={searchMessages} />
          <ActionColumns actionColumns={actionColumns} />
          <PagingPanel
            pageSizes={[5, 10, 20]}
            messages={{
              showAll: 'Mostrar todos',
              rowsPerPage: 'Linhas por página',
              info: (parameters: { from: number; to: number; count: number }): string =>
                parameters.from + '-' + parameters.to + ' de ' + parameters.count,
            }}
          />
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof ParceirosActions =>
  bindActionCreators({ ...LoginActions, ...ParceirosActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HomeParceiros)
