import BaseDocument from './BaseDocument'
import { ExtratoPagamentoInterface, ItemExtra } from './model_interfaces'
import moment, { Moment } from 'moment-timezone'
import ServicoPrestado from './ServicoPrestado'
import Parceiro from './Parceiro'
import { firestore } from 'firebase'
import FirebaseManager from '../utils/FirebaseManager'
export enum TipoFiltroExtratoPagamento {
  TUDO = 0,
  DATA = 1,
  DATA_PARCEIRO = 2,
  PARCEIRO = 3,
}
export default class ExtratoPagamento extends BaseDocument<ExtratoPagamento> implements ExtratoPagamentoInterface {
  servicosPrestado!: string[]
  servicosCarregados!: ServicoPrestado[]
  dataInicio!: Moment
  dataFim!: Moment
  valorBruto!: number
  valorLiquido!: number
  descontosExtras!: ItemExtra[]
  recebimentosExtras!: ItemExtra[]
  dataPagamento!: Moment | null
  taxaCartao!: number
  taxaFixaCartao!: number
  taxaDinheiro!: number
  pago!: boolean
  pid!: string
  parceiro!: Parceiro
  pparceiro!: firestore.DocumentReference

  async fillFromJson(json?: any, recursive?: string[] | undefined): Promise<ExtratoPagamento> {
    recursive =
      recursive !== undefined
        ? recursive
        : ['carrinhoCompras', 'cliente', 'tipoServico', 'servicosCarregados', 'parceiro']
    if (!json) {
      json = this.dados
    }
    this.dataPagamento = json.dataPagamento ? moment(json.dataPagamento).tz(moment.tz.guess()) : null
    this.dataInicio = moment(json.dataInicio).tz(moment.tz.guess())
    this.dataFim = moment(json.dataFim).tz(moment.tz.guess())
    this.servicosPrestado = json.servicosPrestado
    this.valorBruto = json.valorBruto
    this.valorLiquido = json.valorLiquido
    this.descontosExtras = json.descontosExtras
    this.recebimentosExtras = json.recebimentosExtras
    this.taxaCartao = json.taxaCartao
    this.taxaDinheiro = json.taxaDinheiro
    this.pago = json.pago
    this.pid = json.pid
    this.taxaFixaCartao = json.taxaFixaCartao

    const servicosCarregados = json.servicosCarregados

    if ('servicosCarregados' in json && recursive.includes('servicosCarregados') && servicosCarregados) {
      this.servicosCarregados = []
      for (const servico of servicosCarregados) {
        const snapshot = await servico.get()
        const newSituacao = await new ServicoPrestado().convert(snapshot, recursive)
        if (newSituacao instanceof ServicoPrestado) {
          this.servicosCarregados.push(newSituacao)
          newSituacao.setAtualTipoServico()
        }
      }
    }

    const pparceiro: firestore.DocumentReference = json.parceiro
    if (recursive.includes('parceiro') && pparceiro) {
      this.pparceiro = pparceiro
      const snapshot = await pparceiro.get()
      const data = snapshot.data()
      if (data) {
        const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
          .collection('usuarios')
          .where('publicUid.parceiros', '==', data.pid)
          .get()

        if (!query.empty) {
          this.parceiro = new Parceiro()
          this.parceiro.ref = pparceiro
          this.parceiro.pparceiro = pparceiro
          this.parceiro.nome = data.nome
          this.parceiro.uid = query.docs[0].id
          this.parceiro.pidExtrato = query.docs[0].data().publicUid.extratospagamento
        }
      }
    }
    return this
  }
}
