// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Menu
import Menu from '../menu/Menu'
// CSS
import './Parceiros.css'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// Parceiro
import * as ParceirosActions from '../../store/models/parceiros/actions'
import ParceiroController from '../../controller/ParceiroController'
import Parceiro from '../../models/Parceiro'
// Bootstrap 4
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import FirebaseManager from '../../utils/FirebaseManager'
import ImageComponent from '../utils/ImageComponent'
import * as TipoServicosActions from '../../store/models/tiposservicos/actions'
import Disponibilidade from '../../models/Disponibilidade'
import { DiaSemana, Turno } from '../../models/model_interfaces'
import DisponibilidadeController from '../../controller/DisponibilidadeController'
import TipoServico from '../../models/TipoServico'
import TipoServicoController from '../../controller/TipoServicoController'
import { Checkbox, IconButton } from '@material-ui/core'
import { firestore } from 'firebase'
import { Delete } from '@material-ui/icons'

interface ParceiroAlterarState {
  parceiro: Parceiro
  disponibilidades: Disponibilidade[]
  url: string
  tiposServicos: { [id: string]: TipoServico[] }
  allTiposServicos: TipoServico[]
  cidade: string[]
  cidadeDropDown: string
}

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState &
  LoginState &
  typeof LoginActions &
  typeof ParceirosActions &
  RouteComponentProps<any> &
  typeof TipoServicosActions

class ParceiroAlterar extends Component<Props, ParceiroAlterarState> {
  private validado: React.RefObject<HTMLInputElement>
  private nome: React.RefObject<HTMLInputElement>
  private bairro: React.RefObject<HTMLInputElement>
  private cep: React.RefObject<HTMLInputElement>
  private cidade: React.RefObject<HTMLInputElement>
  private endereco1: React.RefObject<HTMLInputElement>
  private endereco2: React.RefObject<HTMLInputElement>
  private estado: React.RefObject<HTMLInputElement>

  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }

    // Vincula palavra this a função
    this.goBack = this.goBack.bind(this)
    this.salvarParceiro = this.salvarParceiro.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.carregarDisponibilidades = this.carregarDisponibilidades.bind(this)
    this.carregarImagem = this.carregarImagem.bind(this)
    this.renderTiposServicos = this.renderTiposServicos.bind(this)
    this.handleCheckTipoServico = this.handleCheckTipoServico.bind(this)
    this.filtrarPorTurnoEDiaSemana = this.filtrarPorTurnoEDiaSemana.bind(this)
    this.renderDropDown = this.renderDropDown.bind(this)
    this.renderCidadesAtua = this.renderCidadesAtua.bind(this)
    this.handleChangeDropDown = this.handleChangeDropDown.bind(this)
    this.handleAddCidadeAtua = this.handleAddCidadeAtua.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.validado = React.createRef<HTMLInputElement>()
    this.nome = React.createRef<HTMLInputElement>()
    this.bairro = React.createRef<HTMLInputElement>()
    this.cep = React.createRef<HTMLInputElement>()
    this.cidade = React.createRef<HTMLInputElement>()
    this.endereco1 = React.createRef<HTMLInputElement>()
    this.endereco2 = React.createRef<HTMLInputElement>()
    this.estado = React.createRef<HTMLInputElement>()
    const map: { [id: string]: TipoServico[] } = {}
    if (!this.props.models.parceiros.selecionado.isEmpty) {
      for (const cidade of this.props.models.parceiros.selecionado.cidade) {
        map[cidade] = []
      }
    } else {
      map[this.props.match.params.cidadeAtua] = []
    }

    this.state = {
      parceiro: this.props.models.parceiros.selecionado,
      url: '',
      disponibilidades: [],
      tiposServicos: map,
      cidade: this.props.models.parceiros.selecionado.isEmpty ? [] : this.props.models.parceiros.selecionado.cidade,
      allTiposServicos: [],
      cidadeDropDown: '',
    }

    this.carregarImagem()

    if (!this.state.parceiro.isEmpty) {
      this.carregarDisponibilidades()
    }

    if (
      !this.props.models.parceiros ||
      !this.props.models.parceiros ||
      this.props.models.parceiros.selecionado.isEmpty
    ) {
      if (this.props.match.params.id) {
        this.pegarParceiroById(this.props.match.params.id)
      } else {
        this.props.history.replace('/parceiros')
      }
    }
  }

  async carregarDisponibilidades(): Promise<void> {
    const disponibilidades = await DisponibilidadeController.pegarPorParceiro(this.state.parceiro)
    this.setState({
      disponibilidades,
    })
  }

  carregarImagem(): void {
    const user = FirebaseManager.auth.currentUser
    if (user) {
      const ref = FirebaseManager.storage.refFromURL(this.state.parceiro.documento)
      ref.getDownloadURL().then(url => {
        this.setState({
          url: url,
        })
      })
    }
  }

  async pegarParceiroById(documentId: string): Promise<void> {
    const parceiro = await ParceiroController.pegarPorId(documentId)
    const tiposServicos = await TipoServicoController.pegarTodosDireto(parceiro.cidade)
    const map = this.state.tiposServicos
    for (const servico of tiposServicos) {
      for (const servicoParceiro of parceiro.tiposServicosAptos) {
        if (servicoParceiro.id === servico.ref.id) {
          if (!(servico.cidade in map)) {
            map[servico.cidade] = []
          }
          map[servico.cidade].push(servico)
        }
      }
    }

    this.setState({
      tiposServicos: map,
      parceiro: parceiro,
      cidade: parceiro.cidade,
      allTiposServicos: tiposServicos,
    })
    this.carregarDisponibilidades()
    this.carregarImagem()
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  renderLogged(): JSX.Element {
    if (this.state.parceiro.isEmpty) {
      return <div>Carregando...</div>
    }
    const estiloBtnSalvar = { backgroundColor: '#113F4F', color: '#FFFFFF', borderRadius: '0' }
    const estiloBtnCancelar = { backgroundColor: '#1B9999', color: '#FFFFFF', borderRadius: '0' }
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Alterar Parceiro</h1>
          <br />
          <Form className="form">
            <p>Parceiro</p>
            <Form.Group controlId="formaBasicNome">
              <Form.Control defaultValue={this.state.parceiro.nome} ref={this.nome} type="text" placeholder="nome" />
            </Form.Group>
            <p>CEP</p>
            <Form.Group controlId="formaBasicCEP">
              <Form.Control
                defaultValue={this.state.parceiro.endereco.cep}
                ref={this.cep}
                type="text"
                placeholder="CEP"
              />
            </Form.Group>
            <p>Rua</p>
            <Form.Group controlId="formaBasicEndereco">
              <Form.Control
                defaultValue={this.state.parceiro.endereco.endereco1}
                ref={this.endereco1}
                type="text"
                placeholder="Endereço"
              />
            </Form.Group>
            <p>Número</p>
            <Form.Group controlId="formaBasicNumero">
              <Form.Control
                defaultValue={this.state.parceiro.endereco.endereco2}
                ref={this.endereco2}
                type="text"
                placeholder="Numero"
              />
            </Form.Group>
            <p>Bairro</p>
            <Form.Group controlId="formaBasicBairro">
              <Form.Control
                defaultValue={this.state.parceiro.endereco.bairro}
                ref={this.bairro}
                type="text"
                placeholder="Bairro"
              />
            </Form.Group>
            <p>Cidade</p>
            <Form.Group controlId="formaBasicCidade">
              <Form.Control
                defaultValue={this.state.parceiro.endereco.cidade}
                ref={this.cidade}
                type="text"
                placeholder="Cidade"
              />
            </Form.Group>
            <p>Estado</p>
            <Form.Group controlId="formaBasicEstado">
              <Form.Control
                defaultValue={this.state.parceiro.endereco.estado}
                ref={this.estado}
                type="text"
                placeholder="Estado"
              />
            </Form.Group>
            <br />
            <p>Cidades que atua</p>
            {this.renderCidadesAtua()}

            <span>
              <span className="formDescricao">Possui internet móvel:</span>{' '}
              {this.state.parceiro.internetMovel ? 'Sim' : 'Não possui'}
            </span>
            <br />
            {this.renderMeioTransporte()}
            <br />
            {this.renderComoConheceu()}
            <br />
            <br />
            <p>Foto Documento</p>
            <Form.Group controlId="formBasicDocumento" className="imageDocumento">
              <ImageComponent src={this.state.url} height="400" width="" />
            </Form.Group>
            <br />
            <p>Ativo</p>
            <Form.Group controlId="formBasicVerificacao" className="checkBoxValidacaoParceiro">
              <Form.Check
                defaultChecked={this.state.parceiro.validado || false}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => this.handleChange('validado', evt)}
                type="checkbox"
              />
            </Form.Group>
            <p>Apto a trabalhar na loja?</p>
            <Form.Group controlId="formBasicVerificacaoLoja" className="checkBoxValidacaoParceiro">
              <Form.Check
                defaultChecked={this.state.parceiro.aptoLoja || false}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => this.handleChange('aptoLoja', evt)}
                type="checkbox"
              />
            </Form.Group>
            <p>Apto a trabalhar no ponto fixo?</p>
            <Form.Group controlId="formBasicPontoFixo" className="checkBoxValidacaoParceiro">
              <Form.Check
                defaultChecked={this.state.parceiro.aptoPontoFixo || false}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => this.handleChange('aptoPontoFixo', evt)}
                type="checkbox"
              />
            </Form.Group>
            <br />
            <Container className="table-container">
              <Row className="header-row">
                <Col>Tipos de serviços</Col>
                <Col>Cidade</Col>
                <Col>Apto?</Col>
              </Row>
              {this.renderTiposServicos()}
            </Container>
            <br />
            <Container className="table-container">
              <Row key={0} className="header-row">
                <Col></Col>
                <Col>Segunda-feira</Col>
                <Col>Terça-feira</Col>
                <Col>Quarta-feira</Col>
                <Col>Quinta-feira</Col>
                <Col>Sexta-feira</Col>

                <Col>Sábado</Col>
                <Col>Domingo</Col>
              </Row>
              <Row key={1}>
                <Col>Manhã</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.manha, DiaSemana.segunda)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.manha, DiaSemana.terca)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.manha, DiaSemana.quarta)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.manha, DiaSemana.quinta)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.manha, DiaSemana.sexta)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.manha, DiaSemana.sabado)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.manha, DiaSemana.domingo)}</Col>
              </Row>
              <Row key={2}>
                <Col>Tarde</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.tarde, DiaSemana.segunda)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.tarde, DiaSemana.terca)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.tarde, DiaSemana.quarta)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.tarde, DiaSemana.quinta)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.tarde, DiaSemana.sexta)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.tarde, DiaSemana.sabado)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.tarde, DiaSemana.domingo)}</Col>
              </Row>
              <Row key={3}>
                <Col>Noite</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.noite, DiaSemana.segunda)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.noite, DiaSemana.terca)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.noite, DiaSemana.quarta)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.noite, DiaSemana.quinta)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.noite, DiaSemana.sexta)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.noite, DiaSemana.sabado)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.noite, DiaSemana.domingo)}</Col>
              </Row>
              <Row key={4}>
                <Col>Madrugada</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.madrugada, DiaSemana.segunda)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.madrugada, DiaSemana.terca)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.madrugada, DiaSemana.quarta)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.madrugada, DiaSemana.quinta)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.madrugada, DiaSemana.sexta)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.madrugada, DiaSemana.sabado)}</Col>
                <Col>{this.filtrarPorTurnoEDiaSemana(Turno.madrugada, DiaSemana.domingo)}</Col>
              </Row>
            </Container>
            <div className="formButtons">
              <Button onClick={this.goBack} style={estiloBtnCancelar}>
                Voltar
              </Button>
              <Button onClick={this.salvarParceiro} style={estiloBtnSalvar}>
                Salvar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }

  handleCheckTipoServico(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    const position = Number(event.currentTarget.value)
    const tipoServico = this.state.allTiposServicos[position]
    const map = this.state.tiposServicos
    if (this.state.tiposServicos[tipoServico.cidade].some(v => v.ref.id === tipoServico.ref.id)) {
      const tiposServicos = this.state.tiposServicos[tipoServico.cidade].filter(
        value => value.ref.id !== tipoServico.ref.id,
      )
      map[tipoServico.cidade] = tiposServicos
      this.setState({
        tiposServicos: map,
      })
    } else {
      const tiposServicos = map[tipoServico.cidade]
      tiposServicos.push(tipoServico)
      map[tipoServico.cidade] = tiposServicos
      this.setState({
        tiposServicos: map,
      })
    }
  }

  renderTiposServicos(): JSX.Element[] {
    const tiposServicos = !this.state.allTiposServicos ? [] : this.state.allTiposServicos
    const elements: JSX.Element[] = tiposServicos.map<JSX.Element>((value, idx) => {
      return (
        <Row key={idx + 1} className="item-row">
          <Col>{value.descricao}</Col>
          <Col>{value.cidade}</Col>
          <Col>
            <Checkbox
              checked={this.state.tiposServicos[value.cidade].some(v => v.ref.id === value.ref.id)}
              value={idx}
              onChange={this.handleCheckTipoServico}
            ></Checkbox>
          </Col>
        </Row>
      )
    })
    return elements
  }

  filtrarPorTurnoEDiaSemana(turno: Turno, diaSemana: DiaSemana): string {
    for (const disponibilidade of this.state.disponibilidades) {
      if (disponibilidade.turno === turno && disponibilidade.diaSemana === diaSemana) {
        return disponibilidade.dataInicio.format('HH:mm') + ' até às ' + disponibilidade.dataFim.format('HH:mm')
      }
    }
    return '-'
  }

  renderComoConheceu(): JSX.Element {
    let comoConheceu = ''
    switch (this.state.parceiro.comoConheceu) {
      case 0:
        comoConheceu += 'Não sei, '
        break
      case 1:
        comoConheceu += 'Redes Sociais, '
        break
      case 2:
        comoConheceu += 'Notícias, '
        break
      case 3:
        comoConheceu += 'Indicação de amigos/ conhecidos, '
        break
      case 4:
        comoConheceu += 'Contato direto, '
        break
      case 5:
        comoConheceu += 'Outro, '
        break
    }
    comoConheceu = comoConheceu.substring(0, comoConheceu.length - 2)
    return (
      <span>
        <span className="formDescricao">Como conheceu:</span> {comoConheceu}
      </span>
    )
  }

  renderMeioTransporte(): JSX.Element {
    let meiosTransportes = ''
    for (const meioTransporte of this.state.parceiro.meioTransporte) {
      switch (meioTransporte) {
        case 0:
          meiosTransportes += 'Carro, '
          break
        case 1:
          meiosTransportes += 'Moto, '
          break
        case 2:
          meiosTransportes += 'Bicicleta, '
          break
        case 3:
          meiosTransportes += 'Ônibus, '
          break
        case 4:
          meiosTransportes += 'Outro, '
          break
      }
    }
    meiosTransportes = meiosTransportes.substring(0, meiosTransportes.length - 2)
    return (
      <span>
        <span className="formDescricao">Meios de transportes: </span> {meiosTransportes}
      </span>
    )
  }

  handleChange(tipo: 'validado' | 'aptoPontoFixo' | 'aptoLoja', evt: React.ChangeEvent<HTMLInputElement>): void {
    const parceiro = this.state.parceiro
    parceiro[tipo] = evt.target.checked
    this.setState({ parceiro: parceiro })
  }

  async handleChangeDropDown(event: React.ChangeEvent<HTMLSelectElement>): Promise<void> {
    this.setState({
      cidadeDropDown: event.target.value,
    })
  }

  async handleAddCidadeAtua(): Promise<void> {
    const cidade = this.state.cidadeDropDown
    if (this.state.cidade.includes(cidade)) {
      return
    }
    const newTiposServicos = await TipoServicoController.pegarTodosDireto(cidade)
    const map = this.state.tiposServicos
    if (!(cidade in map)) {
      map[cidade] = []
    }
    const cidades = this.state.cidade
    const allTiposServicos: TipoServico[] = []
    for (const c of cidades) {
      for (const tp of this.state.allTiposServicos) {
        if (tp.cidade === c) {
          allTiposServicos.push(tp)
        }
      }
    }
    allTiposServicos.push(...newTiposServicos)

    cidades.push(cidade)

    this.setState({
      allTiposServicos: allTiposServicos,
      cidade: cidades,
      tiposServicos: map,
    })
  }

  async handleDeleteItem(item: string): Promise<void> {
    const cidades = this.state.cidade.filter(v => v !== item)
    const allTiposServicos: TipoServico[] = this.state.allTiposServicos.filter(v => v.cidade !== item)
    const map = this.state.tiposServicos
    delete map[item]
    this.setState({
      cidade: cidades,
      tiposServicos: map,
      allTiposServicos,
    })
  }

  renderCidadesAtua(): JSX.Element {
    const options: JSX.Element[] = this.state.cidade.map<JSX.Element>((item, idx) => {
      return (
        <Row className="item-extra" key={'item' + idx}>
          <p>{item}</p>{' '}
          <IconButton
            onClick={(): Promise<void> => this.handleDeleteItem(item)}
            size="medium"
            aria-label={'Remover item'}
            style={{ verticalAlign: 'middle' }}
          >
            <Delete></Delete>
          </IconButton>
        </Row>
      )
    })
    return (
      <Col className="list-add-remove">
        <Row className="row-add">
          {this.renderDropDown()} <Button onClick={this.handleAddCidadeAtua}>Adicionar</Button>
        </Row>
        {options}
      </Col>
    )
  }

  renderDropDown(): JSX.Element {
    const options = this.props.models.cidadesAtua.data.map<JSX.Element>((value, idx) => {
      if (!this.state.cidadeDropDown) {
        this.setState({
          cidadeDropDown: value.nome,
        })
      }
      return (
        <option key={idx} value={value.nome}>
          {value.nome}
        </option>
      )
    })
    return (
      <Form.Group className="dropdown">
        <Form.Control onChange={this.handleChangeDropDown} as="select">
          {options}
        </Form.Control>
      </Form.Group>
    )
  }

  async salvarParceiro(): Promise<void> {
    try {
      const nome = this.nome.current?.value
      const bairro = this.bairro.current?.value
      const cep = this.cep.current?.value
      const cidade = this.cidade.current?.value
      const endereco1 = this.endereco1.current?.value
      const endereco2 = this.endereco2.current?.value
      const estado = this.estado.current?.value

      if (nome && bairro && cep && cidade && endereco1 && endereco2 && estado) {
        this.state.parceiro.nome = nome
        this.state.parceiro.endereco.bairro = bairro
        this.state.parceiro.endereco.cep = cep
        this.state.parceiro.endereco.cidade = cidade
        this.state.parceiro.endereco.endereco1 = endereco1
        this.state.parceiro.endereco.endereco2 = endereco2
        this.state.parceiro.endereco.estado = estado
        this.state.parceiro.cidade = this.state.cidade

        const tiposServicosAptos: firestore.DocumentReference[] = []
        for (const tsa of Object.keys(this.state.tiposServicos)) {
          tiposServicosAptos.push(...this.state.tiposServicos[tsa].map<firestore.DocumentReference>(value => value.ref))
        }

        this.state.parceiro.tiposServicosAptos = tiposServicosAptos

        this.props.modificarParceiroSelecionado(await ParceiroController.alterar(this.state.parceiro))
        this.props.adicionarParceiro(this.state.parceiro)

        if (this.props.match.params.cidadeAtua !== this.state.cidade) {
          const newUrl = `/${this.state.cidade[0]}/parceiros`
          this.props.history.replace(newUrl)
          window.scrollTo(0, 0)
          window.location.reload()
        }
      } else {
        throw Error('Algum campo está inválido')
      }
    } catch (error) {
      alert(error)
    }
  }

  goBack(): void {
    this.props.history.goBack()
  }

  renderNotLogged(): JSX.Element {
    return (
      <div className="Home">
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  async componentDidMount(): Promise<void> {
    const tiposServicos = await TipoServicoController.pegarTodosDireto(
      this.state.parceiro.isEmpty ? this.props.match.params.cidadeAtua : this.state.parceiro.cidade,
    )
    for (const ts of tiposServicos) {
      this.props.adicionarTipoServico(ts)
    }

    this.setState({
      allTiposServicos: tiposServicos,
    })
    if (!this.state.parceiro.isEmpty) {
      const map = this.state.tiposServicos
      for (const servico of tiposServicos) {
        for (const servicoParceiro of this.state.parceiro.tiposServicosAptos) {
          if (servicoParceiro.id === servico.ref.id) {
            if (!(servico.cidade in map)) {
              map[servico.cidade] = []
            }
            map[servico.cidade].push(servico)
          }
        }
      }
      this.setState({
        tiposServicos: map,
      })
    }
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (
  dispatch: Dispatch,
): typeof LoginActions & typeof ParceirosActions & typeof TipoServicosActions =>
  bindActionCreators({ ...LoginActions, ...ParceirosActions, ...TipoServicosActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ParceiroAlterar)
