import { Reducer } from 'redux'
import { ExtratosPagamentoState, ExtratosPagamentoTypes } from './types'
import ExtratoPagamento from '../../../models/ExtratoPagamento'

const initialState: ExtratosPagamentoState = {
  data: [],
  selecionado: new ExtratoPagamento(),
  listaCarregada: false,
}

const reducer: Reducer<ExtratosPagamentoState> = (state = initialState, action) => {
  const currentData = state.data

  switch (action.type) {
    case ExtratosPagamentoTypes.ADICIONAR_EXTRATO_PAGAMENTO: {
      const newData = currentData.filter(value => value.id !== action.payload.id)
      newData.push(action.payload)
      return { ...state, data: newData }
    }
    case ExtratosPagamentoTypes.ATUALIZAR_EXTRATOS_PAGAMENTO:
      return { ...state, data: action.payload.data }
    case ExtratosPagamentoTypes.LIMPAR_EXTRATOS_PAGAMENTO:
      return { ...state, data: [] }
    case ExtratosPagamentoTypes.MODIFICAR_EXTRATO_PAGAMENTO_SELECIONADO:
      return { ...state, selecionado: action.payload }
    case ExtratosPagamentoTypes.MODIFICAR_LISTA_CARREGADA:
      return { ...state, listaCarregada: action.payload }
    case ExtratosPagamentoTypes.REMOVER_EXTRATO_PAGAMENTO: {
      const newData = currentData.filter(extratoPagamento => extratoPagamento.id !== action.payload.id)
      return { ...state, data: newData }
    }
    default:
      return state
  }
}

export default reducer
