import Produto from '../models/Produto'
import ProdutoRepository from '../repositories/ProdutoRepository'

class ProdutoController {
  pegarTodos(recursive?: string[]): AsyncGenerator<Produto, void, Produto> {
    return ProdutoRepository.pegarTodos(recursive)
  }

  pegarPorId(documentId: string, recursive?: string[]): Promise<Produto> {
    return ProdutoRepository.pegarPorId(documentId, recursive)
  }

  alterar(produto: Produto): Promise<Produto> {
    return ProdutoRepository.alterar(produto)
  }

  criar(produto: Produto): Promise<Produto> {
    return ProdutoRepository.criar(produto)
  }

  deletar(produto: Produto): Promise<Produto> {
    return ProdutoRepository.deletar(produto)
  }
}

export default new ProdutoController()
