import BaseDocument from './BaseDocument'
import { CupomInterface, TipoCupom } from './model_interfaces'
import moment, { Moment } from 'moment-timezone'

export default class Cupom extends BaseDocument<Cupom> implements CupomInterface {
  validade!: Moment
  codigo!: string
  restante!: number
  desconto!: number
  tipoCupom!: number
  valorMinimo!: number
  voucher!: number
  pidCliente!: string

  async fillFromJson(json?: any, recursive?: string[] | undefined): Promise<Cupom> {
    if (!json) {
      json = this.dados
    }

    this.codigo = json.codigo
    this.validade = moment(json.validade).tz(moment.tz.guess())
    this.restante = json.restante
    this.desconto = json.desconto
    this.tipoCupom = json.tipoCupom
    this.valorMinimo = json.valorMinimo
    this.voucher = json.voucher
    this.pidCliente = json.pidCliente

    return this
  }

  canInsertOrUpdate() {
    if (this.codigo.length < 3) {
      throw Error('Código muito curto')
    }

    if (this.validade.isBefore(moment())) {
      throw Error('A validade não pode ser anterior ao dia de hoje')
    }

    if (this.restante < 0) {
      throw Error('Não é possível ter um quantidade de cupons negativa')
    }

    if (this.desconto <= 0) {
      throw Error('Não é possível aplicar essa quantidade de desconto')
    }

    if (this.valorMinimo < 0) {
      throw Error('Não é possível aplicar esse valor mínimo')
    }

    if (this.tipoCupom !== TipoCupom.PORCENTAGEM && this.tipoCupom !== TipoCupom.VALOR) {
      throw Error('Tipo de cumpom inválido')
    }
  }
}
