import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// CSS Global aplicado a todas as telas
import './estiloGlobal.css'
// Importação dos componentes para rota. Toda tela deve estar mapeada aqui
import Home from './home/Home'
import LoginPage from './login/Login'
import HomeTipoServico from './tiposservicos/TipoServi'
import HomeTiposVeiculos from './tiposveiculos/TiposVeiculos'
import HomeCupons from './cupons/Cupons'
import HomeConfiguracoes from './configuracoes/Configuracoes'
import HomeParceiros from './parceiros/Parceiros'
import ServiPrestado from './servicosprestados/ServiPrestado'
import Financeiro from './financeiro/Financeiro'
import HomeProdutos from './produtos/Produtos'
import Cliente from './clientes/Cliente'
import GenericNotFound from './genericas/GenericNotFound'

// Importando Pontos Fixos
import PontosFixos from './pontosFixos/PontosFixos'

// TODO: reorganizar subrotas
export class AppAdministrativo extends Component {
  render(): JSX.Element {
    return (
      <Router basename="/administrativo">
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route path="/:cidadeAtua/home" component={Home} />
          <Route path="/:cidadeAtua/tiposservicos" component={HomeTipoServico} />
          <Route path="/:cidadeAtua/tiposveiculos" component={HomeTiposVeiculos} />
          <Route path="/:cidadeAtua/servicosprestados" component={ServiPrestado} />
          <Route path="/:cidadeAtua/cupons" component={HomeCupons} />
          <Route path="/:cidadeAtua/configuracoes" component={HomeConfiguracoes} />
          <Route path="/:cidadeAtua/parceiros" component={HomeParceiros} />
          <Route path="/:cidadeAtua/financeiro" component={Financeiro} />
          <Route path="/:cidadeAtua/clientes" component={Cliente} />
          <Route path="/:cidadeAtua/produtos" component={HomeProdutos} />
          <Route path="/:cidadeAtua/pontos_fixos" component={PontosFixos} />
          <Route component={GenericNotFound} />
        </Switch>
      </Router>
    )
  }
}
