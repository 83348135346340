import { Reducer } from 'redux'
import { ProdutosState, ProdutosTypes } from './types'
import Produto from '../../../models/Produto'

const initialState: ProdutosState = {
  data: [],
  selecionado: new Produto(),
  listaCarregada: false,
}

const reducer: Reducer<ProdutosState> = (state = initialState, action) => {
  const currentData = state.data

  switch (action.type) {
    case ProdutosTypes.ADICIONAR_PRODUTO: {
      const newData = currentData.filter(p => p.id !== action.payload.id)
      newData.push(action.payload)
      return { ...state, data: newData }
    }
    case ProdutosTypes.ATUALIZAR_PRODUTOS:
      return { ...state, data: action.payload.data }
    case ProdutosTypes.LIMPAR_PRODUTOS:
      return { ...state, data: [], listaCarregada: false }
    case ProdutosTypes.MODIFICAR_PRODUTO_SELECIONADO:
      return { ...state, selecionado: action.payload }
    case ProdutosTypes.REMOVER_PRODUTO: {
      const newData = currentData.filter(produto => produto.id !== action.payload.id)
      return { ...state, data: newData }
    }
    case ProdutosTypes.MODIFICAR_LISTA_CARREGADA:
      return { ...state, listaCarregada: action.payload }
    default:
      return state
  }
}

export default reducer
