import login from './login'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { persistReducer } from 'redux-persist'

const persistConfig = {
  key: 'user',
  storage,
}

export default persistReducer(persistConfig, login)
