import FirebaseManager from '../utils/FirebaseManager'
import ExtratoPagamento, { TipoFiltroExtratoPagamento } from '../models/ExtratoPagamento'
import { firestore } from 'firebase'
import Parceiro from '../models/Parceiro'
import moment, { Moment } from 'moment-timezone'

class ExtratoPagamentoRepository {
  async criar(extratoPagamento: ExtratoPagamento): Promise<ExtratoPagamento> {
    const batch = FirebaseManager.database.batch()
    const document: firebase.firestore.DocumentReference = FirebaseManager.database
      .collection('extratospagamento')
      .doc()
    const map = {
      pago: extratoPagamento.pago,
      servicosPrestado: extratoPagamento.servicosPrestado,
      servicosCarregados: extratoPagamento.servicosCarregados.map<firestore.DocumentReference>(servico => servico.ref),
      dataInicio: extratoPagamento.dataInicio.format(),
      dataFim: extratoPagamento.dataFim.format(),
      valorBruto: extratoPagamento.valorBruto,
      valorLiquido: extratoPagamento.valorLiquido,
      descontosExtras: extratoPagamento.descontosExtras,
      recebimentosExtras: extratoPagamento.recebimentosExtras,
      taxaCartao: extratoPagamento.taxaCartao,
      taxaDinheiro: extratoPagamento.taxaDinheiro,
      taxaFixaCartao: extratoPagamento.taxaFixaCartao,
      pid: extratoPagamento.pid,
      dataPagamento: extratoPagamento.dataPagamento ? extratoPagamento.dataPagamento.format() : null,
      parceiro: extratoPagamento.pparceiro,
      ativo: true,
    }
    for (const sp of extratoPagamento.servicosCarregados) {
      if (extratoPagamento.pago) {
        batch.update(sp.ref, { fechadoExtrato: true, extratoPago: true })
      } else {
        batch.update(sp.ref, { fechadoExtrato: true })
      }
    }
    batch.set(document, map)
    await batch.commit()
    extratoPagamento.ref = document
    return extratoPagamento
  }

  async pegarPorId(documentId: string, recursive?: string[]): Promise<ExtratoPagamento> {
    const reference: firebase.firestore.DocumentReference = await FirebaseManager.database
      .collection('extratospagamento')
      .doc(documentId)
    const doc = await reference.get()
    if (doc.exists) {
      const cupom = await new ExtratoPagamento().convert(doc, recursive)

      if (cupom instanceof ExtratoPagamento) {
        return cupom
      }
    }

    return new ExtratoPagamento()
  }

  async alterar(extratoPagamento: ExtratoPagamento): Promise<ExtratoPagamento> {
    const batch = FirebaseManager.database.batch()
    const document: firebase.firestore.DocumentReference = extratoPagamento.ref
    const map = {
      pago: extratoPagamento.pago,
      servicosPrestado: extratoPagamento.servicosPrestado,
      servicosCarregados: extratoPagamento.servicosCarregados.map<firestore.DocumentReference>(servico => servico.ref),
      dataInicio: extratoPagamento.dataInicio.format(),
      dataFim: extratoPagamento.dataFim.format(),
      valorBruto: extratoPagamento.valorBruto,
      valorLiquido: extratoPagamento.valorLiquido,
      taxaFixaCartao: extratoPagamento.taxaFixaCartao,
      descontosExtras: extratoPagamento.descontosExtras,
      recebimentosExtras: extratoPagamento.recebimentosExtras,
      taxaCartao: extratoPagamento.taxaCartao,
      taxaDinheiro: extratoPagamento.taxaDinheiro,
      pid: extratoPagamento.pid,
      dataPagamento: extratoPagamento.dataPagamento ? extratoPagamento.dataPagamento.format() : null,
      parceiro: extratoPagamento.pparceiro,
      ativo: true,
    }
    for (const sp of extratoPagamento.servicosCarregados) {
      if (extratoPagamento.pago) {
        batch.update(sp.ref, { fechadoExtrato: true, extratoPago: true })
      } else {
        batch.update(sp.ref, { fechadoExtrato: true })
      }
    }
    batch.update(extratoPagamento.ref, map)
    await batch.commit()
    extratoPagamento.ref = document
    return extratoPagamento
  }

  async deletar(extratoPagamento: ExtratoPagamento): Promise<ExtratoPagamento> {
    const batch = FirebaseManager.database.batch()
    if (extratoPagamento.servicosCarregados) {
      for (const ref of extratoPagamento.servicosCarregados) {
        batch.update(ref.ref, { fechadoExtrato: false, extratoPago: false })
      }
    } else {
      for (const ref of extratoPagamento.dados.servicosCarregados) {
        batch.update(ref, { fechadoExtrato: false, extratoPago: false })
      }
    }
    batch.update(extratoPagamento.ref, {
      ativo: false,
    })
    await batch.commit()
    return extratoPagamento
  }

  async *pegarTodos(recursive?: string[]): AsyncGenerator<ExtratoPagamento, void, ExtratoPagamento> {
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('extratospagamento')
      .where('ativo', '==', true)
      .get()
    const queryDocsSnapshot = query.docs
    if (queryDocsSnapshot) {
      for (const doc of queryDocsSnapshot) {
        const extratoPagamento = await new ExtratoPagamento().convert(doc, recursive)

        if (extratoPagamento instanceof ExtratoPagamento) {
          yield extratoPagamento
        }
      }
    }
  }

  async *pegarFiltro(
    tipoFiltro: TipoFiltroExtratoPagamento,
    parceiro?: Parceiro,
    dataInicio?: Moment,
    dataFim?: Moment,
    recursive?: string[],
  ): AsyncGenerator<ExtratoPagamento, void, ExtratoPagamento> {
    const query: firebase.firestore.QuerySnapshot =
      TipoFiltroExtratoPagamento.DATA === tipoFiltro
        ? await FirebaseManager.database
            .collection('extratospagamento')
            .where('ativo', '==', true)
            .get()
        : await FirebaseManager.database
            .collection('extratospagamento')
            .where('ativo', '==', true)
            .where('parceiro', '==', parceiro?.pparceiro)
            .get()
    const queryDocsSnapshot = query.docs
    if (queryDocsSnapshot) {
      for (const doc of queryDocsSnapshot) {
        const dadosExtrato = doc.data()
        if (dataInicio && dataFim && dadosExtrato) {
          const extratoInicio = moment(dadosExtrato.dataInicio)

          if (!extratoInicio.isAfter(dataInicio) || !extratoInicio.isBefore(dataFim)) {
            continue
          }
        }
        const extratoPagamento = await new ExtratoPagamento().convert(doc, ['parceiro', 'not_endereco'])

        if (extratoPagamento instanceof ExtratoPagamento) {
          yield extratoPagamento
        }
      }
    }
  }
}

export default new ExtratoPagamentoRepository()
