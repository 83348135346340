import { action } from 'typesafe-actions'
import {
  TiposServicosState,
  TiposServicosTypes,
  AtualizarTiposServicos,
  AdicionarTipoServico,
  LimparTiposServicos,
  ModificarTipoServicoSelecionado,
  RemoverTipoServico,
  ModificarListaCarregadaTipoServicos,
} from './types'
import TipoServico from '../../../models/TipoServico'
export const atualizarTiposServicos = (data: TiposServicosState): AtualizarTiposServicos =>
  action(TiposServicosTypes.ATUALIZAR_TIPOS_SERVICOS, data)

export const adicionarTipoServico = (newTipoServico: TipoServico): AdicionarTipoServico => {
  return action(TiposServicosTypes.ADICIONAR_TIPO_SERVICO, newTipoServico)
}

export const removerTipoServico = (newTipoServico: TipoServico): RemoverTipoServico => {
  return action(TiposServicosTypes.REMOVER_TIPO_SERVICO, newTipoServico)
}

export const limparTiposServicos = (): LimparTiposServicos => {
  return action(TiposServicosTypes.LIMPAR_TIPOS_SERVICOS)
}

export const modificarTipoServicoSelecionado = (newTipoServico: TipoServico): ModificarTipoServicoSelecionado => {
  return action(TiposServicosTypes.MODIFICAR_TIPO_SERVICO_SELECIONADO, newTipoServico)
}

export const modificarListaCarregadaTiposServicos = (estaCarregada: boolean): ModificarListaCarregadaTipoServicos => {
  return action(TiposServicosTypes.MODIFICAR_LISTA_CARREGADA, estaCarregada)
}
