import Cupom from '../models/Cupom'
import CupomRepository from '../repositories/CupomRepository'

class CupomController {
  pegarTodos(recursive?: string[]): AsyncGenerator<Cupom, void, Cupom> {
    return CupomRepository.pegarTodos(recursive)
  }

  criar(cupom: Cupom): Promise<Cupom> {
    return CupomRepository.criar(cupom)
  }

  pegarPorId(documentId: string): Promise<Cupom> {
    return CupomRepository.pegarPorId(documentId)
  }

  alterar(cupom: Cupom): Promise<Cupom> {
    return CupomRepository.alterar(cupom)
  }

  deletar(cupom: Cupom): Promise<Cupom> {
    return CupomRepository.deletar(cupom)
  }
}

export default new CupomController()
