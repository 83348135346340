import Produto from '../../../models/Produto'

export enum ProdutosTypes {
  ATUALIZAR_PRODUTOS = '@produtos/ATUALIZAR_TODOS',
  ADICIONAR_PRODUTO = '@produtos/ADICIONAR',
  REMOVER_PRODUTO = '@produtos/REMOVER',
  MODIFICAR_PRODUTO_SELECIONADO = '@produtos/MODIFICAR_SELECIONADO',
  MODIFICAR_LISTA_CARREGADA = '@produtos/MODIFICAR_LISTA_CARREGADA',
  LIMPAR_PRODUTOS = '@produtos/LIMPAR',
}

export interface ProdutosState {
  readonly data: Produto[]
  readonly selecionado: Produto
  readonly listaCarregada: boolean
}

export interface AtualizarProdutos {
  type: typeof ProdutosTypes.ATUALIZAR_PRODUTOS
}

export interface AdicionarProduto {
  type: typeof ProdutosTypes.ADICIONAR_PRODUTO
}

export interface RemoverProduto {
  type: typeof ProdutosTypes.REMOVER_PRODUTO
}

export interface LimparProdutos {
  type: typeof ProdutosTypes.LIMPAR_PRODUTOS
}

export interface ModificarListaCarregadaProdutos {
  type: typeof ProdutosTypes.MODIFICAR_LISTA_CARREGADA
}

export interface ModificarProdutoSelecionado {
  type: typeof ProdutosTypes.MODIFICAR_PRODUTO_SELECIONADO
}
