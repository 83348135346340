// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Grid
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  TableColumnVisibility,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui'
import { ActionColumns, ActionColumn } from '../utils/TableActionButtons'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// TipoServico
import * as ServicoPrestadoActions from '../../store/models/servicosprestados/actions'
import ServicoPrestadoRepository from '../../repositories/ServicoPrestadoRepository'
import ServicoPrestado from '../../models/ServicoPrestado'
import ServicoPrestadoController from '../../controller/ServicoPrestadoController'

interface CidadeAtua {
  cidadeAtua: string
}

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState &
  LoginState &
  typeof LoginActions &
  typeof ServicoPrestadoActions &
  RouteComponentProps<CidadeAtua>

// Determina cabecalho do grid
const cabecalho = [
  { name: 'detalhes' },
  { name: 'id', title: 'Id' },
  { name: 'dia', title: 'Data' },
  { name: 'hora', title: 'Hora' },
  { name: 'parceiro', title: 'Parceiro' },
  { name: 'situacaoAtual', title: 'Situação' },
  { name: 'favoritado', title: 'Favoritado' },
]

// Determina interface para linha do grid
interface IlinhaServPrestado {
  id: string
  dia: string
  hora: string
  parceiro: string
  situacaoAtual: string
  favoritado: string
  servPrestado: ServicoPrestado
}

// Valores padrão no grid
const tableMessages = {
  noData: 'Sem registros',
}
const searchMessages = {
  searchPlaceholder: 'Pesquisar',
}

// 0 solicitado
// 1 aceito
// 2 lincadoACaminho
// 3 iniciado
// 4 avariaRegistrada
// 5 finalizado
// 6 avaliadoCliente
// 7 avaliadoParceiro
// 8 sugestões
// 9 canceladoCliente
// 10 canceladoParceiro
// 11 canceladoSistema
// 12 estornado
const estados: Array<string> = [
  'Solicitado',
  'Aceito',
  'A caminho',
  'Iniciado',
  'Iniciado',
  'Finalizado',
  'Finalizado',
  'Finalizado',
  'Finalizado',
  'Cancelado Cliente',
  'Cancelado Parceiro',
  'Cancelado Sistema',
  'Estornado',
]

interface ServicoPrestadoState {
  quantidade: number
}

class HomeServiPrestado extends Component<Props, ServicoPrestadoState> {
  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }

    // Vincula palavra this a função
    this.goToDetalhes = this.goToDetalhes.bind(this)
    this.state = {
      quantidade: 0,
    }
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  renderLogged(): JSX.Element {
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Serviços prestados</h1>
          <h3 className="tituloPagina">Total de serviços: {this.state.quantidade}</h3>
          {this.renderGrid()}
        </div>
      </div>
    )
  }

  renderNotLogged(): JSX.Element {
    return (
      <div>
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  renderGrid(): JSX.Element {
    // Busca serviços e estrutura registros
    const listServicosPrestados = this.props.models.servicosPrestados ? this.props.models.servicosPrestados.data : []
    let tuplas: readonly IlinhaServPrestado[] = []
    if (listServicosPrestados) {
      tuplas = listServicosPrestados.map(servicoPrestado => {
        let nomeparceiro = 'Sem parceiro'
        if (servicoPrestado.parceiro) {
          nomeparceiro = servicoPrestado.parceiro.nome
        }
        return {
          id: servicoPrestado.id,
          dia: servicoPrestado.situacaoInicial.dataFormatado,
          hora: servicoPrestado.situacaoInicial.horaFormatado,
          parceiro: nomeparceiro,
          favoritado: servicoPrestado.dados.favoritado ? 'Sim' : 'Não',
          situacaoAtual: estados[servicoPrestado.situacaoAtual.estado],
          servPrestado: servicoPrestado,
        }
      })
    }
    // Define coluna de detalhes
    const actionColumns: ActionColumn[] = [
      { columnName: 'detalhes', label: 'Ver detalhes', onClick: this.goToDetalhes, icon: <ZoomInIcon /> },
    ]
    // Monta Grid
    return (
      <div className="grid">
        <Grid rows={tuplas} columns={cabecalho}>
          <PagingState defaultCurrentPage={0} defaultPageSize={5} />
          <SearchState defaultValue="" />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table messages={tableMessages} />
          <TableHeaderRow />
          <Toolbar />
          <SearchPanel messages={searchMessages} />
          <TableColumnVisibility defaultHiddenColumnNames={['id']} />
          <ActionColumns actionColumns={actionColumns} />
          <PagingPanel
            pageSizes={[5, 10, 20]}
            messages={{
              showAll: 'Mostrar todos',
              rowsPerPage: 'Linhas por página',
              info: (parameters: { from: number; to: number; count: number }): string =>
                parameters.from + '-' + parameters.to + ' de ' + parameters.count,
            }}
          />
        </Grid>
      </div>
    )
  }

  private goToDetalhes(linha: IlinhaServPrestado): void {
    this.props.modificarServicoPrestadoSelecionado(linha.servPrestado)
    this.props.history.push('servicosprestados/' + linha.servPrestado.id + '/detalheservico')
  }

  // Recupera lista Servico Prestado
  async componentDidMount(): Promise<void> {
    ServicoPrestadoController.pegarQuantidadeServicosPrestados(this.props.match.params.cidadeAtua).then(value => {
      this.setState({
        quantidade: value,
      })
    })
    if (!this.props.models.servicosPrestados.listaCarregada) {
      let done: boolean | undefined = false
      const asyncGenerator = ServicoPrestadoRepository.getAllServicosPrestados(this.props.match.params.cidadeAtua, [
        'situacao',
        'parceiro',
        'cupom',
      ])
      while (!done) {
        const iteratorResult = await asyncGenerator.next()
        if (iteratorResult.value) {
          this.props.adicionarServicoPrestado(iteratorResult.value)
        }
        done = iteratorResult.done
      }

      this.props.modificarListaCarregadaServicosPrestados(true)
    }
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof ServicoPrestadoActions =>
  bindActionCreators({ ...LoginActions, ...ServicoPrestadoActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HomeServiPrestado)
