import { Reducer } from 'redux'
import { CidadesAtuaState, CidadesAtuaTypes } from './types'
import CidadeAtua from '../../../models/CidadeAtua'

const initialState: CidadesAtuaState = {
  data: [],
  selecionado: new CidadeAtua(),
  listaCarregada: false,
}

const reducer: Reducer<CidadesAtuaState> = (state = initialState, action) => {
  const currentData = state.data

  switch (action.type) {
    case CidadesAtuaTypes.ADICIONAR_CIDADE_ATUA: {
      const newData = currentData.filter(p => p.id !== action.payload.id)
      newData.push(action.payload)
      return { ...state, data: newData }
    }
    case CidadesAtuaTypes.ATUALIZAR_CIDADES_ATUA:
      return { ...state, data: action.payload.data }
    case CidadesAtuaTypes.LIMPAR_CIDADES_ATUA:
      return { ...state, data: [], listaCarregada: false }
    case CidadesAtuaTypes.MODIFICAR_CIDADE_ATUA_SELECIONADO:
      return { ...state, selecionado: action.payload }
    case CidadesAtuaTypes.REMOVER_CIDADE_ATUA: {
      const newData = currentData.filter(cidadeAtua => cidadeAtua.id !== action.payload.id)
      return { ...state, data: newData }
    }
    case CidadesAtuaTypes.MODIFICAR_LISTA_CARREGADA:
      return { ...state, listaCarregada: action.payload }
    default:
      return state
  }
}

export default reducer
