import BaseDocument from './BaseDocument'
import { CidadeAtuaInterface } from './model_interfaces'
import moment, { Moment } from 'moment-timezone'

export default class CidadeAtua extends BaseDocument<CidadeAtua> implements CidadeAtuaInterface {
  nome!: string

  async fillFromJson(json?: any, recursive?: string[] | undefined): Promise<CidadeAtua> {
    if (!json) {
      json = this.dados
    }

    this.nome = json.nome

    return this
  }

  canInsertOrUpdate() {
    if (this.nome.length < 3) {
      throw Error('Código muito curto')
    }
  }
}
