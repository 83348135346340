import FirebaseManager from '../utils/FirebaseManager'
import CidadeAtua from '../models/CidadeAtua'

class CidadeAtuaRepository {
  async criar(cidadeAtua: CidadeAtua): Promise<CidadeAtua> {
    const reference = await FirebaseManager.database
      .collection('cidadesatua')
      .where('nome', '==', cidadeAtua.nome)
      .get()
    if (!reference.empty) {
      const refCidade = reference.docs[0]
      await refCidade.ref.update({ ativo: true })
      const newCidade = await new CidadeAtua().convert(refCidade)
      newCidade.dados.ativo = true
      if (newCidade instanceof CidadeAtua) {
        return newCidade
      }
    }
    const document: firebase.firestore.DocumentReference = FirebaseManager.database.collection('cidadesatua').doc()
    const map = {
      nome: cidadeAtua.nome,
      ativo: true,
    }
    await document.set(map)
    cidadeAtua.ref = document
    return cidadeAtua
  }

  async alterar(cidadeAtua: CidadeAtua): Promise<CidadeAtua> {
    const document: firebase.firestore.DocumentReference = cidadeAtua.ref
    const map = {
      nome: cidadeAtua.nome,
      ativo: true,
    }

    await document.update(map)
    cidadeAtua.ref = document
    return cidadeAtua
  }

  async deletar(cidadeAtua: CidadeAtua): Promise<CidadeAtua> {
    await cidadeAtua.ref.update({
      ativo: false,
    })
    return cidadeAtua
  }

  async pegarPorId(documentId: string, recursive?: string[]): Promise<CidadeAtua> {
    const reference: firebase.firestore.DocumentReference = await FirebaseManager.database
      .collection('cidadesatua')
      .doc(documentId)
    const doc = await reference.get()
    if (doc.exists) {
      const cidadeAtua = await new CidadeAtua().convert(doc, recursive)

      if (cidadeAtua instanceof CidadeAtua) {
        return cidadeAtua
      }
    }

    return new CidadeAtua()
  }

  async pegar(recursive?: string[]): Promise<CidadeAtua[]> {
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('cidadesatua')
      .where('ativo', '==', true)
      .get()
    const queryDocsSnapshot = query.docs
    const cidades: CidadeAtua[] = []
    if (queryDocsSnapshot) {
      for (const doc of queryDocsSnapshot) {
        const data = doc.data()
        if (data && data.pidCliente) {
          continue
        }
        const cidadeAtua = await new CidadeAtua().convert(doc, recursive)

        if (cidadeAtua instanceof CidadeAtua) {
          cidades.push(cidadeAtua)
        }
      }
    }

    return cidades
  }

  async *pegarTodos(recursive?: string[]): AsyncGenerator<CidadeAtua, void, CidadeAtua> {
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('cidadesatua')
      .where('ativo', '==', true)
      .get()
    const queryDocsSnapshot = query.docs
    if (queryDocsSnapshot) {
      for (const doc of queryDocsSnapshot) {
        const data = doc.data()
        if (data && data.pidCliente) {
          continue
        }
        const cidadeAtua = await new CidadeAtua().convert(doc, recursive)

        if (cidadeAtua instanceof CidadeAtua) {
          yield cidadeAtua
        }
      }
    }
  }
}

export default new CidadeAtuaRepository()
