import Administrador from '../models/Administrador'
import FirebaseManager from '../utils/FirebaseManager'

class AdministradorRepository {
  async getAdministradoByUid(uid: string): Promise<Administrador | undefined> {
    const document: firebase.firestore.DocumentSnapshot = await FirebaseManager.database
      .collection('private')
      .doc(uid)
      .get()
    let administrador: Administrador | undefined

    if (document.data()) {
      administrador = document.data() as Administrador
    }
    return administrador
  }
}

export default new AdministradorRepository()
