import { action } from 'typesafe-actions'
import {
  CuponsState,
  CuponsTypes,
  AtualizarCupons,
  AdicionarCupom,
  LimparCupons,
  ModificarCupomSelecionado,
  RemoverCupom,
} from './types'
import Cupom from '../../../models/Cupom'
export const atualizarCupons = (data: CuponsState): AtualizarCupons => action(CuponsTypes.ATUALIZAR_CUPONS, data)

export const adicionarCupom = (newCupom: Cupom): AdicionarCupom => {
  return action(CuponsTypes.ADICIONAR_CUPOM, newCupom)
}

export const removerCupom = (newCupom: Cupom): RemoverCupom => {
  return action(CuponsTypes.REMOVER_CUPOM, newCupom)
}

export const limparCupons = (): LimparCupons => {
  return action(CuponsTypes.LIMPAR_CUPONS)
}

export const modificarCupomSelecionado = (newCupom: Cupom): ModificarCupomSelecionado => {
  return action(CuponsTypes.MODIFICAR_CUPOM_SELECIONADO, newCupom)
}
