import FirebaseManager from '../utils/FirebaseManager'
import Endereco from '../models/Endereco'

class EnderecoRepository {
  async criar(endereco: Endereco): Promise<Endereco> {
    const document: firebase.firestore.DocumentReference = FirebaseManager.database.collection('enderecos').doc()
    const map = {
      apelido: endereco.apelido,
      bairro: endereco.bairro,
      complemento: endereco.complemento,
      cep: endereco.cep,
      cidade: endereco.cidade,
      endereco1: endereco.endereco1,
      endereco2: endereco.endereco2,
      estado: endereco.estado,
      pontoFixo: true,
      ativo: true,
    }
    await document.set(map)
    endereco.ref = document
    return endereco
  }

  async alterar(endereco: Endereco): Promise<Endereco> {
    const document: firebase.firestore.DocumentReference = endereco.ref
    const map = {
      apelido: endereco.apelido,
      bairro: endereco.bairro,
      complemento: endereco.complemento,
      cep: endereco.cep,
      cidade: endereco.cidade,
      endereco1: endereco.endereco1,
      endereco2: endereco.endereco2,
      estado: endereco.estado,
      pontoFixo: true,
      ativo: true,
    }

    await document.update(map)
    endereco.ref = document
    return endereco
  }

  async deletar(endereco: Endereco): Promise<Endereco> {
    await endereco.ref.update({
      ativo: false,
    })
    return endereco
  }

  async pegarPorId(documentId: string, recursive?: string[]): Promise<Endereco> {
    const reference: firebase.firestore.DocumentReference = await FirebaseManager.database
      .collection('enderecos')
      .doc(documentId)
    const doc = await reference.get()
    if (doc.exists) {
      const endereco = await new Endereco().convert(doc, recursive)

      if (endereco instanceof Endereco) {
        return endereco
      }
    }

    return new Endereco()
  }

  async *pegarTodos(recursive?: string[]): AsyncGenerator<Endereco, void, Endereco> {
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('enderecos')
      .where('ativo', '==', true)
      .where('pontoFixo', '==', true)
      .get()
    const queryDocsSnapshot = query.docs
    if (queryDocsSnapshot) {
      for (const doc of queryDocsSnapshot) {
        const data = doc.data()
        if (data && data.pidCliente) {
          continue
        }
        const endereco = await new Endereco().convert(doc, recursive)

        if (endereco instanceof Endereco) {
          yield endereco
        }
      }
    }
  }
}

export default new EnderecoRepository()
