// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Grid
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  TableColumnVisibility,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui'
// CSS
import './Financeiro.css'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
import * as ParceirosActions from '../../store/models/parceiros/actions'
import moment from 'moment-timezone'
import * as ExtratosPagamentoActions from '../../store/models/extratospagamento/actions'
import ParceiroController from '../../controller/ParceiroController'
import Visibility from '@material-ui/icons/Visibility'
import Create from '@material-ui/icons/Create'
import Delete from '@material-ui/icons/Delete'
import { Button, Form } from 'react-bootstrap'
import ExtratoPagamentoController from '../../controller/ExtratoPagamentoController'
import { ActionColumn, ActionColumns } from '../utils/TableActionButtons'
import ExtratoPagamento, { TipoFiltroExtratoPagamento } from '../../models/ExtratoPagamento'
import ReactDatePicker from 'react-datepicker'
import Parceiro from '../../models/Parceiro'
import { boolean } from 'yargs'
import { valueToNode } from '@babel/types'

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState &
  LoginState &
  typeof LoginActions &
  typeof ExtratosPagamentoActions &
  RouteComponentProps<any> &
  typeof ParceirosActions
interface RowFinanceiro {
  nome: string
  dataInicio: string
  dataFim: string
  pago: string
  bruto: string
  liquido: string
  extratoPagamento: ExtratoPagamento
}
// Determina cabecalho do grid
const cabecalho = [
  { name: 'visualizar' },
  { name: 'alterar' },
  { name: 'excluir' },
  { name: 'nome', title: 'Parceiro' },
  { name: 'dataInicio', title: 'Data Inicio' },
  { name: 'dataFim', title: 'Data Fim' },
  { name: 'pago', title: 'Pago' },
  { name: 'bruto', title: 'Valor bruto' },
  { name: 'liquido', title: 'Valor líquido' },
]

// Valores padrão no grid
const tableMessages = {
  noData: 'Sem registros',
}
const searchMessages = {
  searchPlaceholder: 'Pesquisar',
}

interface StateFinanceiro {
  radioSelected: number
  dataInicio: Date | null
  dataFim: Date | null
  parceiro: Parceiro
  posParceiro: number
  extratosPagamento: ExtratoPagamento[]
  filtroStatus: FiltroStatus
}

interface FiltroStatus {
  status: TipoStatus
  filtro: TipoFiltroExtratoPagamento
}

enum TipoStatus {
  NADA = 0,
  CARREGANDO = 1,
  CONCLUIDO = 2,
}

class Financeiro extends Component<Props, StateFinanceiro> {
  constructor(props: Props) {
    super(props)
    this.handleClickFiltro = this.handleClickFiltro.bind(this)
    this.goToPdf = this.goToPdf.bind(this)
    this.goToExtratoPagamento = this.goToExtratoPagamento.bind(this)
    this.handleClickExcluir = this.handleClickExcluir.bind(this)
    this.handleClickAlterar = this.handleClickAlterar.bind(this)
    this.renderDropdown = this.renderDropdown.bind(this)
    this.handleCarregarByFiltro = this.handleCarregarByFiltro.bind(this)
    this.handleChangeDropDown = this.handleChangeDropDown.bind(this)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }

    this.state = {
      radioSelected: 0,
      dataInicio: null,
      dataFim: null,
      parceiro: new Parceiro(),
      posParceiro: 0,
      extratosPagamento: [],
      filtroStatus: {
        filtro: TipoFiltroExtratoPagamento.TUDO,
        status: TipoStatus.NADA,
      },
    }
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  handleClickFiltro(index: number): void {
    if (this.state.radioSelected !== index) {
      this.setState({
        radioSelected: index,
      })
    }
  }

  filtrarTodosCarregados(tipoFiltro: number): void {
    const listParceiros = this.props.models.parceiros ? this.props.models.parceiros.data : []
    if (listParceiros) {
      let dataInicial = moment(0)
      const dataFinal = moment()
      switch (tipoFiltro) {
        case 0:
          dataInicial = moment(dataFinal).subtract(7, 'days')
          break
        case 1:
          dataInicial = moment(dataFinal).subtract(1, 'month')
          break
      }

      for (const parceiro of listParceiros) {
        for (const fatura of parceiro.faturas) {
          if (fatura.data.isBefore(dataInicial) || fatura.data.isAfter(dataFinal)) {
            fatura.ativo = false
          } else {
            fatura.ativo = true
          }
        }
      }
    }
  }

  handleChangeDropDown(event: React.ChangeEvent<HTMLSelectElement>): void {
    this.setState({
      posParceiro: Number(event.target.value),
      parceiro: this.props.models.parceiros.data[Number(event.target.value)],
    })
  }

  renderDropdown(): JSX.Element {
    const options: JSX.Element[] = this.props.models.parceiros.data.map<JSX.Element>((parceiro, idx) => {
      return (
        <option key={idx} value={idx}>
          {parceiro.nome}
        </option>
      )
    })
    return (
      <Form.Group
        className="dropdown"
        hidden={
          this.state.radioSelected === TipoFiltroExtratoPagamento.DATA ||
          this.state.radioSelected === TipoFiltroExtratoPagamento.TUDO
        }
      >
        <Form.Control as="select" onChange={this.handleChangeDropDown} value={this.state.posParceiro}>
          {options}
        </Form.Control>
      </Form.Group>
    )
  }

  async handleCarregarByFiltro(): Promise<void> {
    if (
      this.state.filtroStatus.filtro === this.state.radioSelected &&
      this.state.filtroStatus.status === TipoStatus.CARREGANDO
    ) {
      return
    }
    let done: boolean | undefined = false
    switch (this.state.radioSelected) {
      case TipoFiltroExtratoPagamento.TUDO:
        if (!this.props.models.extratosPagamento.data || !this.props.models.extratosPagamento.listaCarregada) {
          this.setState({
            filtroStatus: {
              filtro: TipoFiltroExtratoPagamento.TUDO,
              status: TipoStatus.CARREGANDO,
            },
          })
          this.props.limparExtratosPagamento()
          const asyncGeneratorParceiro = ExtratoPagamentoController.pegarTodos(['parceiro'])
          while (!done) {
            if (this.state.radioSelected !== TipoFiltroExtratoPagamento.TUDO) {
              return
            }
            const iteratorResult = await asyncGeneratorParceiro.next()
            if (iteratorResult.value) {
              this.props.adicionarExtratoPagamento(iteratorResult.value)
              const newExtratos = this.state.extratosPagamento
              newExtratos.push(iteratorResult.value)
              this.setState({
                extratosPagamento: newExtratos,
              })
            }
            done = iteratorResult.done
          }

          this.props.modificarListaCarregadaExtratosPagamento(true)
          this.setState({
            filtroStatus: {
              filtro: TipoFiltroExtratoPagamento.TUDO,
              status: TipoStatus.CONCLUIDO,
            },
          })
        } else {
          this.setState({
            extratosPagamento: this.props.models.extratosPagamento.data,
          })
        }
        break

      case TipoFiltroExtratoPagamento.DATA:
        {
          if (this.state.dataInicio == null || this.state.dataFim == null) {
            alert('Você deve selecionar uma data válida')
            return
          }
          this.setState({
            filtroStatus: {
              filtro: TipoFiltroExtratoPagamento.DATA,
              status: TipoStatus.CARREGANDO,
            },
          })
          this.setState({
            extratosPagamento: [],
          })
          const asyncGenerator = ExtratoPagamentoController.pegarFiltro(
            TipoFiltroExtratoPagamento.DATA,
            moment(this.state.dataInicio),
            moment(this.state.dataFim),
          )
          while (!done) {
            const iteratorResult = await asyncGenerator.next()
            if (iteratorResult.value) {
              const newExtratos = this.state.extratosPagamento
              newExtratos.push(iteratorResult.value)
              this.setState({
                extratosPagamento: newExtratos,
              })
            }
            done = iteratorResult.done
          }
        }

        this.setState({
          filtroStatus: {
            filtro: TipoFiltroExtratoPagamento.DATA,
            status: TipoStatus.CONCLUIDO,
          },
        })
        break

      case TipoFiltroExtratoPagamento.DATA_PARCEIRO:
        {
          if (this.state.dataInicio == null || this.state.dataFim == null) {
            alert('Você deve selecionar uma data válida')
            return
          }
          this.setState({
            filtroStatus: {
              filtro: TipoFiltroExtratoPagamento.DATA_PARCEIRO,
              status: TipoStatus.CARREGANDO,
            },
          })
          this.setState({
            extratosPagamento: [],
          })
          const asyncGenerator = ExtratoPagamentoController.pegarFiltro(
            TipoFiltroExtratoPagamento.DATA_PARCEIRO,
            moment(this.state.dataInicio),
            moment(this.state.dataFim),
            this.state.parceiro,
          )
          while (!done) {
            const iteratorResult = await asyncGenerator.next()
            if (iteratorResult.value) {
              const newExtratos = this.state.extratosPagamento
              newExtratos.push(iteratorResult.value)
              this.setState({
                extratosPagamento: newExtratos,
              })
            }
            done = iteratorResult.done
          }
          this.setState({
            filtroStatus: {
              filtro: TipoFiltroExtratoPagamento.DATA_PARCEIRO,
              status: TipoStatus.CONCLUIDO,
            },
          })
        }
        break
      case TipoFiltroExtratoPagamento.PARCEIRO:
        {
          this.setState({
            extratosPagamento: [],
          })
          this.setState({
            filtroStatus: {
              filtro: TipoFiltroExtratoPagamento.PARCEIRO,
              status: TipoStatus.CARREGANDO,
            },
          })
          const asyncGenerator = ExtratoPagamentoController.pegarFiltro(
            TipoFiltroExtratoPagamento.PARCEIRO,
            undefined,
            undefined,
            this.state.parceiro,
          )
          while (!done) {
            const iteratorResult = await asyncGenerator.next()
            if (iteratorResult.value) {
              const newExtratos = this.state.extratosPagamento
              newExtratos.push(iteratorResult.value)
              this.setState({
                extratosPagamento: newExtratos,
              })
            }
            done = iteratorResult.done
          }

          this.setState({
            filtroStatus: {
              filtro: TipoFiltroExtratoPagamento.PARCEIRO,
              status: TipoStatus.CONCLUIDO,
            },
          })
        }
        break
      default:
        break
    }
  }

  renderLogged(): JSX.Element {
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Relatório financeiro</h1>
          <br />
          <div className="filtro-botao">
            <div className="filtroPeriodo">
              <input
                onChange={(): void => this.handleClickFiltro(TipoFiltroExtratoPagamento.TUDO)}
                checked={this.state.radioSelected === TipoFiltroExtratoPagamento.TUDO}
                type="radio"
                id="rdotudo"
                name="periodo"
                value="tudo"
              />
              <span>Tudo</span>
              <input
                onChange={(): void => this.handleClickFiltro(TipoFiltroExtratoPagamento.DATA)}
                checked={this.state.radioSelected === TipoFiltroExtratoPagamento.DATA}
                type="radio"
                id="rdo7"
                name="periodo"
                value="7dias"
              />
              <span>Filtrar por data</span>
              <input
                onChange={(): void => this.handleClickFiltro(TipoFiltroExtratoPagamento.DATA_PARCEIRO)}
                checked={this.state.radioSelected === TipoFiltroExtratoPagamento.DATA_PARCEIRO}
                type="radio"
                id="rdoparceiro-data"
                name="periodo"
                value="data-parceiro"
              />
              <span>Filtrar por data e parceiro</span>
              <input
                onChange={(): void => this.handleClickFiltro(TipoFiltroExtratoPagamento.PARCEIRO)}
                checked={this.state.radioSelected === TipoFiltroExtratoPagamento.PARCEIRO}
                type="radio"
                id="rdoparceiro"
                name="periodo"
                value="parceiro"
              />
              <span>Filtrar por parceiro</span>
            </div>
            <div>
              <Button className="btn-dark" onClick={this.goToExtratoPagamento}>
                Extrato de pagamento
              </Button>
            </div>
          </div>
          <div className="form-inline">
            {this.renderDropdown()}
            <Form.Group
              controlId="formaBasicInicio"
              hidden={
                this.state.radioSelected === TipoFiltroExtratoPagamento.PARCEIRO ||
                this.state.radioSelected === TipoFiltroExtratoPagamento.TUDO
              }
            >
              <ReactDatePicker
                className="datepicker"
                placeholderText="Dt Início"
                selected={this.state.dataInicio}
                onChange={(date): void => this.setState({ dataInicio: date || new Date() })}
                dateFormat="dd/MM/yyyy"
              />
            </Form.Group>
            <Form.Group
              controlId="formaBasicFim"
              hidden={
                this.state.radioSelected === TipoFiltroExtratoPagamento.PARCEIRO ||
                this.state.radioSelected === TipoFiltroExtratoPagamento.TUDO
              }
            >
              <ReactDatePicker
                className="datepicker"
                placeholderText="Dt Fim"
                selected={this.state.dataFim}
                onChange={(date): void => this.setState({ dataFim: date || new Date() })}
                dateFormat="dd/MM/yyyy"
              />
            </Form.Group>
            <Button onClick={this.handleCarregarByFiltro}>Carregar</Button>
          </div>
          <div className="caixaExtrato">
            <p>Valor total</p>
            <p className="valorExtrato">R$ 139,50</p>
          </div>
          <div className="caixaExtrato">
            <p>Valor líquido</p>
            <p className="valorExtrato">R$ 139,50</p>
          </div>
          {this.renderGrid()}
        </div>
      </div>
    )
  }

  private handleClickAlterar(row: RowFinanceiro): void {
    this.props.modificarExtratoPagamentoSelecionado(row.extratoPagamento)
    this.props.history.push('financeiro/' + row.extratoPagamento.ref.id + '/alterar')
  }

  goToExtratoPagamento(): void {
    this.props.history.push('financeiro/adicionar')
  }

  goToPdf(row: RowFinanceiro): void {
    this.props.modificarExtratoPagamentoSelecionado(new ExtratoPagamento())
    this.props.history.push('financeiro/' + row.extratoPagamento.ref.id + '/pdf')
  }

  private handleClickExcluir(row: RowFinanceiro): void {
    ExtratoPagamentoController.deletar(row.extratoPagamento)
    this.props.removerExtratoPagamento(row.extratoPagamento)

    const newExtratos = []
    for (const extrato of this.state.extratosPagamento) {
      if (extrato.ref.id !== row.extratoPagamento.ref.id) {
        newExtratos.push(extrato)
      }
    }
    this.setState({
      extratosPagamento: newExtratos,
    })
  }

  renderGrid(): JSX.Element {
    // Busca serviços e estrutura registros
    const listParceiros = this.state.extratosPagamento
    const tuplas: RowFinanceiro[] = []
    if (listParceiros) {
      listParceiros.forEach(extrato => {
        tuplas.push({
          bruto: extrato.valorBruto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
          dataInicio: extrato.dataInicio.toDate().toLocaleDateString(),
          pago: extrato.pago ? 'Sim' : 'Não',
          liquido: extrato.valorLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
          nome: extrato.parceiro.nome,
          dataFim: extrato.dataFim.toDate().toLocaleDateString(),
          extratoPagamento: extrato,
        })
      })
    }
    const actionColumns: ActionColumn[] = [
      { columnName: 'excluir', label: 'Excluir linha', onClick: this.handleClickExcluir, icon: <Delete /> },
      { columnName: 'alterar', label: 'Abrir modo edição', onClick: this.handleClickAlterar, icon: <Create /> },
      {
        columnName: 'visualizar',
        label: 'Gerar PDF',
        onClick: this.goToPdf,
        icon: <Visibility />,
      },
    ]
    // Monta Grid
    return (
      <div className="grid">
        <Grid rows={tuplas} columns={cabecalho}>
          <PagingState defaultCurrentPage={0} defaultPageSize={5} />
          <SearchState defaultValue="" />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table messages={tableMessages} />
          <TableHeaderRow />
          <Toolbar />
          <SearchPanel messages={searchMessages} />
          <TableColumnVisibility defaultHiddenColumnNames={['id']} />
          <ActionColumns actionColumns={actionColumns} />
          <PagingPanel
            pageSizes={[5, 10, 20]}
            messages={{
              showAll: 'Mostrar todos',
              rowsPerPage: 'Linhas por página',
              info: (parameters: { from: number; to: number; count: number }): string =>
                parameters.from + '-' + parameters.to + ' de ' + parameters.count,
            }}
          />
        </Grid>
      </div>
    )
  }

  renderNotLogged(): JSX.Element {
    return (
      <div>
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  async componentDidMount(): Promise<void> {
    let done: boolean | undefined = false

    if (!this.props.models.parceiros.listaCarregada) {
      let done: boolean | undefined = false
      let first = true
      this.props.limparParceiros()
      const asyncGeneratorParceiro = ParceiroController.pegarTodos(this.props.match.params.cidadeAtua, ['not_endereco'])
      while (!done) {
        const iteratorResult = await asyncGeneratorParceiro.next()
        if (iteratorResult.value) {
          if (first) {
            this.setState({
              parceiro: iteratorResult.value,
            })
            first = false
          }
          this.props.adicionarParceiro(iteratorResult.value)
        }
        done = iteratorResult.done
      }
      this.props.modificarListaCarregadaParceiros(true)
    }
    // Caso a lista de extratospagamentos esteja vazia ele carrega todos
    if (!this.props.models.extratosPagamento.data || !this.props.models.extratosPagamento.listaCarregada) {
      this.setState({
        filtroStatus: {
          filtro: TipoFiltroExtratoPagamento.TUDO,
          status: TipoStatus.CARREGANDO,
        },
      })
      this.props.limparExtratosPagamento()
      const asyncGeneratorParceiro = ExtratoPagamentoController.pegarTodos(['parceiro', 'not_endereco'])
      while (!done) {
        const iteratorResult = await asyncGeneratorParceiro.next()
        if (iteratorResult.value) {
          if (this.state.radioSelected !== TipoFiltroExtratoPagamento.TUDO) {
            return
          }
          this.props.adicionarExtratoPagamento(iteratorResult.value)
          const newExtratos = this.state.extratosPagamento
          newExtratos.push(iteratorResult.value)
          this.setState({
            extratosPagamento: newExtratos,
          })
        }
        done = iteratorResult.done
      }
      this.setState({
        filtroStatus: {
          filtro: TipoFiltroExtratoPagamento.TUDO,
          status: TipoStatus.CONCLUIDO,
        },
      })

      this.props.modificarListaCarregadaExtratosPagamento(true)
    } else {
      this.setState({
        extratosPagamento: this.props.models.extratosPagamento.data,
      })
    }
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (
  dispatch: Dispatch,
): typeof LoginActions & typeof ExtratosPagamentoActions & typeof ParceirosActions =>
  bindActionCreators({ ...LoginActions, ...ExtratosPagamentoActions, ...ParceirosActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Financeiro)
