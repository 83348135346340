// React + redux
import React, { Component } from 'react'
import { RouteComponentProps, Route, Switch } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// TipoServico
import * as ServicoPrestadoActions from '../../store/models/servicosprestados/actions'
import HomeServicoPrestado from './Home'
import ServiPrestadoDetalhe from './ServiPrestDetalhe'

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState & LoginState & typeof LoginActions & typeof ServicoPrestadoActions & RouteComponentProps

class ServiPrestado extends Component<Props> {
  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
  }

  render(): JSX.Element {
    return (
      <Switch>
        <Route exact path="/:cidadeAtua/servicosprestados/" component={HomeServicoPrestado} />
        <Route path="/:cidadeAtua/servicosprestados/:id/detalheservico" component={ServiPrestadoDetalhe} />
      </Switch>
    )
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof ServicoPrestadoActions =>
  bindActionCreators({ ...LoginActions, ...ServicoPrestadoActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ServiPrestado)
