import FirebaseManager from '../utils/FirebaseManager'
import Parceiro from '../models/Parceiro'

class ParceiroRepository {
  async alterar(parceiro: Parceiro): Promise<Parceiro> {
    const document: firebase.firestore.DocumentReference = parceiro.ref

    const mapEndereco = {
      bairro: parceiro.endereco.bairro,
      cep: parceiro.endereco.cep,
      cidade: parceiro.endereco.cidade,
      endereco1: parceiro.endereco.endereco1,
      endereco2: parceiro.endereco.endereco2,
      estado: parceiro.endereco.estado,
    }

    const map = {
      validado: parceiro.validado || false,
      endereco: mapEndereco,
      nome: parceiro.nome,
      aptoLoja: parceiro.aptoLoja || false,
      aptoPontoFixo: parceiro.aptoPontoFixo || false,
    }

    const publicMap = {
      nome: parceiro.nome,
      validado: parceiro.validado || false,
      tiposServicosAptos: parceiro.tiposServicosAptos,
      cidade: parceiro.cidade,
      aptoLoja: parceiro.aptoLoja || false,
      aptoPontoFixo: parceiro.aptoPontoFixo || false,
    }

    const user: firebase.firestore.DocumentReference = FirebaseManager.database.collection('usuarios').doc(parceiro.uid)
    const batch = FirebaseManager.database.batch()

    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('disponibilidades')
      .where('parceiroRef', '==', parceiro.pparceiro)
      .get()

    for (const doc of query.docs) {
      batch.update(doc.ref, { cidade: parceiro.cidade })
    }

    batch.update(user, {
      validado: parceiro.validado || false,
      aptoLoja: parceiro.aptoLoja || false,
      aptoPontoFixo: parceiro.aptoPontoFixo || false,
      cidade: parceiro.cidade,
    })
    batch.update(document, map)
    batch.update(parceiro.pparceiro, publicMap)
    await batch.commit()
    parceiro.ref = document
    return parceiro
  }

  async deletar(parceiro: Parceiro): Promise<Parceiro> {
    const batch = FirebaseManager.database.batch()
    const user: firebase.firestore.DocumentSnapshot = await FirebaseManager.database
      .collection('private')
      .doc(parceiro.uid)
      .get()

    batch.update(user.ref, {
      validado: false,
    })

    batch.update(parceiro.ref, {
      validado: false,
    })

    await batch.commit()
    return parceiro
  }

  async *pegarTodos(cidade: string, recursive?: string[]): AsyncGenerator<Parceiro, void, Parceiro> {
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('usuarios')
      .where('role', '==', 100)
      .where('cidade', 'array-contains', cidade)
      .get()
    const queryDocsSnapshot = query.docs
    if (queryDocsSnapshot) {
      for (const doc of queryDocsSnapshot) {
        const queryParceiro: firebase.firestore.QuerySnapshot = await FirebaseManager.database
          .collection('private')
          .doc(doc.id)
          .collection('parceiro')
          .get()

        const parceiroDoc = queryParceiro.docs[0]
        const parceiro = await new Parceiro().convert(parceiroDoc, recursive)

        if (parceiro instanceof Parceiro) {
          parceiro.uid = doc.id
          parceiro.pidExtrato = doc.data().publicUid.extratospagamento

          yield parceiro
        }
      }
    }
  }

  async pegarPorId(documentId: string, recursive?: string[]): Promise<Parceiro> {
    const queryParceiro: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('private')
      .doc(documentId)
      .collection('parceiro')
      .get()

    const parceiroDoc = queryParceiro.docs[0]

    const parceiro = await new Parceiro().convert(parceiroDoc, recursive)

    if (parceiro instanceof Parceiro) {
      parceiro.uid = documentId

      return parceiro
    }
    return new Parceiro()
  }

  async pegarPorParceiroUid(parceiroUid: string, recursive?: string[]): Promise<Parceiro> {
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('usuarios')
      .where('publicUid.servicosprestados', '==', parceiroUid)
      .get()

    const parceiroDoc = query.docs[0]

    const parceiro = await this.pegarPorId(parceiroDoc.id, recursive)
    parceiro.uid = parceiroDoc.id

    return parceiro
  }

  async *pegarTodosParceiroUid(): AsyncGenerator<string, void, string> {
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('usuarios')
      .where('role', '==', 100)
      .get()
    const queryDocsSnapshot = query.docs
    if (queryDocsSnapshot) {
      const user = queryDocsSnapshot[0].data()
      if (user) {
        yield user.renderServicosPorVeiculo
      }
    }
  }
}

export default new ParceiroRepository()
