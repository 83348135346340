import FirebaseManager from '../utils/FirebaseManager'
import TipoVeiculo from '../models/TipoVeiculo'

class TipoVeiculoRepository {
  async criar(tipoVeiculo: TipoVeiculo): Promise<TipoVeiculo> {
    const document: firebase.firestore.DocumentReference = FirebaseManager.database.collection('tiposveiculos').doc()
    const map = {
      modelo: tipoVeiculo.modelo,
      marca: tipoVeiculo.marca,
      tamanho: tipoVeiculo.tamanho,
      ativo: true,
    }
    await document.set(map)
    tipoVeiculo.ref = document
    return tipoVeiculo
  }

  async alterar(tipoVeiculo: TipoVeiculo): Promise<TipoVeiculo> {
    const document: firebase.firestore.DocumentReference = tipoVeiculo.ref
    const map = {
      modelo: tipoVeiculo.modelo,
      marca: tipoVeiculo.marca,
      tamanho: tipoVeiculo.tamanho,
    }

    await document.update(map)
    tipoVeiculo.ref = document
    return tipoVeiculo
  }

  async deletar(tipoVeiculo: TipoVeiculo): Promise<TipoVeiculo> {
    await tipoVeiculo.ref.update({
      ativo: false,
    })
    return tipoVeiculo
  }

  async pegarPorId(documentId: string, recursive?: string[]): Promise<TipoVeiculo> {
    const reference: firebase.firestore.DocumentReference = await FirebaseManager.database
      .collection('tiposveiculos')
      .doc(documentId)
    const doc = await reference.get()
    if (doc.exists) {
      const tipoVeiculo = await new TipoVeiculo().convert(doc, recursive)

      if (tipoVeiculo instanceof TipoVeiculo) {
        return tipoVeiculo
      }
    }

    return new TipoVeiculo()
  }

  async pegarTodosTiposServicos(recursive?: string[]): Promise<TipoVeiculo[]> {
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('tiposveiculos')
      .where('ativo', '==', true)
      .get()
    const queryDocsSnapshot = query.docs
    const list = []
    if (queryDocsSnapshot) {
      for (const doc of queryDocsSnapshot) {
        const tipoVeiculo = await new TipoVeiculo().convert(doc, recursive)

        if (tipoVeiculo instanceof TipoVeiculo) {
          list.push(tipoVeiculo)
        }
      }
    }

    return list
  }
}

export default new TipoVeiculoRepository()
