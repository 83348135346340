import { Reducer } from 'redux'
import { EnderecosState, EnderecosTypes } from './types'
import Endereco from '../../../models/Endereco'

const initialState: EnderecosState = {
  data: [],
  selecionado: new Endereco(),
  listaCarregada: false,
}

const reducer: Reducer<EnderecosState> = (state = initialState, action) => {
  const currentData = state.data

  switch (action.type) {
    case EnderecosTypes.ADICIONAR_ENDERECO: {
      const newData = currentData.filter(p => p.id !== action.payload.id)
      newData.push(action.payload)
      return { ...state, data: newData }
    }
    case EnderecosTypes.ATUALIZAR_ENDERECOS:
      return { ...state, data: action.payload.data }
    case EnderecosTypes.LIMPAR_ENDERECOS:
      return { ...state, data: [], listaCarregada: false }
    case EnderecosTypes.MODIFICAR_ENDERECO_SELECIONADO:
      return { ...state, selecionado: action.payload }
    case EnderecosTypes.REMOVER_ENDERECO: {
      const newData = currentData.filter(endereco => endereco.id !== action.payload.id)
      return { ...state, data: newData }
    }
    case EnderecosTypes.MODIFICAR_LISTA_CARREGADA:
      return { ...state, listaCarregada: action.payload }
    default:
      return state
  }
}

export default reducer
