// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

// Une todos em props antes de passar ao ao componente
type Props = RouteComponentProps

export class SejaParceiro extends Component<Props> {
  private navCollapse: React.RefObject<HTMLDivElement>
  constructor(props: Props) {
    super(props)
    this.navCollapse = React.createRef<HTMLDivElement>()
    this.handleNavToggle = this.handleNavToggle.bind(this)
  }

  handleNavToggle(): void {
    const current = this.navCollapse.current
    if (current) {
      if (current.className.includes('show')) {
        current.className = current.className.replace('show', '')
      } else {
        current.className = current.className + ' show'
      }
    }
  }

  render(): JSX.Element {
    return (
      <div id="page-top">
        <nav className="navbar navbar-expand-lg bg-secondary fixed-top" id="mainNav">
          <div className="container">
            <a className="navbar-brand js-scroll-trigger" href="#page-top">
              <img src={process.env.PUBLIC_URL + '/assets/img/logo_icon.png'} alt="Símbolo da empresa Lincar" />
              <img src={process.env.PUBLIC_URL + '/assets/img/logo_nome.png'} alt="Lincar" />
            </a>
            <button
              className="navbar-toggler navbar-toggler-right font-weight-bold bg-primary text-white rounded"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              onClick={this.handleNavToggle}
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              Menu
              <FontAwesomeIcon icon={faBars} size="lg" color="white" style={{ marginLeft: 10 }} />
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive" ref={this.navCollapse}>
              <ul className="navbar-nav ml-auto">
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger" href="servicos">
                    Serviços
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger" href="club">
                    Lincar Club
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger" href="faleconosco">
                    Fale conosco
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a
                    className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger seja nav-item-selected"
                    href="seja-parceiro"
                  >
                    Seja Parceiro
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <section className="bloco-inicio">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 ml-auto">
                <h1 className="titulo">Seja um parceiro LinCar!</h1>
                <div className="subtitulo" style={{ paddingBottom: 80 }}>
                  <h6>Entre em contato agora mesmo e se cadastre para fazer parte dessa equipe.</h6>
                </div>
                <a href="https://parceiro.lincar.com.br/">
                  <input className="sejamesmo" type="button" value="SAIBA MAIS" />
                </a>
                <br />
                <br />
                <br />
              </div>
              <div className="col-lg-4 mr-auto">
                <img className="imagem" src={process.env.PUBLIC_URL + '/assets/img/Ilustra_seja.png'} alt="" />
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
        <br />
        <section>
          <div className="container" style={{ width: '85%' }}>
            <div className="row text-center">
              <div>
                <p className="benebene">BENEFÍCIOS</p>
                <p className="benetitulo">As vantagens de ser um parceiro LinCar</p>
                <p className="benetexto">
                  Seja nosso parceiro. Se inscreva através do <a href="https://parceiro.lincar.com.br/">formulário</a>{' '}
                  para se tornar um parceiro Lincar. Garanta sua renda principal ou extra realizando serviços como
                  parceiro LinCar. Aqui, o quanto irá faturar só depende de você!
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="page-section portfolio" id="portfolio">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="mx-auto benecaixa">
                  <p className="benecaixatitulo">Do aceite ao trabalho</p>
                  <img
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + '/assets/img/seja_bene_celular.png'}
                    alt=""
                  />
                  <p className="benecaixatexto">
                    Após seu aceite no cadastro, você passará por um treinamento exclusivo para começar a trabalhar.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="mx-auto benecaixa">
                  <p className="benecaixatitulo">Seja seu próprio chefe</p>
                  <img
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + '/assets/img/seja_bene_relogio.png'}
                    alt=""
                  />
                  <p className="benecaixatexto">
                    Sendo nosso parceiro, você tem a autonomia para escolher o seu horário de trabalho.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="mx-auto benecaixa">
                  <p className="benecaixatitulo">Vá como puder</p>
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/seja_bene_carro.png'} alt="" />
                  <p className="benecaixatexto">
                    O importante é chegar ao local e prestar um bom atendimento ao cliente. Não importa se você irá de
                    carro, moto, etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 mb-5 mb-lg-0">
                <img
                  src={process.env.PUBLIC_URL + '/assets/img/logo_icon_nome_branco.png'}
                  alt="Símbolo da empresa Lincar"
                />
              </div>
              <div className="col-lg-3 mb-5 mb-lg-0">
                <ul className="linkrodape">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="servicos">Serviços</a>
                  </li>
                  <li>
                    <a href="club">Lincar Club</a>
                  </li>
                  <li>
                    <a href="faleconosco">Fale conosco</a>
                  </li>
                  <li>
                    <a href="seja-parceiro" style={{ fontWeight: 'bold' }}>
                      Seja Parceiro
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-7">
                <p className="mb-0 endereco">Travessa Julio Feydit, 19 Lapa / Campos dos Goytacazes Cep:28010-180</p>
              </div>
            </div>
          </div>
        </footer>
        <section className="page-section">
          <div className="container">
            <div className="row" style={{ paddingTop: 20, textAlign: 'center' }}>
              <div className="col-md-6 col-lg-4 mb-5" style={{ paddingTop: 10 }}>
                <img src={process.env.PUBLIC_URL + '/assets/img/logo_teccampos.png'} />
              </div>
              <div className="col-md-6 col-lg-4 mb-5" style={{ textAlign: 'center' }}>
                <img src={process.env.PUBLIC_URL + '/assets/img/logo_embrapii.png'} />
              </div>
              <div className="col-md-6 col-lg-4 mb-5" style={{ textAlign: 'center' }}>
                <img src={process.env.PUBLIC_URL + '/assets/img/logo_fundecam.png'} width="120px" />
              </div>
            </div>
          </div>
        </section>
        <div className="scroll-to-top d-lg-none position-fixed">
          <a className="js-scroll-trigger d-block text-center text-white rounded" href="#page-top">
            <i className="fa fa-chevron-up"></i>
          </a>
        </div>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.bundle.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js"></script>
        <script src={process.env.PUBLIC_URL + '/scripts.js'}></script>
      </div>
    )
  }
}
