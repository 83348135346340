// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// TipoVeiculo
import * as TiposVeiculosActions from '../../store/models/tiposveiculos/actions'
import TipoVeiculoController from '../../controller/TipoVeiculoController'
import TipoVeiculo from '../../models/TipoVeiculo'
import 'react-datepicker/dist/react-datepicker.css'
// Form
import { Form, Button } from 'react-bootstrap'
//Dropdown
import Dropdown, { Option } from 'react-dropdown'
import 'react-dropdown/style.css'

interface TipoVeiculoAdicionarState {
  tamanho: string
}

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState & LoginState & typeof LoginActions & typeof TiposVeiculosActions & RouteComponentProps

class TipoVeiculoAdicionar extends Component<Props, TipoVeiculoAdicionarState> {
  private tipoVeiculo: TipoVeiculo = new TipoVeiculo()
  private modelo: React.RefObject<HTMLInputElement>
  private marca: React.RefObject<HTMLInputElement>

  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    // Vincula palavra this a função
    this.goBack = this.goBack.bind(this)
    this.salvarTipoVeiculo = this.salvarTipoVeiculo.bind(this)
    this.modelo = React.createRef<HTMLInputElement>()
    this.marca = React.createRef<HTMLInputElement>()
    this.onChangeDropdown = this.onChangeDropdown.bind(this)
    this.state = {
      tamanho: 'Pequeno'
    }
  }

  onChangeDropdown(option: Option): void {
    this.setState({
      tamanho: option.value
    })
  }

  render(): JSX.Element {
    return this.props.login.isLogged
      ? this.renderLogged()
      : this.renderNotLogged()
  }

  renderLogged(): JSX.Element {
    //Define estilo dos botões
    let estiloBtnAdicionar, estiloBtnCancelar
    estiloBtnAdicionar = {
      backgroundColor: '#113F4F',
      color: '#FFFFFF',
      borderRadius: '0'
    }
    estiloBtnCancelar = {
      backgroundColor: '#1B9999',
      color: '#FFFFFF',
      borderRadius: '0'
    }
    return (
      <div>
        <Menu {...this.props} {...this.props.login}>
        </Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Adicionar novo Tipo de Veiculo</h1>
          <br />
          <Form className="form">
            <p>Marca</p>
            <Form.Group controlId="formaBasicMarca">
              <Form.Control ref={this.marca} type="text" placeholder="Marca" />
            </Form.Group>
            <p>Modelo</p>
            <Form.Group controlId="formBasicModelo">
              <Form.Control ref={this.modelo} type="text" placeholder="Modelo" />
            </Form.Group>
            <p>Tamanho</p>
            <Form.Group controlId="formaBasicTamanho">
              <Dropdown options={['Pequeno', 'Medio', 'Grande']} onChange={this.onChangeDropdown} value={this.state.tamanho} placeholder="Selecione o tamanho" />
            </Form.Group>
            <br />
            <div className="formButtons">
              <Button onClick={this.goBack} style={estiloBtnCancelar}>
                Cancelar
              </Button>
              <Button onClick={this.salvarTipoVeiculo} style={estiloBtnAdicionar}>
                Adicionar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }

  async salvarTipoVeiculo(): Promise<void> {
    try {
      const modelo = this.modelo.current?.value
      const marca = this.marca.current?.value
      const tamanho = this.state.tamanho
      if (modelo && marca && tamanho) {
        this.tipoVeiculo.modelo = modelo
        this.tipoVeiculo.marca = marca
        this.tipoVeiculo.tamanho = tamanho
        this.tipoVeiculo.canInsertOrUpdate()
        this.tipoVeiculo = await TipoVeiculoController.criar(this.tipoVeiculo)
        this.props.history.goBack()
      } else {
        throw Error("Valores inválidos")
      }
    } catch (error) {
      alert(error)
    }
  }

  goBack(): void {
    this.props.history.push('/tiposVeiculos')
  }

  renderNotLogged(): JSX.Element {
    return (
      <div className="Home">
        <h1>Você deve estar logado</h1>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof TiposVeiculosActions =>
  bindActionCreators({ ...LoginActions, ...TiposVeiculosActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TipoVeiculoAdicionar)
