import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// CSS Global aplicado a todas as telas
import './estiloGlobal.css'
// Importação dos componentes para rota. Toda tela deve estar mapeada aqui
import GenericNotFound from './genericas/GenericNotFound'
import { LandingPage } from './landing_page/LandingPage'
import { AppAdministrativo } from './AppAdministrativo'
import { FaleConosco } from './landing_page/FaleConosco'
import { SejaParceiro } from './landing_page/SejaParceiro'
import { Servicos } from './landing_page/Servicos'
import { Club } from './landing_page/Club'
// TODO: reorganizar subrotas
class App extends Component {
  render(): JSX.Element {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/faleconosco" component={FaleConosco} />
          <Route path="/administrativo" component={AppAdministrativo} />
          <Route path="/seja-parceiro" component={SejaParceiro} />
          <Route path="/servicos" component={Servicos} />
          <Route path="/club" component={Club} />
          <Route component={GenericNotFound} />
        </Switch>
      </Router>
    )
  }
}

export default App
