// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// TipoServico
import * as ServicoPrestadoActions from '../../store/models/servicosprestados/actions'
import ServicoPrestado from '../../models/ServicoPrestado'
// CSS e imagens
import './ServicoDetalhe.css'
import CarrinhoCompra from '../../models/CarrinhoCompra'
import FaturaController from '../../controller/FaturaController'
import { ServicoClub, Situacoes, StatusPagamento, TipoCupom, TipoPagamento } from '../../models/model_interfaces'
import ServicoPrestadoRepository from '../../repositories/ServicoPrestadoRepository'
import ClienteController from '../../controller/ClienteController'
import Cliente from '../../models/Cliente'
import ParceiroController from '../../controller/ParceiroController'
import ServicoClubController from '../../controller/ServicoClubController'

interface ServiPrestDetalheState {
  servicoPrestado: ServicoPrestado
  estornando: boolean
  pagamentoManual: boolean
  servicosClubs: ServicoClub[]
}

// 0 solicitado
// 1 aceito
// 2 lincadoACaminho
// 3 iniciado
// 4 avariaRegistrada
// 5 finalizado
// 6 avaliadoCliente
// 7 avaliadoParceiro
// 8 sugestões
// 9 canceladoCliente
// 10 canceladoParceiro
// 11 canceladoSistema
// 12 estornado
const estados: Array<string> = [
  'Solicitado',
  'Aceito',
  'A caminho',
  'Iniciado',
  'Iniciado',
  'Finalizado',
  'Finalizado*',
  'Finalizado*',
  'Finalizado*',
  'Cancelado Cliente',
  'Cancelado Parceiro',
  'Cancelado Sistema',
  'Estornado',
]

// Variável de controle de exibição
let controleEstornado = 0
// Une todos em props antes de passar ao ao componente
type Props = ApplicationState &
  LoginState &
  typeof LoginActions &
  typeof ServicoPrestadoActions &
  RouteComponentProps<any>
class DetalheServico extends Component<Props, ServiPrestDetalheState> {
  constructor(props: Props) {
    super(props)
    // Vincula palavra this a função
    this.estornarPagamento = this.estornarPagamento.bind(this)
    this.pegaServicoPrestadoById = this.pegaServicoPrestadoById.bind(this)
    this.completarServicoPrestado = this.completarServicoPrestado.bind(this)
    this.renderBotaoEstorno = this.renderBotaoEstorno.bind(this)
    this.getServicosClub = this.getServicosClub.bind(this)
    this.handlePagamentoManual = this.handlePagamentoManual.bind(this)
    this.state = {
      servicoPrestado: this.props.models.servicosPrestados.selecionado,
      estornando: false,
      pagamentoManual: false,
      servicosClubs: [],
    }
    this.getServicosClub()
    controleEstornado = 0
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    if (
      !this.props.models.servicosPrestados ||
      !this.props.models.servicosPrestados ||
      this.props.models.servicosPrestados.selecionado.isEmpty
    ) {
      if (this.props.match.params.id) {
        this.pegaServicoPrestadoById(this.props.match.params.id)
      } else {
        this.props.history.replace('/servicosprestados')
      }
    }
  }

  async getServicosClub(): Promise<void> {
    const servicosClubs = await ServicoClubController.listarServicosClub()
    this.setState({
      servicosClubs,
    })
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  async pegaServicoPrestadoById(documentId: string): Promise<void> {
    const servicoPrestado = await ServicoPrestadoRepository.getServicoPrestadoById(documentId, [
      'situacao',
      'parceiro',
      'cupom',
    ])
    this.setState({
      servicoPrestado: servicoPrestado,
    })
    this.completarServicoPrestado()
  }

  statusPagamentoToText(statusPagamento: StatusPagamento): string {
    switch (statusPagamento) {
      case StatusPagamento.NAO_PAGO:
        return 'Não pago'
      case StatusPagamento.PAGO:
        return 'Pago'
      case StatusPagamento.TAXA_NAO_PAGA:
        return 'Taxa não paga'
      case StatusPagamento.TAXA_PAGA:
        return 'Taxa paga'
      case StatusPagamento.REEMBOLSO:
        return 'Reembolsado'
    }
  }

  favoritadoToText(dados?: firebase.firestore.DocumentData): string {
    if (dados && dados.favoritado) {
      return 'Foi favoritado'
    } else {
      return 'Não foi favoritado'
    }
  }

  renderLogged(): JSX.Element {
    if (this.state.servicoPrestado.isEmpty || !this.state.servicosClubs.length) {
      return <div>Carregando...</div>
    }
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Detalhes do serviço prestado</h1>
          <br />
          <span className="tituloDetalhe">Parceiro: </span>
          {this.state.servicoPrestado.parceiro?.nome}
          <br />
          <span className="tituloDetalhe">Cliente: </span>
          {this.state.servicoPrestado.cliente?.nome}
          <br />
          <span className="tituloDetalhe">Telefone do Cliente: </span>
          {this.state.servicoPrestado.cliente?.telefone ? this.state.servicoPrestado.cliente?.telefone : 'Carregando'}
          <br />
          <span className="tituloDetalhe">Endereco do Serviço: </span>
          {this.state.servicoPrestado.endereco ? this.state.servicoPrestado.endereco.formatarEndereco() : 'Carregando'}
          <br />
          <span className="tituloDetalhe">Favoritado: </span>
          {this.favoritadoToText(this.state.servicoPrestado.dados)}
          <br />
          <span className="tituloDetalhe">Status: </span>
          {this.statusPagamentoToText(this.state.servicoPrestado.statusPagamento)}
          <br />

          <span className="tituloDetalhe">Metodo pagamento: </span>
          {this.state.servicoPrestado.tipoPagamento !== undefined
            ? this.state.servicoPrestado.tipoPagamento === TipoPagamento.CARTAO
              ? 'Cartão'
              : 'Dinheiro'
            : 'Carregando...'}
          <br />
          <span className="tituloDetalhe">Nota: </span>
          {this.state.servicoPrestado.nota ? this.state.servicoPrestado.nota : 'Sem nota'}
          <br />
          <span className="tituloDetalhe">Comentários: </span>
          <br />
          {this.state.servicoPrestado.comentario ? this.state.servicoPrestado.comentario : 'Sem comentários'}
          <br />
          <div className="carrinhoCompra">
            {this.renderServicosContratados()}
            <br />
            <div className="carrinhoCompraTotal"></div>
            <span className="tituloDetalhe">Total</span>
            <span className="tituloDetalhe preco">
              {this.state.servicoPrestado.dados.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </span>
          </div>
          <br />
          <br />
          <p className="tituloDetalhe">Eventos:</p>
          {this.renderEstadosServico()}
          <br />
          {controleEstornado < 1 ? this.renderBotaoEstorno() : this.renderEstornando()}
        </div>
      </div>
    )
  }

  async handlePagamentoManual(): Promise<void> {
    this.setState({
      pagamentoManual: true,
    })

    if (!('faturaId' in this.state.servicoPrestado.dados)) {
      alert('Esse serviço não possui uma fatura')
    } else {
      try {
        const response = await FaturaController.pagar(this.state.servicoPrestado)
        if (response === 'Autorizado') {
          alert('Sucesso ao pagar!')
        }
      } catch (error) {
        alert(error)
      }
    }

    this.setState({
      pagamentoManual: false,
    })
  }

  renderBotaoEstorno(): JSX.Element {
    const estadoAtual = this.state.servicoPrestado.situacoes[this.state.servicoPrestado.situacoes.length - 1].estado
    if (
      estadoAtual >= Situacoes.FINALIZADO &&
      estadoAtual < Situacoes.CANCELADOCLIENTE &&
      this.state.servicoPrestado.tipoPagamento === TipoPagamento.CARTAO
    ) {
      if (this.state.servicoPrestado.statusPagamento === StatusPagamento.NAO_PAGO) {
        return (
          <button className="botaoEstorno" onClick={this.handlePagamentoManual} disabled={this.state.pagamentoManual}>
            Pagamento manual
          </button>
        )
      } else {
        return (
          <button className="botaoEstorno" onClick={this.estornarPagamento} disabled={this.state.estornando}>
            Estornar pagamento cliente
          </button>
        )
      }
    }
    return <p>Estorno indisponível</p>
  }

  renderEstornando(): JSX.Element {
    return (
      <div className="msgEstorno">
        <p>Estorno solicitado</p>
      </div>
    )
  }

  renderServicosContratados(): JSX.Element {
    const items = []
    for (let i = 0; i < this.state.servicoPrestado.carrinhoCompra?.length; i++) {
      if (i == 0) {
        items.push(
          <div key={'detalhes' + i}>
            <span className="tituloDetalhe">Veículo & Placa: </span>
            {this.state.servicoPrestado.carrinhoCompra[i]?.veiculo.tipoVeiculo.modelo}
            {this.state.servicoPrestado.carrinhoCompra[i]?.veiculo.placa}
            <br />
            {this.renderServicosPorVeiculo(this.state.servicoPrestado.carrinhoCompra[i], false)}
          </div>,
        )
      } else {
        items.push(
          <div key={'detalhes' + i}>
            <span className="tituloDetalhe">Extras </span>
            <br />
            {this.renderServicosPorVeiculo(this.state.servicoPrestado.carrinhoCompra[i], true)}
          </div>,
        )
      }
    }
    return (
      <div>
        <ul className="servicosContratados">{items}</ul>
      </div>
    )
  }

  renderServicosPorVeiculo(carrinho: CarrinhoCompra, extra: boolean): JSX.Element {
    const items = []
    let subtotal = 0
    for (let i = 0; i < carrinho.tipoServicos.length; i++) {
      subtotal += carrinho.tipoServicos[i].atualInfoTipoServico.valor
      items.push(
        <div key={'tipoServico' + i}>
          <span className="tituloDetalhe">{carrinho.tipoServicos[i].descricao}</span>
          <span className="preco">
            {carrinho.tipoServicos[i].atualInfoTipoServico.valor.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        </div>,
      )
      // TODO: Como o preço do serviço é calculado?
      // subtotal =
    }

    if (
      !extra &&
      this.state.servicoPrestado.dados.isLincarClub &&
      carrinho.tipoServicos.some(value => this.state.servicosClubs.some(v => v.tipoServico.id === value.ref.id))
    ) {
      const tipoServicoClub = carrinho.tipoServicos.find(value =>
        this.state.servicosClubs.some(v => v.tipoServico.id === value.ref.id),
      )
      items.push(
        <div key={'tipoServico' + items.length}>
          <span className="tituloDetalhe">Desconto Lincar Club: {tipoServicoClub?.descricao}</span>
          <span className="preco">
            -
            {tipoServicoClub?.atualInfoTipoServico.valor.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        </div>,
      )
    }
    if (!extra && this.state.servicoPrestado && this.state.servicoPrestado.cupom) {
      const cupom = this.state.servicoPrestado.cupom
      const valorDesconto = cupom.tipoCupom === TipoCupom.VALOR ? cupom.desconto : (subtotal * cupom.desconto) / 100
      subtotal -= valorDesconto
      items.push(
        <div key={'tipoServico' + items.length}>
          <span className="tituloDetalhe">Cupom: {cupom.codigo}</span>
          <span className="preco">
            -
            {valorDesconto.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        </div>,
      )
    } else if (extra && carrinho.dados.desconto) {
      items.push(
        <div key={'tipoServico' + items.length}>
          <span className="tituloDetalhe">Desconto</span>
          <span className="preco">
            -
            {carrinho.dados.desconto.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        </div>,
      )
    }
    return <div>{items}</div>
  }

  renderEstadosServico(): JSX.Element {
    const items = []
    for (let i = 0; i < this.state.servicoPrestado.situacoes.length; i++) {
      items.push(
        <li key={'estados' + i}>
          <p>{estados[this.state.servicoPrestado.situacoes[i].estado]}</p>
          <span>
            <strong>{this.state.servicoPrestado.situacoes[i].dataFormatado.toString()}</strong>
            {this.state.servicoPrestado.situacoes[i].horaFormatado.toString()}
          </span>
        </li>,
      )
    }
    return (
      <div>
        <ul className="eventos">{items}</ul>
      </div>
    )
  }

  renderNotLogged(): JSX.Element {
    return (
      <div>
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  async completarServicoPrestado(): Promise<void> {
    const servicoPrestado = await this.state.servicoPrestado.fillFromJson(undefined, [
      'veiculo',
      'carrinhoCompras',
      'cliente',
      'parceiro',
      'tipoServico',
      'tipoVeiculo',
      'veiculo',
      'enderecoSelecionado',
    ])
    servicoPrestado.setAtualTipoServico()
    this.setState({
      servicoPrestado: servicoPrestado,
    })
    if (!servicoPrestado.cliente?.telefone) {
      ClienteController.pegarInformacoesClientePorPid(servicoPrestado.cliente as Cliente).then(cliente => {
        servicoPrestado.cliente = cliente
        this.setState({
          servicoPrestado: servicoPrestado,
        })
      })
    }
    if (servicoPrestado.dados.favoritado && !servicoPrestado.parceiro) {
      ParceiroController.pegarPorParceiroUid(servicoPrestado.dados.parceiroUid).then(parceiro => {
        servicoPrestado.parceiro = parceiro
        this.setState({
          servicoPrestado,
        })
      })
    }
  }

  componentDidMount(): void {
    if (!this.state.servicoPrestado.isEmpty) {
      this.completarServicoPrestado()
    }
    // else{
    //   this.state.servicoPrestado.fillFromJson(undefined,['endereco'])
    // }
  }

  async estornarPagamento(): Promise<void> {
    // TODO Vini
    // Verificar se pagamento foi feito e se já não foi estornado
    // Chamada IUGU:Estornar pagamento
    // Alterar status "Estornado"
    this.setState({
      estornando: true,
    })
    if (this.state.servicoPrestado.situacaoAtual.estado === Situacoes.ESTORNADO) {
      alert('Serviço já estornado')
    } else if (this.state.servicoPrestado.dados.statusPagamento !== StatusPagamento.PAGO) {
      alert('Serviço já pago')
    } else if (!('faturaId' in this.state.servicoPrestado.dados)) {
      alert('Esse serviço não possui uma fatura')
    } else {
      try {
        const response = await FaturaController.reembolso(this.state.servicoPrestado)
        if (response === 'Sucesso') {
          alert('Estornado!')
        }
      } catch (error) {
        alert(error)
      }
    }
    this.pegaServicoPrestadoById(this.state.servicoPrestado.id)
    this.setState({
      estornando: false,
    })
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof ServicoPrestadoActions =>
  bindActionCreators({ ...LoginActions, ...ServicoPrestadoActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DetalheServico)
