import Parceiro from '../models/Parceiro'
import ParceiroRepository from '../repositories/ParceiroRepository'

class ParceiroController {
  pegarTodos(cidade: string, recursive?: string[]): AsyncGenerator<Parceiro, void, Parceiro> {
    return ParceiroRepository.pegarTodos(cidade, recursive)
  }

  pegarPorId(documentId: string, recursive?: string[]): Promise<Parceiro> {
    return ParceiroRepository.pegarPorId(documentId, recursive)
  }

  pegarPorParceiroUid(parceiroUid: string, recursive?: string[]): Promise<Parceiro> {
    return ParceiroRepository.pegarPorParceiroUid(parceiroUid, recursive)
  }

  alterar(parceiro: Parceiro): Promise<Parceiro> {
    return ParceiroRepository.alterar(parceiro)
  }

  deletar(parceiro: Parceiro): Promise<Parceiro> {
    return ParceiroRepository.deletar(parceiro)
  }
}

export default new ParceiroController()
