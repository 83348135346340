import { action } from 'typesafe-actions'
import {
  ServicosPrestadosState,
  ServicosPrestadosTypes,
  AtualizarTodosServicos,
  AdicionarServicoPrestado,
  ModificarServicoPrestadoSelecionado,
  ModificarListaCarregadaServicoPrestado,
} from './types'
import ServicoPrestado from '../../../models/ServicoPrestado'
export const atualizarServicosPrestados = (data: ServicosPrestadosState): AtualizarTodosServicos =>
  action(ServicosPrestadosTypes.ATUALIZAR_TODOS_SERVICOS, data)

export const adicionarServicoPrestado = (newServicoPrestado: ServicoPrestado): AdicionarServicoPrestado => {
  return action(ServicosPrestadosTypes.ADICIONAR_SERVICO_PRESTADO, newServicoPrestado)
}

export const modificarServicoPrestadoSelecionado = (
  newServicoPrestado: ServicoPrestado,
): ModificarServicoPrestadoSelecionado => {
  return action(ServicosPrestadosTypes.MODIFICAR_SERVICO_PRESTADO_SELECIONADO, newServicoPrestado)
}

export const modificarListaCarregadaServicosPrestados = (
  estaCarregada: boolean,
): ModificarListaCarregadaServicoPrestado => {
  return action(ServicosPrestadosTypes.MODIFICAR_LISTA_CARREGADA, estaCarregada)
}
