import Cupom from '../../../models/Cupom'

export enum CuponsTypes {
  ATUALIZAR_CUPONS = '@cupons/ATUALIZAR_TODOS',
  ADICIONAR_CUPOM = '@cupons/ADICIONAR',
  REMOVER_CUPOM = '@cupons/REMOVER',
  MODIFICAR_CUPOM_SELECIONADO = '@cupons/MODIFICAR_SELECIONADO',
  LIMPAR_CUPONS = '@cupons/LIMPAR',
}

export interface CuponsState {
  readonly data: Cupom[]
  readonly selecionado: Cupom
}

export interface AtualizarCupons {
  type: typeof CuponsTypes.ATUALIZAR_CUPONS
}

export interface AdicionarCupom {
  type: typeof CuponsTypes.ADICIONAR_CUPOM
}

export interface RemoverCupom {
  type: typeof CuponsTypes.REMOVER_CUPOM
}

export interface LimparCupons {
  type: typeof CuponsTypes.LIMPAR_CUPONS
}

export interface ModificarCupomSelecionado {
  type: typeof CuponsTypes.MODIFICAR_CUPOM_SELECIONADO
}
