import { ServicoClub } from '../models/model_interfaces'
import FirebaseManager from '../utils/FirebaseManager'

class ServicoClubRepository {
  async list(): Promise<ServicoClub[]> {
    const list: ServicoClub[] = []
    const servicosClub = await FirebaseManager.database.collection('servicosclub').get()
    if (!servicosClub.empty) {
      for (const servico of servicosClub.docs) {
        const ts = servico.data() as ServicoClub
        list.push(ts)
      }
    }
    return list
  }
}

export default new ServicoClubRepository()
