import { action } from 'typesafe-actions'
import { LoginTypes, LoginRequest, LoginSuccess, LoginFailure, LoginState, Logout } from './types'

export const loginRequest = (): LoginRequest => action(LoginTypes.LOGIN_REQUEST)

export const loginSuccess = (data: LoginState): LoginSuccess => action(LoginTypes.LOGIN_SUCCESS, data)

export const loginFailure = (): LoginFailure => action(LoginTypes.LOGIN_FAILURE)

export const logoutSuccess = (): Logout => action(LoginTypes.LOGOUT)
