import { action } from 'typesafe-actions'
import {
  ClientesState,
  ClientesTypes,
  AtualizarClientes,
  AdicionarCliente,
  LimparClientes,
  ModificarClienteSelecionado,
  ModificarListaCarregadaClientes,
  RemoverCliente,
} from './types'
import Cliente from '../../../models/Cliente'
export const atualizarClientes = (data: ClientesState): AtualizarClientes =>
  action(ClientesTypes.ATUALIZAR_CLIENTES, data)

export const adicionarCliente = (newCliente: Cliente): AdicionarCliente => {
  return action(ClientesTypes.ADICIONAR_CLIENTE, newCliente)
}

export const removerCliente = (newCliente: Cliente): RemoverCliente => {
  return action(ClientesTypes.REMOVER_CLIENTE, newCliente)
}

export const limparClientes = (): LimparClientes => {
  return action(ClientesTypes.LIMPAR_CLIENTES)
}

export const modificarClienteSelecionado = (newCliente: Cliente): ModificarClienteSelecionado => {
  return action(ClientesTypes.MODIFICAR_CLIENTE_SELECIONADO, newCliente)
}

export const modificarListaCarregadaClientes = (estaCarregada: boolean): ModificarListaCarregadaClientes => {
  return action(ClientesTypes.MODIFICAR_LISTA_CARREGADA, estaCarregada)
}
