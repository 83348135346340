import CidadeAtua from '../models/CidadeAtua'
import CidadeAtuaRepository from '../repositories/CidadeAtuaRepository'

class CidadeAtuaController {
  pegarTodos(recursive?: string[]): AsyncGenerator<CidadeAtua, void, CidadeAtua> {
    return CidadeAtuaRepository.pegarTodos(recursive)
  }

  criar(cidadeAtua: CidadeAtua): Promise<CidadeAtua> {
    return CidadeAtuaRepository.criar(cidadeAtua)
  }

  pegarPorId(documentId: string): Promise<CidadeAtua> {
    return CidadeAtuaRepository.pegarPorId(documentId)
  }

  pegar(): Promise<CidadeAtua[]> {
    return CidadeAtuaRepository.pegar()
  }

  alterar(cidadeAtua: CidadeAtua): Promise<CidadeAtua> {
    return CidadeAtuaRepository.alterar(cidadeAtua)
  }

  deletar(cidadeAtua: CidadeAtua): Promise<CidadeAtua> {
    return CidadeAtuaRepository.deletar(cidadeAtua)
  }
}

export default new CidadeAtuaController()
