// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import './style.css'

// Une todos em props antes de passar ao ao componente
type Props = RouteComponentProps

interface FaleConoscoState {
  assunto: string
  descricao: string
  email: string
  nome: string
}

export class FaleConosco extends Component<Props, FaleConoscoState> {
  private assunto: React.RefObject<HTMLInputElement>
  private descricao: React.RefObject<HTMLTextAreaElement>
  private email: React.RefObject<HTMLInputElement>
  private nome: React.RefObject<HTMLInputElement>

  constructor(props: Props) {
    super(props)
    this.enviar = this.enviar.bind(this)
    this.limpar = this.limpar.bind(this)
    this.assunto = React.createRef<HTMLInputElement>()
    this.descricao = React.createRef<HTMLTextAreaElement>()
    this.email = React.createRef<HTMLInputElement>()
    this.nome = React.createRef<HTMLInputElement>()
    this.state = {
      assunto: '',
      descricao: '',
      email: '',
      nome: '',
    }

    this.handleVoltar = this.handleVoltar.bind(this)
  }

  handleVoltar(): void {
    this.props.history.push('/')
  }

  render(): JSX.Element {
    return (
      <div>
        <header className="barra">
          <div onClick={this.handleVoltar} style={{ cursor: 'pointer' }}>
            <img
              className="img-faleconosco"
              src={process.env.PUBLIC_URL + '/assets/img/logo_icon.png'}
              alt="Símbolo da empresa Lincar"
            />
            <img className="img-faleconosco" src={process.env.PUBLIC_URL + '/assets/img/logo_nome.png'} alt="Lincar" />
          </div>
        </header>
        <br />
        <div className="fale-conosco">
          <h1>Fale conosco</h1>
          {this.renderFormulario()}
        </div>
      </div>
    )
  }

  renderFormulario(): JSX.Element {
    const estiloBtnSalvar = { backgroundColor: '#113F4F', color: '#FFFFFF', borderRadius: '0' }
    const estiloBtnCancelar = { backgroundColor: '#1B9999', color: '#FFFFFF', borderRadius: '0' }
    return (
      <div>
        <Form className="form">
          <p>Nome</p>
          <Form.Group controlId="formaBasicNome">
            <Form.Control defaultValue={this.state.nome} ref={this.nome} type="text" placeholder="Ex: José Alencar" />
          </Form.Group>
          <p>Email</p>
          <Form.Group controlId="formaBasicEmail">
            <Form.Control
              defaultValue={this.state.email}
              ref={this.email}
              type="text"
              placeholder="Ex: alencar@gmail.com"
            />
          </Form.Group>
          <p>Assunto</p>
          <Form.Group controlId="formaBasicAssunto">
            <Form.Control
              defaultValue={this.state.assunto}
              ref={this.assunto}
              type="text"
              placeholder="Ex: Problemas no login"
            />
          </Form.Group>
          <p>Descrição</p>
          <Form.Group controlId="formaBasicDescricao">
            <Form.Control
              ref={this.descricao}
              defaultValue={this.state.descricao}
              as="textarea"
              rows={3}
              placeholder="Ex: não consigo entrar no app do parceiro"
            />
          </Form.Group>

          <br />
          <div className="formButtons">
            <Button onClick={this.limpar} style={estiloBtnCancelar}>
              Limpar
            </Button>
            <Button onClick={this.enviar} style={estiloBtnSalvar}>
              Enviar
            </Button>
          </div>
        </Form>
      </div>
    )
  }

  enviar(): void {
    const email = this.email.current?.value
    const assunto = this.assunto.current?.value
    const descricao = this.descricao.current?.value
    const nome = this.nome.current?.value
    if (email && assunto && descricao && nome) {
      const descricaoFinal = descricao + `<br/><br/><br/>Contato: ${email}<br/><br/>Att, ${nome}.`
      window.location.href = `mailto:lincar@lincar.com.br?subject=${assunto}&body=${descricaoFinal}`
      this.setState({
        assunto,
        descricao,
        email,
        nome,
      })
    }
  }

  limpar(): void {
    const email = this.email.current
    const assunto = this.assunto.current
    const descricao = this.descricao.current
    const nome = this.nome.current
    if (email && assunto && descricao && nome) {
      assunto.value = ''
      email.value = ''
      descricao.value = ''
      nome.value = ''
    }
  }
}
