// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// Produto
import * as ProdutosActions from '../../store/models/produtos/actions'
import ProdutoController from '../../controller/ProdutoController'
import Produto from '../../models/Produto'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
// Form
import { Form, Button } from 'react-bootstrap'

interface ProdutoAdicionarState {
  validade: Date | null
}

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState & LoginState & typeof LoginActions & typeof ProdutosActions & RouteComponentProps

class ProdutoAdicionar extends Component<Props, ProdutoAdicionarState> {
  private produto: Produto = new Produto()
  private nome: React.RefObject<HTMLInputElement>
  private descricao: React.RefObject<HTMLInputElement>
  private quantidade: React.RefObject<HTMLInputElement>
  private preco: React.RefObject<HTMLInputElement>

  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    // Vincula palavra this a função
    this.goBack = this.goBack.bind(this)
    this.salvarProduto = this.salvarProduto.bind(this)
    this.setDate = this.setDate.bind(this)
    this.nome = React.createRef<HTMLInputElement>()
    this.descricao = React.createRef<HTMLInputElement>()
    this.quantidade = React.createRef<HTMLInputElement>()
    this.preco = React.createRef<HTMLInputElement>()
    this.state = {
      validade: new Date(),
    }
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  setDate(newDate: Date | null): void {
    this.setState({
      validade: newDate,
    })
  }

  renderLogged(): JSX.Element {
    // Define estilo dos botões
    const estiloBtnAdicionar = {
      backgroundColor: '#113F4F',
      color: '#FFFFFF',
      borderRadius: '0',
    }
    const estiloBtnCancelar = {
      backgroundColor: '#1B9999',
      color: '#FFFFFF',
      borderRadius: '0',
    }
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Adicionar novo Produto</h1>
          <br />
          <Form className="form">
            <p>Nome</p>
            <Form.Group controlId="formBasicNome">
              <Form.Control ref={this.nome} type="text" placeholder="Nome" />
            </Form.Group>
            <p>Validade</p>
            <Form.Group controlId="formaBasicValidade">
              <DatePicker
                selected={this.state.validade}
                onChange={(date): void => this.setDate(date)}
                dateFormat="dd/MM/yyyy"
              />
            </Form.Group>
            <p>Descrição</p>
            <Form.Group controlId="formaBasicDescricao">
              <Form.Control ref={this.descricao} type="text" placeholder="Descrição" />
            </Form.Group>
            <p>Preço</p>
            <Form.Group controlId="formaBasicDesconto">
              <Form.Control ref={this.preco} type="number" placeholder="Preço (R$)" />
            </Form.Group>
            <p>Quantidade</p>
            <Form.Group controlId="formaBasicQuantidade">
              <Form.Control ref={this.quantidade} type="number" placeholder="Quantidade" />
            </Form.Group>
            <div className="formButtons">
              <Button onClick={this.goBack} style={estiloBtnCancelar}>
                Cancelar
              </Button>
              <Button onClick={this.salvarProduto} style={estiloBtnAdicionar}>
                Adicionar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }

  async salvarProduto(): Promise<void> {
    try {
      const nome = this.nome.current?.value
      const descricao = this.descricao.current?.value
      const preco = this.preco.current?.valueAsNumber
      const validade = this.state.validade
      const quantidade = this.quantidade.current?.valueAsNumber
      if (nome && descricao && validade && preco && quantidade) {
        this.produto.nome = nome
        this.produto.descricao = descricao
        this.produto.validade = moment(validade)
        this.produto.preco = preco
        this.produto.quantidade = quantidade
        this.produto.canInsertOrUpdate()
        this.produto = await ProdutoController.criar(this.produto)
        this.props.history.goBack()
      } else {
        throw Error('Valores inválidos')
      }
    } catch (error) {
      alert(error)
    }
  }

  goBack(): void {
    this.props.history.push('/produtos')
  }

  renderNotLogged(): JSX.Element {
    return (
      <div className="Home">
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  // Recupera lista Tipo Serviço
  async componentDidMount(): Promise<void> {
    let done: boolean | undefined = false
    const asyncGeneratorProduto = ProdutoController.pegarTodos()
    while (!done) {
      const iteratorResult = await asyncGeneratorProduto.next()
      if (iteratorResult.value) {
        this.props.adicionarProduto(iteratorResult.value)
      }
      done = iteratorResult.done
    }
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof ProdutosActions =>
  bindActionCreators({ ...LoginActions, ...ProdutosActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProdutoAdicionar)
