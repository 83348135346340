import BaseDocument from './BaseDocument'
import { VeiculoInterface } from './model_interfaces'
import { firestore } from 'firebase'
import TipoVeiculo from './TipoVeiculo'

export default class Veiculo extends BaseDocument<Veiculo> implements VeiculoInterface {
  cor!: string
  placa!: string
  tipoVeiculo!: TipoVeiculo

  async fillFromJson(json?: any, recursive?: string[] | undefined): Promise<Veiculo> {
    if (!json) {
      json = this.dados
    }
    recursive = recursive || ['tipoVeiculo']

    this.cor = json.cor
    this.placa = json.placa

    const tipoVeiculo: firestore.DocumentReference = json.tipoVeiculo
    if ('tipoVeiculo' in json && recursive.includes('tipoVeiculo') && tipoVeiculo) {
      const snapshot = await tipoVeiculo.get()
      const newTipoVeiculo = await new TipoVeiculo().convert(snapshot, recursive)
      if (newTipoVeiculo instanceof TipoVeiculo) {
        this.tipoVeiculo = newTipoVeiculo
      }
    }

    return this
  }
}
