// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Grid
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from '@devexpress/dx-react-grid'
import { Grid, Table, Toolbar, SearchPanel, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui'
import { ActionColumns, ActionColumn } from '../utils/TableActionButtons'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// TipoServico
import * as TipoServicosActions from '../../store/models/tiposservicos/actions'
import TipoServicoController from '../../controller/TipoServicoController'
// Pega os icones
import Create from '@material-ui/icons/Create'
import Delete from '@material-ui/icons/Delete'
import TipoServico from '../../models/TipoServico'

interface CidadeAtua {
  cidadeAtua: string
}

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState &
  LoginState &
  typeof LoginActions &
  typeof TipoServicosActions &
  RouteComponentProps<CidadeAtua>

// Determinar cabecalho do grid
const cabecalho = [
  { name: 'alterar' },
  { name: 'excluir' },
  { name: 'ativo', title: 'Ativo' },
  { name: 'descricao', title: 'Descrição' },
  // { name: 'valor', title: 'Valor' },
  // { name: 'tempoMedio', title: 'Tempo Médio' },
  { name: 'dica', title: 'Dica' },
]

interface RowTipoServico {
  descricao: string
  // valor: number
  // tempoMedio: number
  tipoServico: TipoServico
  dica: string
}

// Valores padrão no grid
const tableMessages = {
  noData: 'Sem registros',
}
const searchMessages = {
  searchPlaceholder: 'Pesquisar',
}

class TipoServi extends Component<Props> {
  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    // Vincula palavra this a função
    this.goToAdicionar = this.goToAdicionar.bind(this)
    this.handleClickAlterar = this.handleClickAlterar.bind(this)
    this.handleClickExcluir = this.handleClickExcluir.bind(this)
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  renderLogged(): JSX.Element {
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Tipos de Serviços</h1>
          <button onClick={this.goToAdicionar} className="botaoCrud">
            Novo Tipo de Serviço
          </button>
          {this.renderGrid()}
        </div>
      </div>
    )
  }

  goToAdicionar(): void {
    this.props.history.push('tiposservicos/adicionar')
  }

  renderNotLogged(): JSX.Element {
    return (
      <div>
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  // Recupera lista Tipo Serviço
  async componentDidMount(): Promise<void> {
    if (!this.props.models.tiposServicos.listaCarregada) {
      let done: boolean | undefined = false
      this.props.limparTiposServicos()
      const asyncGeneratorTipoServico = TipoServicoController.pegarTodos(this.props.match.params.cidadeAtua)
      while (!done) {
        const iteratorResult = await asyncGeneratorTipoServico.next()
        if (iteratorResult.value) {
          this.props.adicionarTipoServico(iteratorResult.value)
        }
        done = iteratorResult.done
      }
      this.props.modificarListaCarregadaTiposServicos(true)
    }
  }

  private handleClickAlterar(row: RowTipoServico): void {
    this.props.modificarTipoServicoSelecionado(row.tipoServico)
    this.props.history.push('tiposservicos/' + row.tipoServico.id + '/alterar')
  }

  private handleClickExcluir(row: RowTipoServico): void {
    TipoServicoController.deletar(row.tipoServico)
    this.props.removerTipoServico(row.tipoServico)
  }

  renderGrid(): JSX.Element {
    // Busca serviços e estrutura registros
    const listTiposServicos = this.props.models.tiposServicos ? this.props.models.tiposServicos.data : []
    let tuplas: readonly RowTipoServico[] = []
    if (listTiposServicos) {
      tuplas = listTiposServicos.map(tipoServico => {
        return {
          ativo: tipoServico.ativo ? 'Ativo' : 'Não ativo',
          descricao: tipoServico.descricao,
          // valor: tipoServico.valor,
          // tempoMedio: tipoServico.tempoMedio,
          dica: tipoServico.dica,
          tipoServico: tipoServico,
        }
      })
    }
    const actionColumns: ActionColumn[] = [
      { columnName: 'alterar', label: 'Abrir modo edição', onClick: this.handleClickAlterar, icon: <Create /> },
      { columnName: 'excluir', label: 'Excluir linha', onClick: this.handleClickExcluir, icon: <Delete /> },
    ]
    // Monta Grid
    return (
      <div className="grid">
        <Grid rows={tuplas} columns={cabecalho}>
          <PagingState defaultCurrentPage={0} defaultPageSize={5} />
          <SearchState defaultValue="" />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table messages={tableMessages} />
          <TableHeaderRow />
          <Toolbar />
          <SearchPanel messages={searchMessages} />
          <ActionColumns actionColumns={actionColumns} />
          <PagingPanel
            pageSizes={[5, 10, 20]}
            messages={{
              showAll: 'Mostrar todos',
              rowsPerPage: 'Linhas por página',
              info: (parameters: { from: number; to: number; count: number }): string =>
                parameters.from + '-' + parameters.to + ' de ' + parameters.count,
            }}
          />
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof TipoServicosActions =>
  bindActionCreators({ ...LoginActions, ...TipoServicosActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TipoServi)
