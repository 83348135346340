import Iugu from '../utils/iugu'
import ParceiroRepository from '../repositories/ParceiroRepository'
import { Moment } from 'moment-timezone'
import FirebaseManager from '../utils/FirebaseManager'
import Parceiro from '../models/Parceiro'
import SessionRepository from '../repositories/SessionRepository'
import FaturaRepository from '../repositories/FaturaRepository'
import ServicoPrestado from '../models/ServicoPrestado'

class FaturaController {
  async *pegarTodos(inicio: Moment, fim: Moment): AsyncGenerator<{}, void, {}> {
    const idToken: string = (await FirebaseManager.auth.currentUser?.getIdToken()) || ''
    Iugu.setToken(idToken)
    let done: boolean | undefined = false
    const asyncGenerator = ParceiroRepository.pegarTodosParceiroUid()
    while (!done) {
      const iteratorResult = await asyncGenerator.next()
      if (iteratorResult.value) {
        const queryParams: Map<string, string> = new Map()
        queryParams.set('paid_at_from', inicio.format())
        queryParams.set('paid_at_to', fim.format())
        const urlParams: Map<string, string> = new Map()
        urlParams.set('id', iteratorResult.value)
        const result = await Iugu.invoices.retrieve({}, urlParams, queryParams)
        yield result
      }
      done = iteratorResult.done
    }
  }

  async *pegarPorParceiroList(
    parceiros: Parceiro[],
    inicio: Moment,
    fim: Moment,
  ): AsyncGenerator<Parceiro, void, Parceiro> {
    const idToken: string = (await FirebaseManager.auth.currentUser?.getIdToken()) || ''
    Iugu.setToken(idToken)
    for (const parceiro of parceiros) {
      const parceiroUid = await SessionRepository.getParceiroUid(parceiro.uid)
      parceiro.faturas = await FaturaRepository.getByDate(parceiroUid, inicio, fim)
      yield parceiro
    }
  }

  async pegarPorParceiro(parceiro: Parceiro, inicio: Moment, fim: Moment): Promise<Parceiro> {
    const idToken: string = (await FirebaseManager.auth.currentUser?.getIdToken()) || ''
    Iugu.setToken(idToken)
    const parceiroUid = await SessionRepository.getParceiroUid(parceiro.uid)
    parceiro.faturas = await FaturaRepository.getByDate(parceiroUid, inicio, fim)
    return parceiro
  }

  async reembolso(servicoPrestado: ServicoPrestado): Promise<string> {
    const idToken: string = (await FirebaseManager.auth.currentUser?.getIdToken()) || ''
    Iugu.setToken(idToken)
    const params: Map<string, string> = new Map()
    params.set('documentId', servicoPrestado.id)
    const res = await Iugu.invoices.refund({}, params)
    if (!('status' in res)) {
      throw new Error(res.errors)
    }
    return res.status
  }

  async pagar(servicoPrestado: ServicoPrestado): Promise<string> {
    const idToken: string = (await FirebaseManager.auth.currentUser?.getIdToken()) || ''
    Iugu.setToken(idToken)
    const res = await Iugu.paymentos.create({
      pidCliente: servicoPrestado.dados.pid,
      documentId: servicoPrestado.id,
      // eslint-disable-next-line @typescript-eslint/camelcase
      invoice_id: servicoPrestado.dados.faturaId,
    })

    if (!res.success) {
      throw new Error(res.message)
    }
    return res.message
  }
}

export default new FaturaController()
