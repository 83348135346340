import Cliente from '../../../models/Cliente'

export enum ClientesTypes {
  ATUALIZAR_CLIENTES = '@clientes/ATUALIZAR_TODOS',
  ADICIONAR_CLIENTE = '@clientes/ADICIONAR',
  REMOVER_CLIENTE = '@clientes/REMOVER',
  MODIFICAR_CLIENTE_SELECIONADO = '@clientes/MODIFICAR_SELECIONADO',
  MODIFICAR_LISTA_CARREGADA = '@clientes/MODIFICAR_LISTA_CARREGADA',
  LIMPAR_CLIENTES = '@clientes/LIMPAR',
}

export interface ClientesState {
  readonly data: Cliente[]
  readonly selecionado: Cliente
  readonly listaCarregada: boolean
}

export interface AtualizarClientes {
  type: typeof ClientesTypes.ATUALIZAR_CLIENTES
}

export interface AdicionarCliente {
  type: typeof ClientesTypes.ADICIONAR_CLIENTE
}

export interface RemoverCliente {
  type: typeof ClientesTypes.REMOVER_CLIENTE
}

export interface LimparClientes {
  type: typeof ClientesTypes.LIMPAR_CLIENTES
}

export interface ModificarListaCarregadaClientes {
  type: typeof ClientesTypes.MODIFICAR_LISTA_CARREGADA
}

export interface ModificarClienteSelecionado {
  type: typeof ClientesTypes.MODIFICAR_CLIENTE_SELECIONADO
}
