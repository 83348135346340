// React + redux
import React, { Component } from 'react'
// CSS e imagens
import titulo from '../imagens/login_titulo.png'
// Login + aplication
import { ApplicationState } from '../../store'

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState
interface StateGenericNotFound {
  radioSelected: number
  todosCarregada: boolean
}

class GenericNotFound extends Component<Props, StateGenericNotFound> {
  
  render(): JSX.Element {
    return (
      <div>
        <header className="barraSuperior">

        </header>
        <div>
          <img className="tituloImagem" src={titulo} alt="Lincar"></img>          
          <p className="titulo">
            <span className="erro">Erro 404</span>
            : Página não encontrada
          </p>
        </div>
      </div>
    )
  }
}

export default GenericNotFound
