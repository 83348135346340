// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// CSS
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
import * as ParceirosActions from '../../store/models/parceiros/actions'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import ParceiroController from '../../controller/ParceiroController'
import Parceiro from '../../models/Parceiro'
import './Financeiro.css'
import ExtratoPagamento from '../../models/ExtratoPagamento'
import ExtratoPagamentoController from '../../controller/ExtratoPagamentoController'
import ReactDatePicker from 'react-datepicker'
import ServicoPrestadoController from '../../controller/ServicoPrestadoController'
import ServicoPrestado from '../../models/ServicoPrestado'
import { ItemExtra, TipoPagamento } from '../../models/model_interfaces'
import Delete from '@material-ui/icons/Delete'
import { IconButton } from '@material-ui/core'
import * as ExtratosPagamentoActions from '../../store/models/extratospagamento/actions'
import moment from 'moment-timezone'
import { ServicosPrestadosTypes } from '../../store/models/servicosprestados/types'
import TipoServico from '../../models/TipoServico'

interface ExtratoPagamentoState {
  parceiro: Parceiro
  extratoPagamento: ExtratoPagamento
  dataInicio: Date
  dataFim: Date
  dataPagamento?: Date | null
  servicosPrestados: ServicoPrestado[]
  recebimentosExtras: ItemExtra[]
  descontosExtras: ItemExtra[]
  carregando: boolean
  taxaCartao: number
  taxaFixaCartao: number
  posParceiro: number
  valorBruto: number
  valorLiquido: number
  pago: boolean
}

function generateId(): string {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  )
}

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState &
  LoginState &
  typeof LoginActions &
  typeof ParceirosActions &
  RouteComponentProps<any> &
  typeof ExtratosPagamentoActions

class Adicionar extends Component<Props, ExtratoPagamentoState> {
  private refDescricaoRecebimento: React.RefObject<HTMLInputElement>
  private refValorRecebimento: React.RefObject<HTMLInputElement>

  private refDescricaoDescontos: React.RefObject<HTMLInputElement>
  private refValorDescontos: React.RefObject<HTMLInputElement>

  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }

    this.goToPDF = this.goToPDF.bind(this)
    this.renderDropdown = this.renderDropdown.bind(this)
    this.handleChangeDropDown = this.handleChangeDropDown.bind(this)
    this.handleCarregarServicosParceiro = this.handleCarregarServicosParceiro.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.renderResumo = this.renderResumo.bind(this)
    this.createExtratoPagamentoFromData = this.createExtratoPagamentoFromData.bind(this)
    this.handleSave = this.handleSave.bind(this)

    this.refDescricaoRecebimento = React.createRef<HTMLInputElement>()
    this.refValorRecebimento = React.createRef<HTMLInputElement>()

    this.refDescricaoDescontos = React.createRef<HTMLInputElement>()
    this.refValorDescontos = React.createRef<HTMLInputElement>()

    this.state = {
      posParceiro: -1,
      parceiro: new Parceiro(),
      extratoPagamento: new ExtratoPagamento(),
      dataInicio: new Date(),
      dataFim: new Date(),
      carregando: false,
      taxaCartao: 2.5,
      servicosPrestados: [],
      descontosExtras: [],
      recebimentosExtras: [],
      pago: false,
      valorBruto: 0,
      valorLiquido: 0,
      taxaFixaCartao: 0.7,
      dataPagamento: null,
    }
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  goToPDF(): void {
    this.props.history.push('pdf')
  }

  async handleSave(): Promise<void> {
    if (!this.state.servicosPrestados) {
      alert('É necessário que haja serviços prestados')
    }
    const extratoPagamento = this.createExtratoPagamentoFromData()
    const newExtratoPagamento = await ExtratoPagamentoController.criar(extratoPagamento)
    this.setState({
      extratoPagamento: newExtratoPagamento,
    })

    this.props.adicionarExtratoPagamento(newExtratoPagamento)
    this.props.history.goBack()
  }

  handleChangeDropDown(event: React.ChangeEvent<HTMLSelectElement>): void {
    this.setState({
      posParceiro: Number(event.target.value),
      parceiro: this.props.models.parceiros.data[Number(event.target.value)],
    })
  }

  async handleCarregarServicosParceiro(): Promise<void> {
    if (!this.state.carregando) {
      this.setState({
        servicosPrestados: [],
        carregando: true,
      })
      let done: boolean | undefined = false
      const asyncGeneratorServicoPrestado = ServicoPrestadoController.filtrarPorParceiroEData(
        this.state.parceiro,
        this.state.dataInicio,
        this.state.dataFim,
        ['carrinhoCompras', 'cliente', 'tipoServico'],
      )
      while (!done) {
        const iteratorResult = await asyncGeneratorServicoPrestado.next()
        if (iteratorResult.value) {
          const newServicos = this.state.servicosPrestados
          newServicos.push(iteratorResult.value)
          this.setState({
            servicosPrestados: newServicos,
          })
        }
        done = iteratorResult.done
      }
      this.setState({
        carregando: false,
      })
    }
  }

  renderServicosPrestados(): JSX.Element {
    let valorEmServico = 0
    let valorSemDesconto = 0
    const taxaCartao = this.state.taxaCartao
    const taxaFixaCartao = this.state.taxaFixaCartao
    const options: JSX.Element[] = this.state.servicosPrestados.map<JSX.Element>((servico, idx) => {
      const gorjeta = servico.dados.gorjeta ? servico.dados.gorjeta : 0.0
      const valor = servico.dados.isLincarClub ? servico.dados.valorAbsoluto * 0.85 : servico.dados.valor
      let valorFinal = valor + gorjeta
      if (servico.tipoPagamento === TipoPagamento.CARTAO) {
        valorFinal -= (servico.dados.valor * taxaCartao) / 100 + taxaFixaCartao
      }
      valorEmServico += valorFinal
      valorSemDesconto += servico.dados.valorAbsoluto + gorjeta
      const descricao = servico.carrinhoCompra.map<JSX.Element>((value, idx) => {
        const descricao: string[] = []
        for (const ts of value.tipoServicos) {
          descricao.push(ts.descricao)
        }
        return <p key={idx}>{idx === 1 ? 'Extras:\n' + descricao.join('\n') : descricao.join('\n')}</p>
      })
      if (servico.dados.isLincarClub) {
        descricao.push(<p key={descricao.length}>Lincar CLUB</p>)
      }
      return (
        <Row className="item-row" key={idx + 1}>
          <Col>{descricao}</Col>
          <Col>{servico.dados.valorAbsoluto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Col>
          <Col>{valorFinal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Col>
          <Col>
            {gorjeta.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Col>
          <Col>{servico.cliente.nome}</Col>

          <Col>{servico.tipoPagamento === TipoPagamento.CARTAO ? 'Cartão' : 'Dinheiro'}</Col>
        </Row>
      )
    })
    options.unshift(
      <Row key={0} className="header-row">
        <Col>Serviço</Col>
        <Col>Valor cheio</Col>
        <Col>Valor líquido</Col>
        <Col>Gorjeta</Col>
        <Col>Cliente</Col>

        <Col>Pagamento</Col>
      </Row>,
    )
    return (
      <Container className="table-container">
        {options}
        <Row key={options.length} className="item-row">
          <Col>
            Total com cupons e descontos:{' '}
            {valorEmServico.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </Col>
          <Col>
            Total sem cupons e descontos:{' '}
            {valorSemDesconto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </Col>
        </Row>
      </Container>
    )
  }

  renderResumo(): JSX.Element {
    let valorEmServico = 0
    let valorSemDesconto = 0
    const taxaCartao = this.state.taxaCartao
    const taxaFixaCartao = this.state.taxaFixaCartao
    const options: JSX.Element[] = this.state.servicosPrestados.map<JSX.Element>((servico, idx) => {
      const gorjeta = servico.dados.gorjeta ? servico.dados.gorjeta : 0.0
      const valor = servico.dados.isLincarClub ? servico.dados.valorAbsoluto * 0.85 : servico.dados.valor
      const valorDisplay =
        servico.tipoPagamento === TipoPagamento.CARTAO
          ? valor * (1 - taxaCartao / 100) - taxaFixaCartao + gorjeta
          : servico.dados.valor
      valorEmServico += valorDisplay
      if (servico.tipoPagamento === TipoPagamento.CARTAO) {
        valorSemDesconto += valor * (1 - taxaCartao / 100) - taxaFixaCartao + gorjeta
      }
      const descricao = servico.carrinhoCompra.map<JSX.Element>((value, idx) => {
        const descricao: string[] = []
        for (const ts of value.tipoServicos) {
          descricao.push(ts.descricao)
        }
        return <p key={idx}>{idx === 1 ? 'Extras:\n' + descricao.join(', ') : descricao.join(', ')}</p>
      })
      if (servico.dados.isLincarClub) {
        descricao.push(<p key={descricao.length}>(Lincar CLUB)</p>)
      }
      return (
        <Row className="item-row" key={idx + 1}>
          <Col>{descricao}</Col>
          <Col>{valorDisplay.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Col>

          <Col>{servico.tipoPagamento === TipoPagamento.CARTAO ? 'Cartão' : 'Dinheiro'}</Col>
        </Row>
      )
    })
    options.unshift(
      <Row key={0} className="header-row">
        <Col>Serviço</Col>
        <Col>Valor</Col>
        <Col>Observação</Col>
      </Row>,
    )
    const optionsRecebimentos: JSX.Element[] = this.state.recebimentosExtras.map<JSX.Element>((recebimento, idx) => {
      valorEmServico += recebimento.valor
      valorSemDesconto += recebimento.valor
      return (
        <Row className="item-row" key={options.length + idx}>
          <Col>{recebimento.descricao}</Col>
          <Col>{recebimento.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Col>
          <Col>Recebimento</Col>
        </Row>
      )
    })

    const optionsDescontos: JSX.Element[] = this.state.descontosExtras.map<JSX.Element>((desconto, idx) => {
      valorEmServico -= desconto.valor
      valorSemDesconto -= desconto.valor
      return (
        <Row className="item-row" key={options.length + optionsRecebimentos.length + idx}>
          <Col>{desconto.descricao}</Col>
          <Col>- {desconto.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Col>
          <Col>Desconto</Col>
        </Row>
      )
    })

    if (valorEmServico !== this.state.valorBruto) {
      this.setState({
        valorBruto: valorEmServico,
        valorLiquido: valorSemDesconto,
      })
    }
    return (
      <Container className="table-container">
        {options}
        {optionsRecebimentos}
        {optionsDescontos}
        <Row key={optionsRecebimentos.length + optionsDescontos.length + options.length} className="item-row">
          <Col>Valor bruto: {valorEmServico.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Col>
          <Col>
            Valor a ser pago: {valorSemDesconto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </Col>
        </Row>
      </Container>
    )
  }

  renderDropdown(): JSX.Element {
    const options: JSX.Element[] = this.props.models.parceiros.data.map<JSX.Element>((parceiro, idx) => {
      return (
        <option key={idx} value={idx}>
          {parceiro.nome}
        </option>
      )
    })
    return (
      <Form.Group className="dropdown">
        <Form.Control as="select" onChange={this.handleChangeDropDown} value={this.state.posParceiro}>
          {options}
        </Form.Control>
      </Form.Group>
    )
  }

  handleDeleteItem(item: ItemExtra, tipo: number): void {
    const recebimentosExtras = this.state.recebimentosExtras
    const descontosExtras = this.state.descontosExtras

    if (tipo === 0) {
      const newArray = recebimentosExtras.filter(i => i.id !== item.id)
      this.setState({
        recebimentosExtras: newArray,
      })
    } else {
      const newArray = descontosExtras.filter(i => i.id !== item.id)
      this.setState({
        descontosExtras: newArray,
      })
    }
  }

  renderAddItemExtra(items: ItemExtra[], tipo: number): JSX.Element[] {
    const options: JSX.Element[] = items.map<JSX.Element>((item, idx) => {
      return (
        <div className="item-extra" key={(tipo === 0 ? 'recebimento' : 'desconto') + idx}>
          <p>
            {item.descricao}. {item.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </p>{' '}
          <IconButton
            onClick={(): void => this.handleDeleteItem(item, tipo)}
            size="medium"
            aria-label={'Remover item'}
            style={{ verticalAlign: 'middle' }}
          >
            <Delete></Delete>
          </IconButton>
        </div>
      )
    })

    return options
  }

  handleAdicionarItemExtra(tipo: number): void {
    const descricao = tipo === 0 ? this.refDescricaoRecebimento.current : this.refDescricaoDescontos.current
    const valor = tipo === 0 ? this.refValorRecebimento.current : this.refValorDescontos.current

    if (descricao && valor) {
      if (tipo === 0) {
        const recebimentosExtras = this.state.recebimentosExtras
        recebimentosExtras.push({ descricao: descricao.value, valor: valor.valueAsNumber, id: generateId() })

        this.setState({
          recebimentosExtras,
        })
      } else {
        const descontosExtras = this.state.descontosExtras
        descontosExtras.push({ descricao: descricao.value, valor: valor.valueAsNumber, id: generateId() })

        this.setState({
          descontosExtras,
        })
      }
      descricao.value = ''
      valor.value = ''
    }
  }

  handleClickFiltro(value: boolean): void {
    if (this.state.pago !== value) {
      this.setState({
        pago: value,
      })
    }
  }

  renderLogged(): JSX.Element {
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Extrato pagamento</h1>
          <br />
          <Form.Group controlId="formaBasicTaxa" className="texto-input" hidden={true}>
            <p>Taxa dinheiro (%):</p>
            <Form.Control defaultValue="0" type="number" placeholder={'Taxa dinheiro'} />
          </Form.Group>
          <Form.Group controlId="formaBasicTaxaCartao" className="texto-input">
            <p>Taxa cartão (%):</p>
            <Form.Control
              type="number"
              onChange={(value): void => this.setState({ taxaCartao: Number(value.currentTarget.value) })}
              defaultValue={this.state.taxaCartao}
              placeholder={'Taxa cartão'}
            />
          </Form.Group>
          <Form.Group controlId="formaBasicTaxaFixaCartao" className="texto-input">
            <p>Taxa fixa cartão (R$):</p>
            <Form.Control
              type="number"
              onChange={(value): void => this.setState({ taxaFixaCartao: Number(value.currentTarget.value) })}
              defaultValue={this.state.taxaFixaCartao}
              placeholder={'Taxa fixa cartão'}
            />
          </Form.Group>
          <Container className="recebimentos-descontos">
            <Row>
              <Col>
                <p>Recebimentos extras</p>
              </Col>
              <Col>
                <p>Descontos extras</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formaBasicDescricaoRecebimento">
                  <Form.Control ref={this.refDescricaoRecebimento} type="text" placeholder={'Descrição'} />
                </Form.Group>
                <Form.Group controlId="formaBasicValor">
                  <Form.Control ref={this.refValorRecebimento} type="number" placeholder={'Valor'} />
                </Form.Group>

                <Button onClick={(): void => this.handleAdicionarItemExtra(0)}>Adicionar</Button>

                {this.renderAddItemExtra(this.state.recebimentosExtras, 0)}
              </Col>
              <Col>
                <Form.Group controlId="formaBasicDescricaoDesconto">
                  <Form.Control ref={this.refDescricaoDescontos} type="text" placeholder={'Descrição'} />
                </Form.Group>
                <Form.Group controlId="formaBasicValor">
                  <Form.Control ref={this.refValorDescontos} type="number" placeholder={'Valor'} />
                </Form.Group>

                <Button onClick={(): void => this.handleAdicionarItemExtra(1)}>Adicionar</Button>

                {this.renderAddItemExtra(this.state.descontosExtras, 1)}
              </Col>
            </Row>
          </Container>
          <div className="form-inline">
            {this.renderDropdown()}
            <Form.Group controlId="formaBasicInicio">
              <ReactDatePicker
                className="datepicker"
                placeholderText="Dt Início"
                selected={this.state.dataInicio}
                onChange={(date): void => this.setState({ dataInicio: date || new Date() })}
                dateFormat="dd/MM/yyyy"
              />
            </Form.Group>
            <Form.Group controlId="formaBasicFim">
              <ReactDatePicker
                className="datepicker"
                placeholderText="Dt Fim"
                selected={this.state.dataFim}
                onChange={(date): void => this.setState({ dataFim: date || new Date() })}
                dateFormat="dd/MM/yyyy"
              />
            </Form.Group>
            <Button onClick={this.handleCarregarServicosParceiro}>Carregar</Button>
          </div>
          <p>Resumo serviços prestados:</p>
          {this.renderServicosPrestados()}
          <p>Resumo total:</p>
          {this.renderResumo()}

          <div className="filtroPeriodo">
            <p>Esse orçamento foi ou vai ser pago agora?</p>
            <input
              onChange={(): void => this.handleClickFiltro(true)}
              checked={this.state.pago}
              type="radio"
              id="rdo7"
              name="periodo"
              value="7dias"
            />
            <span>Sim</span>
            <input
              onChange={(): void => this.handleClickFiltro(false)}
              checked={!this.state.pago}
              type="radio"
              id="rdo30"
              name="periodo"
              value="30dias"
            />
            <span>Não</span>
          </div>

          <Form.Group controlId="formaBasicPago" hidden={!this.state.pago}>
            <ReactDatePicker
              className="datepicker"
              placeholderText="Dt Pagamento"
              selected={this.state.dataPagamento}
              onChange={(date): void => this.setState({ dataPagamento: date || new Date() })}
              dateFormat="dd/MM/yyyy"
            />
          </Form.Group>

          <div className="botoesAoLado">
            <Button className="btn-dark" onClick={this.handleSave}>
              Salvar
            </Button>
          </div>
        </div>
      </div>
    )
  }

  renderNotLogged(): JSX.Element {
    return (
      <div>
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  createExtratoPagamentoFromData(): ExtratoPagamento {
    const extratoPagamento = new ExtratoPagamento()
    extratoPagamento.pago = this.state.pago
    extratoPagamento.servicosPrestado = this.state.servicosPrestados.map<string>(servico => servico.id)
    extratoPagamento.servicosCarregados = this.state.servicosPrestados
    extratoPagamento.dataInicio = moment(this.state.dataInicio)
    extratoPagamento.dataFim = moment(this.state.dataFim)
    extratoPagamento.valorBruto = this.state.valorBruto
    extratoPagamento.valorLiquido = this.state.valorLiquido
    extratoPagamento.parceiro = this.state.parceiro
    extratoPagamento.pparceiro = this.state.parceiro.pparceiro
    extratoPagamento.descontosExtras = this.state.descontosExtras
    extratoPagamento.recebimentosExtras = this.state.recebimentosExtras
    extratoPagamento.taxaCartao = this.state.taxaCartao
    extratoPagamento.taxaDinheiro = 0
    extratoPagamento.taxaFixaCartao = this.state.taxaFixaCartao
    extratoPagamento.pid = this.state.parceiro.pidExtrato
    const dataPagamento = this.state.dataPagamento
    extratoPagamento.dataPagamento = dataPagamento && this.state.pago ? moment(dataPagamento) : null
    return extratoPagamento
  }

  // Recupera lista Parceiros
  async componentDidMount(): Promise<void> {
    if (!this.props.models.parceiros.listaCarregada) {
      let done: boolean | undefined = false
      this.props.limparParceiros()
      const asyncGeneratorParceiro = ParceiroController.pegarTodos(this.props.match.params.cidadeAtua)
      let first = true
      while (!done) {
        const iteratorResult = await asyncGeneratorParceiro.next()
        if (iteratorResult.value) {
          if (first) {
            this.setState({
              parceiro: iteratorResult.value,
            })
            first = false
          }
          this.props.adicionarParceiro(iteratorResult.value)
        }
        done = iteratorResult.done
      }
      this.props.modificarListaCarregadaParceiros(true)
    }
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (
  dispatch: Dispatch,
): typeof LoginActions & typeof ParceirosActions & typeof ExtratosPagamentoActions =>
  bindActionCreators({ ...LoginActions, ...ParceirosActions, ...ExtratosPagamentoActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Adicionar)
