import { ServicoClub } from '../models/model_interfaces'
import ServicoClubRepository from '../repositories/ServicoClubRepository'

class ServicoClubController {
  listarServicosClub(): Promise<ServicoClub[]> {
    return ServicoClubRepository.list()
  }
}

export default new ServicoClubController()
