import React, { Component } from 'react'
import { Page, View, Document, PDFViewer, Image, Text } from '@react-pdf/renderer'
import ServicoPrestado from '../../models/ServicoPrestado'
import { ItemExtra, TipoPagamento } from '../../models/model_interfaces'
import ExtratoPagamento from '../../models/ExtratoPagamento'
import Parceiro from '../../models/Parceiro'
import * as ExtratosPagamentoActions from '../../store/models/extratospagamento/actions'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { LoginState } from '../../store/administrador/login/types'
import { RouteComponentProps } from 'react-router-dom'
import { ApplicationState } from '../../store'
import moment from 'moment-timezone'
import { Table, TableBody, TableHeader, TableCell, DataTableCell } from '@david.kucsai/react-pdf-table'
import { width } from '@material-ui/system'

type Props = ApplicationState & LoginState & RouteComponentProps<any> & typeof ExtratosPagamentoActions
interface ExtratoPagamentoState {
  parceiro: Parceiro
  extratoPagamento: ExtratoPagamento
  dataInicio: Date
  dataFim: Date
  dataPagamento?: Date | null
  servicosPrestados: ServicoPrestado[]
  recebimentosExtras: ItemExtra[]
  descontosExtras: ItemExtra[]
  carregando: boolean
  taxaCartao: number
  posParceiro: number
  valorEmServico: number
  valorBruto: number
  valorLiquido: number
  pago: boolean
  dadosCarregados: boolean
  recebidoEmDinheiro: number
  recebidoEmCartao: number
  taxaFixaCartao: number
  liquidoDescontosERecebimentos: number
}

interface RowTableServicos {
  data: string
  cliente: string
  tipoLavagem: string
  gorjeta: number
  formaPagamento: string
  valorCheio: number
  valorDesconto: number
  tarifaCartao: number
  valorLiquido: number
}

interface RowTableDescontosRecebimentos {
  descricao: string
  valor: number
  tipo: string
}

class DocumentoPDF extends Component<Props, ExtratoPagamentoState> {
  constructor(props: Props) {
    super(props)

    this.renderTable = this.renderTable.bind(this)
    this.renderTableDescontosERecebimentos = this.renderTableDescontosERecebimentos.bind(this)

    window.history.replaceState(
      null,
      'Lincar',
      '/administrativo/' +
        this.props.match.params.cidadeAtua +
        '/financeiro/' +
        this.props.models.extratosPagamento.selecionado.id +
        '/pdf',
    )

    const extratoPagamento = this.props.models.extratosPagamento.selecionado

    let valorEmServico = 0
    let recebidoEmDinheiro = 0
    let recebidoEmCartao = 0
    for (const servicoPrestado of extratoPagamento.servicosCarregados) {
      const gorjeta = servicoPrestado.dados.gorjeta ? servicoPrestado.dados.gorjeta : 0.0
      const valor = servicoPrestado.dados.isLincarClub
        ? servicoPrestado.dados.valorAbsoluto * 0.85
        : servicoPrestado.dados.valor
      const valorServico =
        servicoPrestado.tipoPagamento === TipoPagamento.CARTAO
          ? valor * (1 - extratoPagamento.taxaCartao / 100) - extratoPagamento.taxaFixaCartao + gorjeta
          : servicoPrestado.dados.valor + gorjeta
      valorEmServico += valorServico

      recebidoEmDinheiro += servicoPrestado.tipoPagamento === TipoPagamento.CARTAO ? 0 : valorServico
      recebidoEmCartao += servicoPrestado.tipoPagamento === TipoPagamento.DINHEIRO ? 0 : valorServico
    }

    let liquidoDescontosERecebimentos = 0

    for (const recebimento of extratoPagamento.recebimentosExtras) {
      liquidoDescontosERecebimentos += recebimento.valor
    }

    for (const descontos of extratoPagamento.descontosExtras) {
      liquidoDescontosERecebimentos -= descontos.valor
    }

    this.state = {
      extratoPagamento: extratoPagamento,
      pago: extratoPagamento.pago,
      taxaFixaCartao: extratoPagamento.taxaFixaCartao,
      servicosPrestados: extratoPagamento.servicosCarregados,
      dataInicio: extratoPagamento.dataInicio.toDate(),
      dataFim: extratoPagamento.dataFim.toDate(),
      valorBruto: extratoPagamento.valorBruto,
      valorLiquido: extratoPagamento.valorLiquido,
      descontosExtras: extratoPagamento.descontosExtras,
      recebimentosExtras: extratoPagamento.recebimentosExtras,
      dataPagamento: extratoPagamento.dataPagamento ? extratoPagamento.dataPagamento?.toDate() : null,
      carregando: false,
      taxaCartao: extratoPagamento.taxaCartao,
      parceiro: extratoPagamento.parceiro,
      posParceiro: 0,
      dadosCarregados: true,
      valorEmServico: valorEmServico,
      recebidoEmCartao,
      recebidoEmDinheiro,
      liquidoDescontosERecebimentos,
    }
  }

  renderTableDescontosERecebimentos(): JSX.Element {
    const rows = this.state.extratoPagamento.recebimentosExtras.map<RowTableDescontosRecebimentos>(value => {
      return {
        descricao: value.descricao,
        valor: value.valor,
        tipo: 'Recebimento',
      }
    })
    rows.push(
      ...this.state.extratoPagamento.descontosExtras.map<RowTableDescontosRecebimentos>(value => {
        return {
          descricao: value.descricao,
          valor: -value.valor,
          tipo: 'Desconto',
        }
      }),
    )
    return (
      <View style={{ width: '92%', marginTop: 10 }}>
        <Table data={rows}>
          <TableHeader fontSize={10}>
            <TableCell>Descrição</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Tipo</TableCell>
          </TableHeader>
          <TableBody fontSize={10}>
            <DataTableCell getContent={(r: RowTableDescontosRecebimentos) => r.descricao} />
            <DataTableCell
              getContent={(r: RowTableDescontosRecebimentos) =>
                r.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
              }
            />
            <DataTableCell getContent={(r: RowTableDescontosRecebimentos) => r.tipo} />
          </TableBody>
        </Table>
      </View>
    )
  }

  renderTable(): JSX.Element {
    const rows = this.state.extratoPagamento.servicosCarregados.map<RowTableServicos>(value => {
      const gorjeta = value.dados.gorjeta ? value.dados.gorjeta : 0.0
      value.setAtualTipoServico()
      const descricao = value.carrinhoCompra.map<string>((v, idx) => {
        const descricao: string[] = []
        for (const ts of v.tipoServicos) {
          descricao.push(ts.descricao)
        }
        return idx === 1 ? 'Extras:\n' + descricao.join(', ') : descricao.join(', ')
      })
      const valor = value.dados.isLincarClub ? value.dados.valorAbsoluto * 0.85 : value.dados.valor
      console.info(value.tipoPagamento)
      console.info(this.state.taxaCartao / 100)
      console.info((valor * this.state.taxaCartao) / 100)
      console.info(gorjeta)
      return {
        cliente: value.cliente.nome,
        gorjeta: gorjeta,
        data: moment(value.situacaoInicial.dataHora).format('DD/MM/yy'),
        formaPagamento: value.tipoPagamento === TipoPagamento.CARTAO ? 'Cartão' : 'Dinheiro',
        tarifaCartao:
          value.tipoPagamento === TipoPagamento.CARTAO
            ? (this.state.taxaCartao / 100) * valor + this.state.taxaFixaCartao
            : 0,
        tipoLavagem: descricao.join('\n') + (value.dados.isLincarClub ? ' (Lincar CLUB)' : ''),
        valorCheio: value.dados.valorAbsoluto,
        valorDesconto:
          (value.dados.isLincarClub ? 0.15 * value.dados.valorAbsoluto : value.dados.valorAbsoluto) - value.dados.valor,
        valorLiquido:
          valor * (value.tipoPagamento === TipoPagamento.CARTAO ? 1 - this.state.taxaCartao / 100 : 1) +
          gorjeta -
          (value.tipoPagamento === TipoPagamento.CARTAO ? this.state.taxaFixaCartao : 0),
      }
    })
    return (
      <View style={{ width: '92%', marginTop: 10 }}>
        <Table data={rows}>
          <TableHeader fontSize={10}>
            <TableCell>Data</TableCell>
            <TableCell>Cliente</TableCell>
            <TableCell>Tipo Lavagem</TableCell>
            <TableCell>Gorjeta</TableCell>
            <TableCell>Forma de pagamento</TableCell>
            <TableCell>Valor cheio</TableCell>
            <TableCell>Valor do desconto</TableCell>
            <TableCell>Tarifa cartão</TableCell>
            <TableCell>Valor líquido</TableCell>
          </TableHeader>
          <TableBody fontSize={10}>
            <DataTableCell getContent={(r: RowTableServicos) => r.data} />
            <DataTableCell getContent={(r: RowTableServicos) => r.cliente} />
            <DataTableCell getContent={(r: RowTableServicos) => r.tipoLavagem} />
            <DataTableCell
              getContent={(r: RowTableServicos) =>
                r.gorjeta.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
              }
            />
            <DataTableCell getContent={(r: RowTableServicos) => r.formaPagamento} />
            <DataTableCell
              getContent={(r: RowTableServicos) =>
                r.valorCheio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
              }
            />
            <DataTableCell
              getContent={(r: RowTableServicos) =>
                r.valorDesconto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
              }
            />
            <DataTableCell
              getContent={(r: RowTableServicos) =>
                r.tarifaCartao.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
              }
            />
            <DataTableCell
              getContent={(r: RowTableServicos) =>
                r.valorLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
              }
            />
          </TableBody>
        </Table>
      </View>
    )
  }

  render(): JSX.Element {
    return (
      <PDFViewer style={{ width: '100%', height: '100%', position: 'absolute' }}>
        <Document>
          <Page size="A4" style={{ margin: 20 }}>
            <View style={{ fontSize: 11 }}>
              <Image
                style={{ width: 200, height: 70 }}
                source={process.env.PUBLIC_URL + '/assets/img/logo_lincar_completa.png'}
              />
              <Text style={{ fontWeight: 'bold', marginTop: 35 }}>Parceiro: {this.state.parceiro.nome}</Text>
              <Text style={{ fontWeight: 'bold', marginTop: 1 }}>
                Período: {moment(this.state.dataInicio).format('DD/MM/yyyy')} a{' '}
                {moment(this.state.dataFim).format('DD/MM/yyyy')}
              </Text>

              <Text style={{ fontWeight: 'bold', marginTop: 35 }}>SERVIÇOS REALIZADOS</Text>

              {this.renderTable()}
              <Text style={{ fontWeight: 'bold', marginTop: 35 }}>
                TOTAL LÍQUIDO FATURADO EM SERVIÇOS:{' '}
                {this.state.valorEmServico.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </Text>
              <Text style={{ fontWeight: 'bold', marginTop: 5 }}>
                RECEBIDO EM DINHEIRO:{' '}
                {this.state.recebidoEmDinheiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </Text>
              <Text style={{ fontWeight: 'bold', marginTop: 5 }}>
                RECEBIDO EM CARTÃO:{' '}
                {this.state.recebidoEmCartao.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </Text>

              <Text style={{ fontWeight: 'bold', marginTop: 15 }}>OUTROS DESCONTOS E RECEBIMENTOS</Text>
              {this.renderTableDescontosERecebimentos()}
              <Text style={{ fontWeight: 'bold', marginTop: 35 }}>
                TOTAL LÍQUIDO FATURADO EM DESCONTOS E RECEBIMENTOS:{' '}
                {this.state.liquidoDescontosERecebimentos.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
              <Text style={{ fontWeight: 'bold', marginTop: 20 }}>
                TOTAL LÍQUIDO GERAL (DINHEIRO E CARTÃO):{' '}
                {this.state.valorBruto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </Text>
              <Text style={{ fontWeight: 'bold', marginTop: 5 }}>
                TOTAL A RECEBER:{' '}
                {this.state.valorLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </Text>
              <Text style={{ fontWeight: 'bold', marginTop: 5 }}>
                SITUAÇÃO DO PAGAMENTO:{' '}
                {this.state.extratoPagamento.pago === true
                  ? 'Pago dia: ' + moment(this.state.dataPagamento).format('DD/MM/yyyy')
                  : 'Não pago'}
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    )
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof ExtratosPagamentoActions =>
  bindActionCreators({ ...ExtratosPagamentoActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DocumentoPDF)
