// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// TipoVeiculo
import * as TiposVeiculosActions from '../../store/models/tiposveiculos/actions'
import TipoVeiculoController from '../../controller/TipoVeiculoController'
import TipoVeiculo from '../../models/TipoVeiculo'
import 'react-datepicker/dist/react-datepicker.css'
// Form
import { Form, Button } from 'react-bootstrap'
// Dropdown
import Dropdown, { Option } from 'react-dropdown'
import 'react-dropdown/style.css'

interface TipoVeiculoAlterarState {
  tipoVeiculo: TipoVeiculo
}

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState &
  LoginState &
  typeof LoginActions &
  typeof TiposVeiculosActions &
  RouteComponentProps<any>

class TipoVeiculoAlterar extends Component<Props, TipoVeiculoAlterarState> {
  private modelo: React.RefObject<HTMLInputElement>
  private marca: React.RefObject<HTMLInputElement>

  constructor(props: Props) {
    super(props)
    this.onChangeDropdown = this.onChangeDropdown.bind(this)
    this.state = {
      tipoVeiculo: this.props.models.tiposVeiculos.selecionado,
    }
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    if (!this.props.models.tiposVeiculos || this.props.models.tiposVeiculos.selecionado.isEmpty) {
      if (this.props.match.params.id) {
        this.pegarTipoVeiculoById(this.props.match.params.id)
      } else {
        this.props.history.replace('/tiposVeiculos')
      }
    }

    // Vincula palavra this a função
    this.goBack = this.goBack.bind(this)
    this.salvarTipoVeiculo = this.salvarTipoVeiculo.bind(this)
    this.modelo = React.createRef<HTMLInputElement>()
    this.marca = React.createRef<HTMLInputElement>()
  }

  async pegarTipoVeiculoById(documentId: string): Promise<void> {
    const tipoVeiculo = await TipoVeiculoController.pegarPorId(documentId)
    this.props.modificarTipoVeiculoSelecionado(tipoVeiculo)
    this.setState({
      tipoVeiculo: tipoVeiculo,
    })
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  onChangeDropdown(option: Option): void {
    const newTipoVeiculo = Object.assign(new TipoVeiculo(), this.state.tipoVeiculo)
    newTipoVeiculo.tamanho = option.value
    this.setState({
      tipoVeiculo: newTipoVeiculo,
    })
  }

  renderLogged(): JSX.Element {
    // Define estilo dos botões
    const estiloBtnAdicionar = {
      backgroundColor: '#113F4F',
      color: '#FFFFFF',
      borderRadius: '0',
    }
    const estiloBtnCancelar = {
      backgroundColor: '#1B9999',
      color: '#FFFFFF',
      borderRadius: '0',
    }
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Alterar Tipo de Veiculo</h1>
          <br />
          <Form className="form">
            <p>Marca</p>
            <Form.Group controlId="formaBasicMarca">
              <Form.Control
                defaultValue={this.state.tipoVeiculo.marca}
                ref={this.marca}
                type="text"
                placeholder="Marca"
              />
            </Form.Group>
            <p>Modelo</p>
            <Form.Group controlId="formBasicModelo">
              <Form.Control
                defaultValue={this.state.tipoVeiculo.modelo}
                ref={this.modelo}
                type="text"
                placeholder="Modelo"
              />
            </Form.Group>
            <p>Tamanho</p>
            <Form.Group controlId="formaBasicTamanho">
              <Dropdown
                options={['Pequeno', 'Medio', 'Grande']}
                onChange={this.onChangeDropdown}
                value={this.state.tipoVeiculo.tamanho}
                placeholder="Selecione o tamanho"
              />
            </Form.Group>
            <br />
            <div className="formButtons">
              <Button onClick={this.goBack} style={estiloBtnCancelar}>
                Cancelar
              </Button>
              <Button onClick={this.salvarTipoVeiculo} style={estiloBtnAdicionar}>
                Alterar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }

  async salvarTipoVeiculo(): Promise<void> {
    try {
      const modelo = this.modelo.current?.value
      const marca = this.marca.current?.value
      if (modelo && marca) {
        let tipoVeiculo: TipoVeiculo = Object.assign(new TipoVeiculo(), this.state.tipoVeiculo)
        tipoVeiculo.modelo = modelo
        tipoVeiculo.marca = marca
        tipoVeiculo.tamanho = this.state.tipoVeiculo.tamanho
        tipoVeiculo.canInsertOrUpdate()

        tipoVeiculo = await TipoVeiculoController.alterar(tipoVeiculo)
        this.setState({
          tipoVeiculo: tipoVeiculo,
        })
        this.props.modificarTipoVeiculoSelecionado(tipoVeiculo)
        this.props.history.goBack()
      } else {
        throw Error('Valores inválidos')
      }
    } catch (error) {
      alert(error)
    }
  }

  goBack(): void {
    this.props.history.push('/tiposVeiculos')
  }

  renderNotLogged(): JSX.Element {
    return (
      <div>
        <h1>Você deve estar logado</h1>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof TiposVeiculosActions =>
  bindActionCreators({ ...LoginActions, ...TiposVeiculosActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TipoVeiculoAlterar)
