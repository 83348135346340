import BaseDocument from './BaseDocument'
import { CancelamentoInterface } from './model_interfaces'

export default class Cancelamento extends BaseDocument<Cancelamento> implements CancelamentoInterface {
  motivo!: string
  origemCancelamento!: number
  datahora!: Date
  consequencia!: string
  async fillFromJson(json?: any, recursive?: string[] | undefined): Promise<Cancelamento> {
    if (!json) {
      json = this.dados
    }

    this.motivo = json.motivo
    this.datahora = new Date(json.dataHora)
    this.consequencia = json.consequencia
    this.origemCancelamento = json.origemCancelamento

    return this
  }
}
