import SessionRepository from '../repositories/SessionRepository'
import AdministradorRepository from '../repositories/AdministradorRepository'
import Administrador from '../models/Administrador'
import FirebaseManager from '../utils/FirebaseManager'

class SessionController {
  public async login(email: string, senha: string): Promise<Administrador | undefined> {
    const auth: firebase.auth.UserCredential = await SessionRepository.login(email, senha)
    let administrador: Administrador | undefined
    if (auth.user) {
      administrador = await AdministradorRepository.getAdministradoByUid(auth.user.uid)
    }
    return administrador
  }

  // Faz chamada para logout
  public async logout() {
    await SessionRepository.logout()
    return FirebaseManager.auth.currentUser != null
  }
}

export default new SessionController()
