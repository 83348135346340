// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// TipoServico
import * as TipoServicosActions from '../../store/models/tiposservicos/actions'
import TipoServicoController from '../../controller/TipoServicoController'
import TipoServico from '../../models/TipoServico'
import { InfoTipoServico } from '../../models/model_interfaces'
// Form
import { Form, Button } from 'react-bootstrap'

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState & LoginState & typeof LoginActions & typeof TipoServicosActions & RouteComponentProps<any>

interface TipoServicoState {
  pai: TipoServico
  posServico: number
}

class TipoServiAdicionar extends Component<Props, TipoServicoState> {
  private tipoServico: TipoServico = new TipoServico()
  private descricao: React.RefObject<HTMLInputElement>
  private temposMedio: React.RefObject<HTMLInputElement>[]
  private valores: React.RefObject<HTMLInputElement>[]
  private dica: React.RefObject<HTMLTextAreaElement>
  private tamanhos = ['Pequeno', 'Medio', 'Grande']

  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    // Vincula palavra this a função
    this.goBack = this.goBack.bind(this)
    this.salvarTipoServico = this.salvarTipoServico.bind(this)
    this.renderDropdown = this.renderDropdown.bind(this)
    this.handleChangeDropDown = this.handleChangeDropDown.bind(this)

    this.temposMedio = new Array<React.RefObject<HTMLInputElement>>(3)
    this.valores = new Array<React.RefObject<HTMLInputElement>>(3)
    for (let i = 0; i < 3; i++) {
      this.temposMedio[i] = React.createRef<HTMLInputElement>()
      this.valores[i] = React.createRef<HTMLInputElement>()
    }
    this.descricao = React.createRef<HTMLInputElement>()
    this.dica = React.createRef<HTMLTextAreaElement>()

    this.state = {
      posServico: -1,
      pai: new TipoServico(),
    }
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  handleChange(tipo: 'aptoLoja' | 'aptoPontoFixo' | 'aptoDelivery', evt: React.ChangeEvent<HTMLInputElement>): void {
    this.tipoServico[tipo] = evt.target.checked
  }

  renderInfoTipoServicos(): JSX.Element[] {
    const forms: JSX.Element[] = []
    for (let i = 0; i < 3; i++) {
      forms.push(
        <Form.Group controlId={'formTamanho' + i} key={i}>
          <p>{this.tamanhos[i]}</p>
          <Form.Control ref={this.valores[i]} type="number" placeholder="Valor" />
          <Form.Control ref={this.temposMedio[i]} type="number" placeholder="Tempo" />
        </Form.Group>,
      )
    }

    return forms
  }

  renderLogged(): JSX.Element {
    // Define estilo dos botões
    const estiloBtnAdicionar = {
      backgroundColor: '#113F4F',
      color: '#FFFFFF',
      borderRadius: '0',
    }
    const estiloBtnCancelar = {
      backgroundColor: '#1B9999',
      color: '#FFFFFF',
      borderRadius: '0',
    }
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Adicionar novo Tipo de Serviços</h1>
          <br />
          <Form className="form">
            <p>Nome</p>
            <Form.Group controlId="formBasicDescricao">
              <Form.Control ref={this.descricao} type="text" placeholder="Descrição" />
            </Form.Group>
            <p>Dica</p>
            <Form.Group controlId="formaBasicDica">
              <Form.Control defaultValue={this.tipoServico.dica} ref={this.dica} as="textarea" placeholder="Dica" />
            </Form.Group>
            <p>Apto na loja?</p>
            <Form.Group controlId="formBasicVerificacaoLoja" className="checkBoxValidacaoParceiro">
              <Form.Check
                defaultChecked={this.tipoServico.aptoLoja}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => this.handleChange('aptoLoja', evt)}
                type="checkbox"
              />
            </Form.Group>
            <p>Apto no ponto fixo?</p>
            <Form.Group controlId="formBasicPontoFixo" className="checkBoxValidacaoParceiro">
              <Form.Check
                defaultChecked={this.tipoServico.aptoPontoFixo}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => this.handleChange('aptoPontoFixo', evt)}
                type="checkbox"
              />
            </Form.Group>
            <p>Apto delivery?</p>
            <Form.Group controlId="formBasicPontoFixo" className="checkBoxValidacaoParceiro">
              <Form.Check
                defaultChecked={this.tipoServico.aptoDelivery === undefined ? true : this.tipoServico.aptoDelivery}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => this.handleChange('aptoDelivery', evt)}
                type="checkbox"
              />
            </Form.Group>
            <div className="formItensMenores">{this.renderInfoTipoServicos()}</div>
            {this.renderDropdown()}
            <div className="formButtons formButtonsTipoServico">
              <Button onClick={this.goBack} style={estiloBtnCancelar}>
                Cancelar
              </Button>
              <Button onClick={this.salvarTipoServico} style={estiloBtnAdicionar}>
                Adicionar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }

  renderDropdown(): JSX.Element {
    const options: JSX.Element[] = this.props.models.tiposServicos.data.map<JSX.Element>((tipoServico, idx) => {
      return (
        <option key={idx + 1} value={idx}>
          {tipoServico.descricao}
        </option>
      )
    })
    options.unshift(
      <option key={0} value={-1}>
        Nenhum
      </option>,
    )
    return (
      <Form.Group className="dropdown" style={{ position: 'inherit' }}>
        <Form.Control as="select" onChange={this.handleChangeDropDown} value={this.state.posServico}>
          {options}
        </Form.Control>
      </Form.Group>
    )
  }

  handleChangeDropDown(event: React.ChangeEvent<HTMLSelectElement>): void {
    if (Number(event.target.value) === -1) {
      this.setState({
        posServico: -1,
        pai: new TipoServico(),
      })
    } else {
      this.setState({
        posServico: Number(event.target.value),
        pai: this.props.models.tiposServicos.data[Number(event.target.value)],
      })
    }
  }

  async salvarTipoServico(): Promise<void> {
    try {
      const infosTipoServico: InfoTipoServico[] = []

      for (let i = 0; i < 3; i++) {
        const valor = this.valores[i].current?.valueAsNumber
        const tempoMedio = this.temposMedio[i].current?.valueAsNumber
        if (valor && tempoMedio) {
          const infoTipoServico: InfoTipoServico = {
            valor: valor,
            tempoMedio: tempoMedio,
            tamanho: this.tamanhos[i],
          }

          infosTipoServico.push(infoTipoServico)
        } else {
          throw new Error('Dados inválidos')
        }
      }
      const descricao = this.descricao.current?.value
      const dica = this.dica.current?.value
      if (infosTipoServico && descricao && descricao) {
        this.tipoServico.infosTipoServico = infosTipoServico
        this.tipoServico.descricao = descricao
        this.tipoServico.dica = dica || ''
        this.tipoServico.cidade = this.props.match.params.cidadeAtua
        this.tipoServico.paiRef = this.state.pai.isEmpty ? null : this.state.pai.ref
        this.tipoServico.ativo = true
        this.tipoServico.canInsertOrUpdate()
        this.tipoServico = await TipoServicoController.criar(this.tipoServico)
        this.props.adicionarTipoServico(this.tipoServico)
        this.props.history.goBack()
      } else {
        throw Error('Valores inválidos')
      }
    } catch (error) {
      alert(error)
    }
  }

  goBack(): void {
    this.props.history.goBack()
  }

  renderNotLogged(): JSX.Element {
    return (
      <div className="Home">
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  // Recupera lista Tipo Serviço
  async componentDidMount(): Promise<void> {
    let done: boolean | undefined = false
    const asyncGeneratorTipoServico = TipoServicoController.pegarTodos(this.props.match.params.cidadeAtua)
    while (!done) {
      const iteratorResult = await asyncGeneratorTipoServico.next()
      if (iteratorResult.value) {
        this.props.adicionarTipoServico(iteratorResult.value)
      }
      done = iteratorResult.done
    }
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof TipoServicosActions =>
  bindActionCreators({ ...LoginActions, ...TipoServicosActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TipoServiAdicionar)
