import { Reducer } from 'redux'
import { ParceirosState, ParceirosTypes } from './types'
import Parceiro from '../../../models/Parceiro'

const initialState: ParceirosState = {
  data: [],
  selecionado: new Parceiro(),
  listaCarregada: false,
}

const reducer: Reducer<ParceirosState> = (state = initialState, action) => {
  const currentData = state.data

  switch (action.type) {
    case ParceirosTypes.ADICIONAR_PARCEIRO: {
      const newData = currentData.filter(p => p.id !== action.payload.id)
      newData.push(action.payload)
      return { ...state, data: newData }
    }
    case ParceirosTypes.ATUALIZAR_PARCEIROS:
      return { ...state, data: action.payload.data }
    case ParceirosTypes.LIMPAR_PARCEIROS:
      return { ...state, data: [], listaCarregada: false }
    case ParceirosTypes.MODIFICAR_PARCEIRO_SELECIONADO:
      return { ...state, selecionado: action.payload }
    case ParceirosTypes.REMOVER_PARCEIRO: {
      const newData = currentData.filter(parceiro => parceiro.id !== action.payload.id)
      return { ...state, data: newData }
    }
    case ParceirosTypes.MODIFICAR_LISTA_CARREGADA:
      return { ...state, listaCarregada: action.payload }
    default:
      return state
  }
}

export default reducer
