// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// Cliente
import * as ClientesActions from '../../store/models/clientes/actions'
import ClienteController from '../../controller/ClienteController'
import Cliente from '../../models/Cliente'
// Bootstrap 4
import { Button, Form } from 'react-bootstrap'

interface ClienteAlterarState {
  cliente: Cliente
  url: string
}

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState & LoginState & typeof LoginActions & typeof ClientesActions & RouteComponentProps<any>

class ClienteAlterar extends Component<Props, ClienteAlterarState> {
  private validado: React.RefObject<HTMLInputElement>
  private nome: React.RefObject<HTMLInputElement>

  constructor(props: Props) {
    super(props)

    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }

    // Vincula palavra this a função
    this.goBack = this.goBack.bind(this)
    this.salvarCliente = this.salvarCliente.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.validado = React.createRef<HTMLInputElement>()
    this.nome = React.createRef<HTMLInputElement>()
    this.state = {
      cliente: this.props.models.clientes.selecionado,
      url: '',
    }

    if (
      !this.props.models.clientes ||
      !this.props.models.clientes ||
      this.props.models.clientes.selecionado.isEmpty
    ) {
      if (this.props.match.params.id) {
        this.pegarClienteById(this.props.match.params.id)
      } else {
        this.props.history.replace('/clientes')
      }
    }
  }

  async pegarClienteById(documentId: string): Promise<void> {
    const cliente = await ClienteController.pegarPorId(documentId)
    this.setState({
      cliente: cliente,
    })
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  renderLogged(): JSX.Element {
    if (this.state.cliente.isEmpty) {
      return <div>Carregando...</div>
    }
    const estiloBtnSalvar = { backgroundColor: '#113F4F', color: '#FFFFFF', borderRadius: '0' }
    const estiloBtnCancelar = { backgroundColor: '#1B9999', color: '#FFFFFF', borderRadius: '0' }
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Alterar Tipo de Serviços</h1>
          <br />
          <Form className="form">
            <p>Cliente</p>
            <Form.Group controlId="formaBasicNome">
              <Form.Control defaultValue={this.state.cliente.nome} ref={this.nome} type="text" placeholder="nome" />
            </Form.Group>
            <div className="formButtons">
              <Button onClick={this.goBack} style={estiloBtnCancelar}>
                Voltar
              </Button>
              <Button onClick={this.salvarCliente} style={estiloBtnSalvar}>
                Salvar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }

  handleChange(evt: React.ChangeEvent<HTMLInputElement>): void {
    const cliente = this.state.cliente
    this.setState({ cliente: cliente })
  }

  async salvarCliente(): Promise<void> {
    try {
      const nome = this.nome.current?.value
      if (nome) {
        this.state.cliente.nome = nome

        this.props.modificarClienteSelecionado(await ClienteController.alterar(this.state.cliente))
        this.props.adicionarCliente(this.state.cliente)
        this.props.history.goBack()
      } else {
        throw Error("Nome inválido")
      }
    } catch (error) {
      alert(error)
    }
  }

  goBack(): void {
    this.props.history.goBack()
  }

  renderNotLogged(): JSX.Element {
    return (
      <div className="Home">
        <h1>Você deve estar logado</h1>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof ClientesActions =>
  bindActionCreators({ ...LoginActions, ...ClientesActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ClienteAlterar)
