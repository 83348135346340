import { action } from 'typesafe-actions'
import {
  EnderecosState,
  EnderecosTypes,
  AtualizarEnderecos,
  AdicionarEndereco,
  LimparEnderecos,
  ModificarEnderecoSelecionado,
  ModificarListaCarregadaEnderecos,
  RemoverEndereco,
} from './types'
import Endereco from '../../../models/Endereco'
export const atualizarEnderecos = (data: EnderecosState): AtualizarEnderecos =>
  action(EnderecosTypes.ATUALIZAR_ENDERECOS, data)

export const adicionarEndereco = (newEndereco: Endereco): AdicionarEndereco => {
  return action(EnderecosTypes.ADICIONAR_ENDERECO, newEndereco)
}

export const removerEndereco = (newEndereco: Endereco): RemoverEndereco => {
  return action(EnderecosTypes.REMOVER_ENDERECO, newEndereco)
}

export const limparEnderecos = (): LimparEnderecos => {
  return action(EnderecosTypes.LIMPAR_ENDERECOS)
}

export const modificarEnderecoSelecionado = (newEndereco: Endereco): ModificarEnderecoSelecionado => {
  return action(EnderecosTypes.MODIFICAR_ENDERECO_SELECIONADO, newEndereco)
}

export const modificarListaCarregadaEnderecos = (estaCarregada: boolean): ModificarListaCarregadaEnderecos => {
  return action(EnderecosTypes.MODIFICAR_LISTA_CARREGADA, estaCarregada)
}
