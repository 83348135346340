import moment, { Moment } from 'moment-timezone'
import { DiaSemana, DisponibilidadeInterface, Turno } from './model_interfaces'
import BaseDocument from './BaseDocument'
export default class Disponibilidade extends BaseDocument<Disponibilidade> implements DisponibilidadeInterface {
  diaSemana!: DiaSemana
  turno!: Turno
  dataInicio!: Moment
  dataFim!: Moment
  excepcional!: boolean
  pid!: string
  async fillFromJson(json?: any, recursive?: string[] | undefined): Promise<Disponibilidade> {
    if (!json) {
      json = this.dados
    }

    this.diaSemana = json.diaSemana
    this.turno = json.turno
    this.excepcional = json.excepcional
    this.pid = json.pid
    this.dataInicio = moment(json.dataInicio)
    this.dataFim = moment(json.dataFim)

    return this
  }
}
