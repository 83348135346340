import { Reducer } from 'redux'
import { ClientesState, ClientesTypes } from './types'
import Cliente from '../../../models/Cliente'

const initialState: ClientesState = {
  data: [],
  selecionado: new Cliente(),
  listaCarregada: false,
}

const reducer: Reducer<ClientesState> = (state = initialState, action) => {
  const currentData = state.data

  switch (action.type) {
    case ClientesTypes.ADICIONAR_CLIENTE: {
      const newData = currentData.filter(p => p.id !== action.payload.id)
      newData.push(action.payload)
      return { ...state, data: newData }
    }
    case ClientesTypes.ATUALIZAR_CLIENTES:
      return { ...state, data: action.payload.data }
    case ClientesTypes.LIMPAR_CLIENTES:
      return { ...state, data: [], listaCarregada: false }
    case ClientesTypes.MODIFICAR_CLIENTE_SELECIONADO:
      return { ...state, selecionado: action.payload }
    case ClientesTypes.REMOVER_CLIENTE: {
      const newData = currentData.filter(cliente => cliente.id !== action.payload.id)
      return { ...state, data: newData }
    }
    case ClientesTypes.MODIFICAR_LISTA_CARREGADA:
      return { ...state, listaCarregada: action.payload }
    default:
      return state
  }
}

export default reducer
