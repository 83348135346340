import BaseDocument from './BaseDocument'
import { SituacaoInterface } from './model_interfaces'
import moment from 'moment-timezone'

export default class Situacao extends BaseDocument<Situacao> implements SituacaoInterface {
  estado!: number
  dataHora!: Date
  dataFormatado!: string
  horaFormatado!: string
  async fillFromJson(json?: any, recursive?: string[] | undefined): Promise<Situacao> {
    if (!json) {
      json = this.dados
    }

    this.estado = json.estado
    this.dataHora = moment(json.dataHora).toDate()
    this.dataFormatado = this.dataHora.toLocaleDateString()
    this.horaFormatado = this.dataHora.toLocaleTimeString()

    return this
  }
}
