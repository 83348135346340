import IuguCommon from './common'
import IuguMethods from '../iugu_methods'

class IuguInvoices extends IuguCommon<any> {
  async refund(
    data: string | object,
    urlParams?: Map<string, string> | undefined,
    queryParams?: Map<string, string> | undefined,
  ): Promise<any> {
    return IuguMethods.createIuguMethod<any>({
      method: 'post',
      path: '/' + this.routeName + '/{documentId}/refund',
      urlParams: ['documentId'],
      checkErrors: this.checkCreate,
    })(data, urlParams)
  }

  get routeName(): string {
    return 'invoice'
  }
}

export default new IuguInvoices()
