// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Grid
import { SearchState, IntegratedFiltering, IntegratedPaging, PagingState } from '@devexpress/dx-react-grid'
import { Grid, Table, Toolbar, SearchPanel, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui'
import { ActionColumns, ActionColumn } from '../utils/TableActionButtons'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// Cliente
import * as ClientesActions from '../../store/models/clientes/actions'
import ClienteController from '../../controller/ClienteController'
// Pega os icones
// import Create from '@material-ui/icons/Create'
import Visibility from '@material-ui/icons/Visibility'
// import Delete from '@material-ui/icons/Delete'
// import Send from '@material-ui/icons/Send'
import Cliente from '../../models/Cliente'

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState & LoginState & typeof LoginActions & typeof ClientesActions & RouteComponentProps

// Determinar cabecalho do grid
const cabecalho = [{ name: 'visualizar' }, { name: 'nome', title: 'Nome' }, { name: 'email', title: 'E-mail' }]

interface RowCliente {
  nome: string
  cliente: Cliente
  email: string
}

interface ClienteState {
  quantidade: number
}

// Valores padrão no grid
const tableMessages = {
  noData: 'Sem registros',
}
const searchMessages = {
  searchPlaceholder: 'Pesquisar',
}

class HomeClientes extends Component<Props, ClienteState> {
  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    // Vincula palavra this a função
    this.goToAdicionar = this.goToAdicionar.bind(this)
    this.handleClickAlterar = this.handleClickAlterar.bind(this)
    this.handleClickExcluir = this.handleClickExcluir.bind(this)
    this.handleClickVisualizar = this.handleClickVisualizar.bind(this)
    this.state = {
      quantidade: 0,
    }
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  renderLogged(): JSX.Element {
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Clientes LinCar</h1>
          <h3 className="tituloPagina">Total de clientes: {this.state.quantidade}</h3>
          {this.renderGrid()}
        </div>
      </div>
    )
  }

  goToAdicionar(): void {
    this.props.history.push('/clientes/adicionar')
  }

  renderNotLogged(): JSX.Element {
    return (
      <div>
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  // Recupera lista Clientes
  async componentDidMount(): Promise<void> {
    ClienteController.pegarQuantidadeDeClientes().then(value => {
      this.setState({
        quantidade: value,
      })
    })
    if (!this.props.models.clientes.listaCarregada) {
      let done: boolean | undefined = false
      this.props.limparClientes()
      const asyncGeneratorCliente = ClienteController.pegarTodos()
      while (!done) {
        const iteratorResult = await asyncGeneratorCliente.next()
        if (iteratorResult.value) {
          this.props.adicionarCliente(iteratorResult.value)
        }
        done = iteratorResult.done
      }
      this.props.modificarListaCarregadaClientes(true)
    }
  }

  private handleClickAlterar(row: RowCliente): void {
    this.props.modificarClienteSelecionado(row.cliente)
    this.props.history.push('clientes/' + row.cliente.uid + '/alterar')
  }

  private handleClickVisualizar(row: RowCliente): void {
    this.props.modificarClienteSelecionado(row.cliente)
    this.props.history.push('clientes/' + row.cliente.uid + '/visualizar')
  }

  private handleClickEnviarVerificacao(row: RowCliente): void {
    this.props.modificarClienteSelecionado(row.cliente)
    this.props.history.push('clientes/' + row.cliente.uid + '/alterar')
  }

  private handleClickExcluir(row: RowCliente): void {
    ClienteController.deletar(row.cliente)
    this.props.removerCliente(row.cliente)
  }

  renderGrid(): JSX.Element {
    // Busca serviços e estrutura registros
    const listClientes = this.props.models.clientes ? this.props.models.clientes.data : []
    let tuplas: readonly RowCliente[] = []
    if (listClientes) {
      tuplas = listClientes.map(cliente => {
        return {
          nome: cliente.nome,
          cliente: cliente,
          email: cliente.email,
        }
      })
    }
    const actionColumns: ActionColumn[] = [
      // { columnName: 'alterar', label: 'Abrir modo edição', onClick: this.handleClickAlterar, icon: <Create /> },
      {
        columnName: 'visualizar',
        label: 'Visualizar cliente',
        onClick: this.handleClickVisualizar,
        icon: <Visibility />,
      },
    ]
    // const actionColumns: ActionColumn[] = [
    //  { columnName: 'alterar', label: 'Abrir modo edição', onClick: this.handleClickAlterar, icon: <Create /> },
    //  {
    //    columnName: 'verificacao',
    //    label: 'Enviar verificação',
    //    onClick: this.handleClickEnviarVerificacao,
    //    icon: <Send />,
    //  },
    //  { columnName: 'excluir', label: 'Excluir linha', onClick: this.handleClickExcluir, icon: <Delete /> },
    // ]
    // Monta Grid
    return (
      <div className="grid">
        <Grid rows={tuplas} columns={cabecalho}>
          <PagingState defaultCurrentPage={0} defaultPageSize={5} />
          <SearchState defaultValue="" />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table messages={tableMessages} />
          <TableHeaderRow />
          <Toolbar />

          <SearchPanel messages={searchMessages} />
          <ActionColumns actionColumns={actionColumns} />
          <PagingPanel
            pageSizes={[5, 10, 20]}
            messages={{
              showAll: 'Mostrar todos',
              rowsPerPage: 'Linhas por página',
              info: (parameters: { from: number; to: number; count: number }): string =>
                parameters.from + '-' + parameters.to + ' de ' + parameters.count,
            }}
          />
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof ClientesActions =>
  bindActionCreators({ ...LoginActions, ...ClientesActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HomeClientes)
