// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import './style.css'

// Une todos em props antes de passar ao ao componente
type Props = RouteComponentProps

export class Home extends Component<Props> {
  private navCollapse: React.RefObject<HTMLDivElement>
  constructor(props: Props) {
    super(props)
    this.navCollapse = React.createRef<HTMLDivElement>()
    this.handleNavToggle = this.handleNavToggle.bind(this)
    this.goTo = this.goTo.bind(this)
  }

  handleNavToggle(): void {
    const current = this.navCollapse.current
    if (current) {
      if (current.className.includes('show')) {
        current.className = current.className.replace('show', '')
      } else {
        current.className = current.className + ' show'
      }
    }
  }

  goTo(path: string): void {
    this.props.history.push(path)
  }

  render(): JSX.Element {
    return (
      <div id="page-top">
        <nav className="navbar navbar-expand-lg bg-secondary fixed-top" id="mainNav">
          <div className="container">
            <a className="navbar-brand js-scroll-trigger" href="#page-top">
              <img src={process.env.PUBLIC_URL + '/assets/img/logo_icon.png'} alt="Símbolo da empresa Lincar" />
              <img src={process.env.PUBLIC_URL + '/assets/img/logo_nome.png'} alt="Lincar" />
            </a>
            <button
              className="navbar-toggler navbar-toggler-right font-weight-bold bg-primary text-white rounded"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              onClick={this.handleNavToggle}
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              Menu
              <FontAwesomeIcon icon={faBars} size="lg" color="white" style={{ marginLeft: 10 }} />
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive" ref={this.navCollapse}>
              <ul className="navbar-nav ml-auto">
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger nav-item-selected" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger" href="servicos">
                    Serviços
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger" href="club">
                    Lincar Club
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger" href="faleconosco">
                    Fale conosco
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger seja" href="seja-parceiro">
                    Seja Parceiro
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <section className="bloco-inicio">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 ml-auto">
                <h1 className="titulo-landing">Inovando na limpeza do seu carro</h1>
                <div className="subtitulo">
                  <h6>
                    A lincar oferece os mais diversos serviços para que você tenha seu carro limpo em qualquer lugar!
                  </h6>
                </div>
              </div>
              <div className="col-lg-4 mr-auto">
                <img className="imagem" src={process.env.PUBLIC_URL + '/assets/img/Ilustra_main.png'} alt="" />
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
        <br />
        <section>
          <div className="container" style={{ width: '85%' }}>
            <div className="row text-center">
              <div>
                <p className="benebene">BENEFÍCIOS</p>
                <p className="benetitulo">Por que a lavagem ecológica?</p>
                <p className="benetexto">
                  Além de economizar em média de 350 a 400 litros de água, a Lavagem Ecológica é feita com produtos
                  biodegradáveis de alta tecnologia, cria uma camada protetora a base de cera de carnaúba em toda
                  lataria do carro que além de proteger a pintura evita que a sujeira se instale facilmente.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="page-section portfolio" id="portfolio">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="mx-auto benecaixa">
                  <p className="benecaixatitulo">Ajuda o planeta</p>
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/bene_planeta.png'} alt="" />
                  <p className="benecaixatexto">A lavagem ecológica quase não utiliza água!</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="mx-auto benecaixa">
                  <p className="benecaixatitulo">É bom para o seu bolso</p>
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/bene_bolso.png'} alt="" />
                  <p className="benecaixatexto">
                    A Lavagem Ecológica dura até duas vezes mais que a lavagem convencional.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="mx-auto benecaixa">
                  <p className="benecaixatitulo">É pratico</p>
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/bene_pratic.png'} alt="" />
                  <p className="benecaixatexto">Peça o serviço a qualquer hora pelo celular!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 mb-5 mb-lg-0">
                <img
                  src={process.env.PUBLIC_URL + '/assets/img/logo_icon_nome_branco.png'}
                  alt="Símbolo da empresa Lincar"
                />
              </div>
              <div className="col-lg-3 mb-5 mb-lg-0">
                <ul className="linkrodape">
                  <li>
                    <a href="index">Home</a>
                  </li>
                  <li>
                    <a href="servicos">Serviços</a>
                  </li>
                  <li>
                    <a href="club">Lincar Club</a>
                  </li>
                  <li>
                    <a href="faleconosco">Fale conosco</a>
                  </li>
                  <li>
                    <a href="seja-parceiro" style={{ fontWeight: 'bold' }}>
                      Seja Parceiro
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-7">
                <p className="mb-0 endereco">Travessa Julio Feydit, 19 Lapa / Campos dos Goytacazes Cep:28010-180</p>
              </div>
            </div>
          </div>
        </footer>
        <section className="page-section">
          <div className="container">
            <div className="row" style={{ paddingTop: 20, textAlign: 'center' }}>
              <div className="col-md-6 col-lg-4 mb-5" style={{ paddingTop: 10 }}>
                <img src={process.env.PUBLIC_URL + '/assets/img/logo_teccampos.png'} />
              </div>
              <div className="col-md-6 col-lg-4 mb-5" style={{ textAlign: 'center' }}>
                <img src={process.env.PUBLIC_URL + '/assets/img/logo_embrapii.png'} />
              </div>
              <div className="col-md-6 col-lg-4 mb-5" style={{ textAlign: 'center' }}>
                <img src={process.env.PUBLIC_URL + '/assets/img/logo_fundecam.png'} width="120px" />
              </div>
            </div>
          </div>
        </section>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.bundle.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js"></script>
        <script src={process.env.PUBLIC_URL + '/scripts.js'}></script>
      </div>
    )
  }
}
