import { Moment } from 'moment-timezone'

export interface ServicoPrestadoInterface {
  agendamento: Date
  cliente: ClienteInterface
  parceiro: ParceiroInterface
  situacoes: SituacaoInterface[]
  avaliacaoCliente: number
  avaliacaoParceiroInterface: number
  carrinhoCompra: CarrinhoCompraInterface[]
  pagamento: Pagamento
  avaria: AvariaInterface
  cancelamento: CancelamentoInterface
  tipoPagamento: number
  statusPagamento: number
  cupom: CupomInterface
  comentario: string
  nota: number
  cidade: string
}

export interface ServicoClub {
  tipoServico: firebase.firestore.DocumentReference
}

export interface AvariaInterface {
  frente: string
  traseira: string
  lateralMotorista: string
  lateralCarona: string
  outro: string
}

export interface DisponibilidadeInterface {
  dataInicio: Moment
  dataFim: Moment
  excepcional: boolean
  diaSemana: DiaSemana
  turno: Turno
  pid: string
}

export enum DiaSemana {
  segunda,
  terca,
  quarta,
  quinta,
  sexta,
  sabado,
  domingo,
}

export enum Turno {
  manha,
  tarde,
  noite,
  madrugada,
}

export interface CancelamentoInterface {
  motivo: string
  origemCancelamento: number
  datahora: Date
  consequencia: string
}

export interface CarrinhoCompraInterface {
  veiculo: VeiculoInterface
  tipoServicos: TipoServicoInterface[]
}

export interface TipoServicoInterface {
  descricao: string
  dica: string
  infosTipoServico: InfoTipoServico[]
  cidade: string
  ativo: boolean
}

export interface VeiculoInterface {
  tipoVeiculo: TipoVeiculoInterface
  cor: string
  placa: string
}

export interface TipoVeiculoInterface {
  modelo: string
  tamanho: string
  marca: string
}

export interface ProdutoInterface {
  nome: string
  descricao: string
  validade: Moment
  quantidade: number
  preco: number
}

export interface ClienteInterface {
  nome: string
  telefone: string
  nascimento: Moment
  sexo: string
  carteira: number
  tokenCliente: string
  mediaNota: number
  veiculos: VeiculoInterface[]
  enderecos: EnderecoInterface[]
}

export interface EnderecoInterface {
  apelido: string
  endereco1: string
  endereco2: string
  cep: string
  bairro: string
  cidade: string
  estado: string
  loja: boolean
  pontoFixo: boolean
}

export interface Pagamento {
  tokenPayment: string
  cpfCnpj: string
  email: string
  name: string
  holderName: string
  displayNumber: string
  year: number
  month: number
  address: Address
}

export interface Fatura {
  data: Moment
  forma: string
  bruto: number
  liquido: number
  ativo: boolean
}

export enum Situacoes {
  SOLICITADO = 0,
  ACEITO = 1,
  LINCADOACAMINHO = 2,
  INICIADO = 3,
  AVARIAREGISTRADA = 4,
  FINALIZADO = 5,
  AVALIADOCLIENTE = 6,
  AVALIADOPARCEIRO = 7,
  SUGESTOES = 8,
  CANCELADOCLIENTE = 9,
  CANCELADOPARCEIRO = 10,
  CANCELADOSISTEMA = 11,
  ESTORNADO = 12,
}

export enum StatusPagamento {
  NAO_PAGO = 0,
  PAGO = 1,
  TAXA_PAGA = 3,
  TAXA_NAO_PAGA = 4,
  REEMBOLSO = 5,
}

export enum TipoCupom {
  VALOR = 0,
  PORCENTAGEM = 1,
}

export enum TipoPagamento {
  DINHEIRO = 0,
  CARTAO = 1,
}

export interface Address {
  zipcode: string
  number: string
}

export interface ParceiroInterface {
  nome: string
  telefone: string
  cidade: string[]
  nascimento: Date
  sexo: string
  cpf: string
  internetMovel: boolean
  deslocamento: boolean
  meioTransporte: number[]
  comoConheceu: number
  mediaNota: number
  validado: boolean
  documento: string
  bankAg: string
  accountType: string
  bankCC: string
  tokenParceiro: string
  endereco: EnderecoInterface
}

export interface SituacaoInterface {
  estado: number
  dataHora: Date
}

export interface InfoTipoServico {
  valor: number
  tempoMedio: number
  tamanho: string
}

export interface CupomInterface {
  validade: Moment
  codigo: string
  restante: number
  desconto: number
  tipoCupom: number
  valorMinimo: number
  voucher: number
  pidCliente: string
}

export interface CidadeAtuaInterface {
  nome: string
}

export interface ExtratoPagamentoInterface {
  servicosPrestado: string[]
  dataInicio: Moment
  dataFim: Moment
  valorBruto: number
  valorLiquido: number
  taxaFixaCartao: number
  descontosExtras: ItemExtra[]
  recebimentosExtras: ItemExtra[]
  dataPagamento: Moment | null
  taxaCartao: number
  taxaDinheiro: number
  pago: boolean
  pid: string
}

export interface ItemExtra {
  descricao: string
  valor: number
  id: string
}
