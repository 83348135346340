import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'

interface FirebaseConfigs {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

class FirebaseManager {
  private _database: firebase.firestore.Firestore

  public get database(): firebase.firestore.Firestore {
    return this._database
  }

  private _auth: firebase.auth.Auth

  public get auth(): firebase.auth.Auth {
    return this._auth
  }

  private _storage: firebase.storage.Storage

  public get storage(): firebase.storage.Storage {
    return this._storage
  }

  private _firebase: firebase.app.App

  public get firebase(): firebase.app.App {
    return this._firebase
  }

  public constructor() {
    const firebaseConfig: FirebaseConfigs = {
      apiKey: 'AIzaSyDpAYH-P4cpjV1Hp_dq8i6BX3iXnH0zxkk',
      authDomain: 'lincar-homologacao3-4ce3c.firebaseapp.com',
      databaseURL: 'https://lincar-homologacao3-4ce3c.firebaseio.com',
      projectId: 'lincar-homologacao3-4ce3c',
      storageBucket: 'lincar-homologacao3-4ce3c.appspot.com',
      messagingSenderId: '176837273780',
      appId: '1:176837273780:web:efe1167c9cd3f029188fe1',
      measurementId: 'G-7H3P6GBJTV',
    }

    // Initialize Firebase
    this._firebase = firebase.initializeApp(firebaseConfig)
    // firebase.analytics()
    this._database = firebase.firestore()
    this._auth = firebase.auth()
    this._storage = firebase.storage()
  }
}
export default new FirebaseManager()
