import BaseDocument from './BaseDocument'
import { CarrinhoCompraInterface } from './model_interfaces'
import { firestore } from 'firebase'
import TipoServico from './TipoServico'
import Veiculo from './Veiculo'

export default class CarrinhoCompra extends BaseDocument<CarrinhoCompra> implements CarrinhoCompraInterface {
  veiculo!: Veiculo
  tipoServicos!: TipoServico[]
  async fillFromJson(json?: any, recursive?: string[] | undefined): Promise<CarrinhoCompra> {
    recursive = recursive || ['tipoServico', 'veiculo']
    if (!json) {
      json = this.dados
    }

    const tipoServicos: firestore.DocumentReference[] = json.tiposServicos
    if ('tiposServicos' in json && recursive.includes('tipoServico') && tipoServicos) {
      this.tipoServicos = []
      for (const tipoServico of tipoServicos) {
        const snapshot = await tipoServico.get()
        const newTipoServico = await new TipoServico().convert(snapshot, recursive)
        if (newTipoServico instanceof TipoServico) {
          this.tipoServicos.push(newTipoServico)
        }
      }
    }

    const veiculo: firestore.DocumentReference = json.veiculo
    if ('veiculo' in json && recursive.includes('veiculo') && veiculo) {
      const snapshot = await veiculo.get()
      const newVeiculo = await new Veiculo().convert(snapshot, recursive)
      if (newVeiculo instanceof Veiculo) {
        this.veiculo = newVeiculo
      }
    }

    return this
  }
}
