// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// Cupom
import * as CuponsActions from '../../store/models/cupons/actions'
import CupomController from '../../controller/CupomController'
import Cupom from '../../models/Cupom'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
// Form
import { Form, Button } from 'react-bootstrap'

interface CupomAlterarState {
  validade: Date | null
  cupom: Cupom
  tipoCupom: number
}

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState & LoginState & typeof LoginActions & typeof CuponsActions & RouteComponentProps<any>

class CupomAlterar extends Component<Props, CupomAlterarState> {
  private codigo: React.RefObject<HTMLInputElement>
  private restante: React.RefObject<HTMLInputElement>
  private desconto: React.RefObject<HTMLInputElement>

  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    if (!this.props.models.cupons || !this.props.models.cupons || this.props.models.cupons.selecionado.isEmpty) {
      if (this.props.match.params.id) {
        this.pegarCupomById(this.props.match.params.id)
      } else {
        this.props.history.replace('/cupons')
      }
    }
    // Vincula palavra this a função
    this.goBack = this.goBack.bind(this)
    this.salvarCupom = this.salvarCupom.bind(this)
    this.handleChangeDropDown = this.handleChangeDropDown.bind(this)
    this.codigo = React.createRef<HTMLInputElement>()
    this.restante = React.createRef<HTMLInputElement>()
    this.desconto = React.createRef<HTMLInputElement>()
    this.state = {
      validade: this.props.models.cupons.selecionado.isEmpty
        ? null
        : this.props.models.cupons.selecionado.validade.toDate(),
      cupom: this.props.models.cupons.selecionado,
      tipoCupom: this.props.models.cupons.selecionado.isEmpty ? 0 : this.props.models.cupons.selecionado.tipoCupom,
    }
  }

  async pegarCupomById(documentId: string): Promise<void> {
    const cupom = await CupomController.pegarPorId(documentId)
    this.setState({
      cupom: cupom,
      validade: cupom.validade.toDate(),
    })
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  setDate(newDate: Date | null): void {
    this.setState({
      validade: newDate,
    })
  }

  handleChangeDropDown(event: React.ChangeEvent<HTMLSelectElement>): void {
    this.setState({
      tipoCupom: Number(event.target.value),
    })
  }

  renderLogged(): JSX.Element {
    // Define estilo dos botões
    const estiloBtnAdicionar = {
      backgroundColor: '#113F4F',
      color: '#FFFFFF',
      borderRadius: '0',
    }
    const estiloBtnCancelar = {
      backgroundColor: '#1B9999',
      color: '#FFFFFF',
      borderRadius: '0',
    }
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Alterar Cupom</h1>
          <br />
          <Form className="form">
            <p>Tipo Cupom</p>
            <Form.Group className="dropdown">
              <Form.Control as="select" onChange={this.handleChangeDropDown} value={this.state.tipoCupom}>
                <option value={0}>Valor</option>
                <option value={1}>Porcentagem</option>
              </Form.Control>
            </Form.Group>
            <p>Código</p>
            <Form.Group controlId="formBasicCodigo">
              <Form.Control defaultValue={this.state.cupom.codigo} ref={this.codigo} type="text" placeholder="Código" />
            </Form.Group>
            <p>Validade</p>
            <Form.Group controlId="formaBasicValidade">
              <DatePicker
                selected={this.state.validade}
                onChange={(date): void => this.setDate(date)}
                dateFormat="dd/MM/yyyy"
              />
            </Form.Group>
            <p>Quantidade restante</p>
            <Form.Group controlId="formaBasicRestante">
              <Form.Control
                defaultValue={this.state.cupom.restante}
                ref={this.restante}
                type="number"
                placeholder="Restante"
              />
            </Form.Group>
            <p>Valor do desconto</p>
            <Form.Group controlId="formaBasicDesconto">
              <Form.Control
                defaultValue={this.state.cupom.desconto}
                ref={this.desconto}
                type="number"
                placeholder="Desconto (R$)"
              />
            </Form.Group>
            <div className="formButtons">
              <Button onClick={this.goBack} style={estiloBtnCancelar}>
                Cancelar
              </Button>
              <Button onClick={this.salvarCupom} style={estiloBtnAdicionar}>
                Alterar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }

  async salvarCupom(): Promise<void> {
    try {
      const codigo = this.codigo.current?.value
      const desconto = this.desconto.current?.valueAsNumber
      const validade = this.state.validade
      const restante = this.restante.current?.valueAsNumber
      if (codigo && desconto && validade && restante) {
        this.state.cupom.codigo = codigo.toUpperCase()
        this.state.cupom.desconto = desconto
        this.state.cupom.validade = moment(validade)
        this.state.cupom.restante = restante
        this.state.cupom.tipoCupom = this.state.tipoCupom
        this.state.cupom.valorMinimo = 0
        this.state.cupom.canInsertOrUpdate()
        const cupom = await CupomController.alterar(this.state.cupom)
        this.setState({
          cupom: cupom,
        })
        this.props.history.goBack()
      } else {
        throw Error('Valores inválidos')
      }
    } catch (error) {
      alert(error)
    }
  }

  goBack(): void {
    this.props.history.push('/cupons')
  }

  renderNotLogged(): JSX.Element {
    return (
      <div>
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  // Recupera lista Tipo Serviço
  async componentDidMount(): Promise<void> {
    let done: boolean | undefined = false
    const asyncGeneratorCupom = CupomController.pegarTodos()
    while (!done) {
      const iteratorResult = await asyncGeneratorCupom.next()
      if (iteratorResult.value) {
        this.props.adicionarCupom(iteratorResult.value)
      }
      done = iteratorResult.done
    }
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof CuponsActions =>
  bindActionCreators({ ...LoginActions, ...CuponsActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CupomAlterar)
