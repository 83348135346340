// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// CSS
import './Menu.css'
import logo from '../imagens/menu_logo.svg'
import seletorU from '../imagens/menu_seletorU.svg'
import seletorS from '../imagens/menu_seletorS.svg'
import homeU from '../imagens/menu_home_u.png'
import homeS from '../imagens/menu_home_s.png'
import finaU from '../imagens/menu_finan_u.png'
import finaS from '../imagens/menu_finan_s.png'
import parcU from '../imagens/menu_parc_u.png'
import parcS from '../imagens/menu_parc_s.png'
import tipsU from '../imagens/menu_tipser_u.png'
import tipsS from '../imagens/menu_tipser_s.png'
import servU from '../imagens/menu_servp_u.png'
import servS from '../imagens/menu_servp_s.png'
import cupoU from '../imagens/menu_cupom_u.png'
import cupoS from '../imagens/menu_cupom_s.png'
import veicU from '../imagens/menu_tipveiculo_u.png'
import veicS from '../imagens/menu_tipveiculo_s.png'
import prodU from '../imagens/menu_produto_u.png'
import prodS from '../imagens/menu_produto_s.png'
import clieU from '../imagens/menu_cliente_u.png'
import clieS from '../imagens/menu_cliente_s.png'
import configS from '../imagens/menu_config_s.png'
import configU from '../imagens/menu_config_u.png'
import specificPlaceU from '../imagens/menu_ponto_fixo_u.svg'
import specificPlaceS from '../imagens/menu_ponto_fixo_s.svg'

// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
import SessionController from '../../controller/SessionController'
import * as CidadesAtuaAction from '../../store/models/cidadesatua/actions'
import CidadeAtua from '../../models/CidadeAtua'
import { Form } from 'react-bootstrap'
import CidadeAtuaController from '../../controller/CidadeAtuaController'
import * as ServicoPrestadoActions from '../../store/models/servicosprestados/actions'

type Props = ApplicationState &
  LoginState &
  typeof LoginActions &
  RouteComponentProps<any> &
  typeof CidadesAtuaAction &
  typeof ServicoPrestadoActions
class Menu extends Component<Props> {
  constructor(props: Props) {
    super(props)
    // Vincula funções
    this.logout = this.logout.bind(this)
    this.goHome = this.goHome.bind(this)
    this.goToServicosPrestado = this.goToServicosPrestado.bind(this)
    this.goToParceiro = this.goToParceiro.bind(this)
    this.goToTiposServicos = this.goToTiposServicos.bind(this)
    this.goToFinanceiro = this.goToFinanceiro.bind(this)
    this.goToCupons = this.goToCupons.bind(this)
    this.goToClientes = this.goToClientes.bind(this)
    this.goToTiposVeiculos = this.goToTiposVeiculos.bind(this)
    this.goToProdutos = this.goToProdutos.bind(this)
    this.goToConfiguracoes = this.goToConfiguracoes.bind(this)
    this.handleCarregarCidadeAtua = this.handleCarregarCidadeAtua.bind(this)
    this.renderDropDown = this.renderDropDown.bind(this)
    this.goToPontosFixos = this.goToPontosFixos.bind(this)
    this.handleChangeDropDown = this.handleChangeDropDown.bind(this)
    if (this.props.match.params.cidadeAtua) {
      const cidade = new CidadeAtua()
      cidade.nome = this.props.match.params.cidadeAtua
      this.props.modificarCidadeAtuaSelecionado(cidade)
    }
  }

  render(): JSX.Element {
    return (
      <div>
        {this.renderBarra()}
        {this.renderMenu()}
        <div className="separadorconteudo"></div>
      </div>
    )
  }

  handleChangeDropDown(event: React.ChangeEvent<HTMLSelectElement>): void {
    const cidade = this.props.models.cidadesAtua.data.filter(value => value.nome === event.target.value)
    if (this.props.location.pathname.includes(this.props.models.cidadesAtua.selecionado.nome)) {
      const newUrl =
        `/${cidade[0].nome}` +
        this.props.location.pathname.split('/' + this.props.models.cidadesAtua.selecionado.nome).join('')
      this.props.history.push(newUrl)
    }
    window.location.reload()
  }

  renderDropDown(): JSX.Element {
    const options = this.props.models.cidadesAtua.data.map<JSX.Element>((value, idx) => {
      return (
        <option key={idx} value={value.nome}>
          {value.nome}
        </option>
      )
    })

    return (
      <Form.Group className="dropdown">
        <Form.Control
          onChange={this.handleChangeDropDown}
          as="select"
          value={
            !this.props.models.cidadesAtua.selecionado.nome ? undefined : this.props.models.cidadesAtua.selecionado.nome
          }
        >
          {options}
        </Form.Control>
      </Form.Group>
    )
  }

  async handleCarregarCidadeAtua(): Promise<void> {
    const newCidades = await CidadeAtuaController.pegar()
    this.props.limparCidadesAtua()
    for (const c of newCidades) {
      this.props.adicionarCidadeAtua(c)
    }
    if (newCidades.length > 0) {
      if (this.props.match.params.cidadeAtua) {
        const cidade = newCidades.filter(value => value.nome === this.props.match.params.cidadeAtua)
        this.props.modificarCidadeAtuaSelecionado(cidade[0])
      } else if (this.props.models.cidadesAtua.selecionado.isEmpty) {
        this.props.modificarCidadeAtuaSelecionado(newCidades[0])
      }
    }
  }

  /// ////////////////
  // Funções barra //
  /// ////////////////
  renderBarra(): JSX.Element {
    return (
      <div className="barraMenu">
        <img className="logoMenu" src={logo} alt="Lincar"></img>
        {this.renderDropDown()}
        <div>
          <p>Olá, {this.props.data?.administrador?.nome || 'Sem nome'}!</p>
          <button onClick={this.logout}>(Sair)</button>
        </div>
      </div>
    )
  }

  logout = async () => {
    // Envia comando para firebase
    SessionController.logout()
    // Atualiza props do component com a action logout
    this.props.logoutSuccess()
    // Redireciona para página inicial
    this.props.history.replace('/')
    // Força atualização
    this.forceUpdate()
  }

  /// //////////////////
  // Funções do Menu //
  /// //////////////////
  renderMenu(): JSX.Element {
    // Defini parâmetros para itens não selecionados
    let homeIcon = homeU
    let servIcon = servU
    let parcIcon = parcU
    let tipsicon = tipsU
    let finaIcon = finaU
    let cupoIcon = cupoU
    let cliIcon = clieU
    let prodIcon = prodU
    let tipoVeicIcon = veicU
    let configIcon = configU
    let pontofixoIcon = specificPlaceU

    // Seletores
    let inicialSel = seletorU
    let serviPrestadoSel = seletorU
    let parceirosSel = seletorU
    let tiposervicoSel = seletorU
    let financeiroSel = seletorU
    let cuponsSel = seletorU
    let cliSel = seletorU
    let tipoVeicSel = seletorU
    let prodSel = seletorU
    let configSel = seletorU
    let pontoFixoSel = seletorU

    // Defini parâmetros para itens selecionados
    let inicial,
      serviPrestado,
      parceiros,
      tiposervico,
      financeiro,
      cupons,
      clientes,
      tiposVeiculos,
      produtos,
      configuracoes,
      pontosFixos

    const itemSelecionado = {
      backgroundColor: '#106665',
      color: '#FFFFFF',
    }
    // Home
    if (this.props.location.pathname.includes('/home')) {
      inicial = itemSelecionado
      homeIcon = homeS
      inicialSel = seletorS
    }
    // Serviço Prestado
    if (this.props.location.pathname.includes('/servicosprestados')) {
      serviPrestado = itemSelecionado
      servIcon = servS
      serviPrestadoSel = seletorS
    }
    // Parceiros
    if (this.props.location.pathname.includes('/parceiros')) {
      parceiros = itemSelecionado
      parcIcon = parcS
      parceirosSel = seletorS
    }
    // Tipo Serviço
    if (this.props.location.pathname.includes('/tiposservicos')) {
      tiposervico = itemSelecionado
      tipsicon = tipsS
      tiposervicoSel = seletorS
    }
    // Financeiro
    if (this.props.location.pathname.includes('/financeiro')) {
      financeiro = itemSelecionado
      finaIcon = finaS
      financeiroSel = seletorS
    }
    // Cupons
    if (this.props.location.pathname.includes('/cupons')) {
      cupons = itemSelecionado
      cupoIcon = cupoS
      cuponsSel = seletorS
    }
    // Clientes
    if (this.props.location.pathname.includes('/clientes')) {
      clientes = itemSelecionado
      cliIcon = clieS
      cliSel = seletorS
    }
    // Tipo Veiculo
    if (this.props.location.pathname.includes('/tiposVeiculos')) {
      tiposVeiculos = itemSelecionado
      tipoVeicIcon = veicS
      tipoVeicSel = seletorS
    }

    // Produtos
    if (this.props.location.pathname.includes('/produtos')) {
      produtos = itemSelecionado
      prodIcon = prodS
      prodSel = seletorS
    }

    // Configurações
    if (this.props.location.pathname.includes('/configuracoes')) {
      configuracoes = itemSelecionado
      configIcon = configS
      configSel = seletorS
    }

    // Ponto Fixo Adicionado

    if (this.props.location.pathname.includes('/pontos_fixos')) {
      pontosFixos = itemSelecionado
      pontofixoIcon = specificPlaceS
      pontoFixoSel = seletorS
    }

    // Ponto Fixo

    return (
      <div className="menu">
        <ul>
          <li className="itemMenu" id="inicialBase" style={inicial} onClick={this.goHome}>
            <img src={homeIcon} alt="" className="iconeMenu"></img>
            <div>
              <p style={inicial}>Página inicial</p>
              <img src={inicialSel} alt="" className="seletorMenu"></img>
            </div>
          </li>
          <li className="itemMenu" id="seviPreBase" style={serviPrestado} onClick={this.goToServicosPrestado}>
            <img src={servIcon} alt="" className="iconeMenu"></img>
            <div>
              <p style={serviPrestado}>Serviços prestados</p>
              <img src={serviPrestadoSel} alt="" className="seletorMenu"></img>
            </div>
          </li>
          <li className="itemMenu" id="parcBase" style={parceiros} onClick={this.goToParceiro}>
            <img src={parcIcon} alt="" className="iconeMenu"></img>
            <div>
              <p style={parceiros}>Parceiros</p>
              <img src={parceirosSel} alt="" className="seletorMenu"></img>
            </div>
          </li>
          <li className="itemMenu" id="tipSerBase" style={tiposervico} onClick={this.goToTiposServicos}>
            <img src={tipsicon} alt="" className="iconeMenu"></img>
            <div>
              <p style={tiposervico}>Tipos de serviços</p>
              <img src={tiposervicoSel} alt="" className="seletorMenu"></img>
            </div>
          </li>
          <li className="itemMenu" id="tipSerBase" style={financeiro} onClick={this.goToFinanceiro}>
            <img src={finaIcon} alt="" className="iconeMenu"></img>
            <div>
              <p style={financeiro}>Relatório financeiro</p>
              <img src={financeiroSel} alt="" className="seletorMenu"></img>
            </div>
          </li>
          <li className="itemMenu" id="tipSerBase" style={cupons} onClick={this.goToCupons}>
            <img src={cupoIcon} alt="" className="iconeMenu"></img>
            <div>
              <p style={cupons}>Cupons</p>
              <img src={cuponsSel} alt="" className="seletorMenu"></img>
            </div>
          </li>
          <li className="itemMenu" id="tipSerBase" style={clientes} onClick={this.goToClientes}>
            <img src={cliIcon} alt="" className="iconeMenu"></img>
            <div>
              <p style={clientes}>Clientes</p>
              <img src={cliSel} alt="" className="seletorMenu"></img>
            </div>
          </li>
          <li className="itemMenu" id="tipSerBase" style={tiposVeiculos} onClick={this.goToTiposVeiculos}>
            <img src={tipoVeicIcon} alt="" className="iconeMenu"></img>
            <div>
              <p style={tiposVeiculos}>Tipos Veiculos</p>
              <img src={tipoVeicSel} alt="" className="seletorMenu"></img>
            </div>
          </li>
          <li className="itemMenu" id="tipSerBase" style={produtos} onClick={this.goToProdutos}>
            <img src={prodIcon} alt="" className="iconeMenu"></img>
            <div>
              <p style={produtos}>Produtos</p>
              <img src={prodSel} alt="" className="seletorMenu"></img>
            </div>
          </li>

          <li className="itemMenu" id="tipSerBase" style={configuracoes} onClick={this.goToConfiguracoes}>
            <img src={configIcon} alt="" className="iconeMenu"></img>
            <div>
              <p style={configuracoes}>Configurações</p>
              <img src={configSel} alt="" className="seletorMenu"></img>
            </div>
          </li>

          <li className="itemMenu" id="tipSerBase" style={pontosFixos} onClick={this.goToPontosFixos}>
            <img src={pontofixoIcon} alt="" className="iconeMenu pontofixo"></img>
            <div>
              <p style={pontosFixos}>Pontos Fixos</p>
              <img src={pontoFixoSel} alt="" className="seletorMenu"></img>
            </div>
          </li>
        </ul>
      </div>
    )
  }

  /// //////////////////
  // Funções de rota //
  /// //////////////////
  goHome(): void {
    if (this.props.models.cidadesAtua.selecionado.nome) {
      this.props.history.push(`/${this.props.models.cidadesAtua.selecionado.nome}/home`)
    }
  }

  goToConfiguracoes(): void {
    if (this.props.models.cidadesAtua.selecionado.nome) {
      this.props.history.push(`/${this.props.models.cidadesAtua.selecionado.nome}/configuracoes`)
    }
  }

  goToServicosPrestado(): void {
    if (this.props.models.cidadesAtua.selecionado.nome) {
      this.props.history.push(`/${this.props.models.cidadesAtua.selecionado.nome}/servicosprestados`)
    }
  }

  goToProdutos(): void {
    if (this.props.models.cidadesAtua.selecionado.nome) {
      this.props.history.push(`/${this.props.models.cidadesAtua.selecionado.nome}/produtos`)
    }
  }

  goToParceiro(): void {
    if (this.props.models.cidadesAtua.selecionado.nome) {
      this.props.history.push(`/${this.props.models.cidadesAtua.selecionado.nome}/parceiros`)
    }
  }

  goToTiposServicos(): void {
    if (this.props.models.cidadesAtua.selecionado.nome) {
      this.props.history.push(`/${this.props.models.cidadesAtua.selecionado.nome}/tiposservicos`)
    }
  }

  goToFinanceiro(): void {
    this.props.history.push(`/${this.props.models.cidadesAtua.selecionado.nome}/financeiro`)
  }

  goToCupons(): void {
    if (this.props.models.cidadesAtua.selecionado.nome) {
      this.props.history.push(`/${this.props.models.cidadesAtua.selecionado.nome}/cupons`)
    }
  }

  goToTiposVeiculos(): void {
    if (this.props.models.cidadesAtua.selecionado.nome) {
      this.props.history.push(`/${this.props.models.cidadesAtua.selecionado.nome}/tiposVeiculos`)
    }
  }

  goToClientes(): void {
    if (this.props.models.cidadesAtua.selecionado.nome) {
      this.props.history.push(`/${this.props.models.cidadesAtua.selecionado.nome}/clientes`)
    }
  }

  goToPontosFixos(): void {
    if (this.props.models.cidadesAtua.selecionado.nome) {
      this.props.history.push(`/${this.props.models.cidadesAtua.selecionado.nome}/pontos_fixos`)
    }
  }

  componentDidMount(): void {
    this.handleCarregarCidadeAtua()
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (
  dispatch: Dispatch,
): typeof LoginActions & typeof CidadesAtuaAction & typeof ServicoPrestadoActions =>
  bindActionCreators({ ...LoginActions, ...CidadesAtuaAction, ...ServicoPrestadoActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Menu)

// export default Menu
