import { Reducer } from 'redux'
import { CuponsState, CuponsTypes } from './types'
import Cupom from '../../../models/Cupom'

const initialState: CuponsState = {
  data: [],
  selecionado: new Cupom(),
}

const reducer: Reducer<CuponsState> = (state = initialState, action) => {
  const currentData = state.data

  switch (action.type) {
    case CuponsTypes.ADICIONAR_CUPOM:
      currentData.push(action.payload)
      return { ...state, data: currentData }
    case CuponsTypes.ATUALIZAR_CUPONS:
      return { ...state, data: action.payload.data }
    case CuponsTypes.LIMPAR_CUPONS:
      return { ...state, data: [] }
    case CuponsTypes.MODIFICAR_CUPOM_SELECIONADO:
      return { ...state, selecionado: action.payload }
    case CuponsTypes.REMOVER_CUPOM: {
      const newData = currentData.filter(cupom => cupom.id !== action.payload.id)
      return { ...state, data: newData }
    }
    default:
      return state
  }
}

export default reducer
