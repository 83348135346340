import { firestore } from 'firebase'

export default abstract class BaseDocument<T extends BaseDocument<any>> implements firestore.DocumentSnapshot {
  public exists!: boolean
  public ref!: firestore.DocumentReference
  public id!: string
  public metadata!: firestore.SnapshotMetadata
  public dados!: firestore.DocumentData
  public isEmpty = true

  data(options?: firestore.SnapshotOptions | undefined): firestore.DocumentData | undefined {
    throw new Error('Method not implemented.')
  }

  get(fieldPath: string | firestore.FieldPath, options?: firestore.SnapshotOptions | undefined) {
    throw new Error('Method not implemented.')
  }

  isEqual(other: firestore.DocumentSnapshot): boolean {
    throw new Error('Method not implemented.')
  }

  abstract fillFromJson(json?: any, recursive?: string[]): Promise<T>

  async convert(document: firestore.DocumentSnapshot, recursive?: string[]): Promise<BaseDocument<T> | T> {
    this.isEmpty = false
    this.id = document.id
    this.metadata = document.metadata
    this.ref = document.ref
    this.get = document.get
    this.isEqual = document.isEqual
    this.exists = document.exists
    this.data = document.data
    const dados = document.data()
    if (dados) {
      this.dados = dados
    }
    await this.fillFromJson(undefined, recursive)

    return this
  }
}
