import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import Menu from '../menu/Menu'
import { connect } from 'react-redux'

import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'

import { Form, Button } from 'react-bootstrap'
import Endereco from '../../models/Endereco'
import EnderecoController from '../../controller/EnderecoController'

type Props = ApplicationState & LoginState & typeof LoginActions & RouteComponentProps

interface EnderecoAdicionarState {
  endereco: Endereco
}

class AdicionarPontoFixo extends Component<Props, EnderecoAdicionarState> {
  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    this.state = {
      endereco: new Endereco(),
    }
    this.handleSaveEndereco = this.handleSaveEndereco.bind(this)
    this.goBack = this.goBack.bind(this)
    this.createUFOptions = this.createUFOptions.bind(this)
    this.handleChangeDropDown = this.handleChangeDropDown.bind(this)
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  async handleSaveEndereco(): Promise<void> {
    const endereco = this.state.endereco
    if (
      endereco.apelido &&
      endereco.endereco1 &&
      endereco.endereco2 &&
      endereco.estado &&
      endereco.cidade &&
      endereco.cep &&
      endereco.bairro &&
      endereco.complemento
    ) {
      await EnderecoController.criar(this.state.endereco)
      this.props.history.goBack()
    } else {
      alert('Preencha todos os campos.')
    }
  }

  goBack(): void {
    this.props.history.goBack()
  }

  handleChangeDropDown(event: React.ChangeEvent<HTMLSelectElement>): void {
    const endereco = this.state.endereco
    endereco.estado = event.target.value
    this.setState({ endereco })
  }

  createUFOptions(): JSX.Element[] {
    const uf: JSX.Element[] = [
      { value: 'AC', label: 'Acre' },
      { value: 'AL', label: 'Alagoas' },
      { value: 'AP', label: 'Amapá' },
      { value: 'AM', label: 'Amazonas' },
      { value: 'BA', label: 'Bahia' },
      { value: 'CE', label: 'Ceará' },
      { value: 'DF', label: 'Distrito Federal' },
      { value: 'ES', label: 'Espírito Santo' },
      { value: 'GO', label: 'Goías' },
      { value: 'MA', label: 'Maranhão' },
      { value: 'MT', label: 'Mato Grosso' },
      { value: 'MS', label: 'Mato Grosso do Sul' },
      { value: 'MG', label: 'Minas Gerais' },
      { value: 'PA', label: 'Pará' },
      { value: 'PB', label: 'Paraíba' },
      { value: 'PR', label: 'Paraná' },
      { value: 'PE', label: 'Pernambuco' },
      { value: 'PI', label: 'Piauí' },
      { value: 'RJ', label: 'Rio de Janeiro' },
      { value: 'RN', label: 'Rio Grande do Norte' },
      { value: 'RS', label: 'Rio Grande do Sul' },
      { value: 'RO', label: 'Rondônia' },
      { value: 'RR', label: 'Roraíma' },
      { value: 'SC', label: 'Santa Catarina' },
      { value: 'SP', label: 'São Paulo' },
      { value: 'SE', label: 'Sergipe' },
      { value: 'TO', label: 'Tocantins' },
    ].map(
      (value, idx): JSX.Element => {
        return (
          <option key={idx} value={value.value}>
            {value.label}
          </option>
        )
      },
    )
    return uf
  }

  renderLogged(): JSX.Element {
    // Define estilo dos botões
    const estiloBtnAdicionar = {
      backgroundColor: '#113F4F',
      color: '#FFFFFF',
      borderRadius: '0',
    }
    const estiloBtnCancelar = {
      backgroundColor: '#1B9999',
      color: '#FFFFFF',
      borderRadius: '0',
    }
    const endereco = this.state.endereco
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Adicionar ponto fixo</h1>
          <br />
          <Form className="form">
            <p>Apelido</p>
            <Form.Group controlId="formBasicCodigo">
              <Form.Control
                type="text"
                placeholder="Apelido"
                value={endereco.apelido}
                onChange={(value): void => {
                  endereco.apelido = value.target.value
                }}
              />
            </Form.Group>
            <p>CEP</p>
            <Form.Group controlId="formBasicCodigo">
              <Form.Control
                type="text"
                placeholder="CEP"
                value={endereco.cep || ''}
                onChange={(event): void => {
                  event.preventDefault()
                  const cep = event.target.value.replace('-', '')
                  endereco.cep = cep
                  this.setState({ endereco })
                  if (cep.length === 8) {
                    fetch(`https://viacep.com.br/ws/${cep}/json/`).then(
                      async (value): Promise<void> => {
                        const result = await value.json()
                        const { bairro, localidade, uf, logradouro, complemento, cep } = result
                        endereco.bairro = bairro
                        endereco.cep = cep
                        endereco.cidade = localidade
                        endereco.estado = uf
                        endereco.endereco1 = logradouro
                        endereco.complemento = complemento
                        this.setState({ endereco: endereco })
                      },
                    )
                  }
                }}
              />
            </Form.Group>
            <p>Endereço</p>
            <Form.Group controlId="formBasicCodigo">
              <Form.Control
                value={endereco.endereco1 || ''}
                onChange={(value): void => {
                  endereco.endereco1 = value.target.value
                  this.setState({ endereco })
                }}
                type="text"
                placeholder="Endereço"
              />
            </Form.Group>

            <p>Número</p>
            <Form.Group controlId="formBasicCodigo">
              <Form.Control
                value={endereco.endereco2}
                onChange={(value): void => {
                  endereco.endereco2 = value.target.value
                }}
                type="text"
                placeholder="Número"
              />
            </Form.Group>

            <p>Bairro</p>
            <Form.Group controlId="formBasicCodigo">
              <Form.Control
                type="text"
                placeholder="Bairro"
                value={endereco.bairro || ''}
                onChange={(value): void => {
                  endereco.bairro = value.target.value
                  this.setState({ endereco })
                }}
              />
            </Form.Group>

            <p>Complemento</p>
            <Form.Group controlId="formBasicCodigo">
              <Form.Control
                type="text"
                placeholder="Complemento"
                value={endereco.complemento || ''}
                onChange={(value): void => {
                  endereco.complemento = value.target.value
                  this.setState({ endereco })
                }}
              />
            </Form.Group>

            <p>Cidade</p>
            <Form.Group controlId="formBasicCodigo">
              <Form.Control
                type="text"
                placeholder="Cidade"
                value={endereco.cidade || ''}
                onChange={(value): void => {
                  endereco.cidade = value.target.value
                  this.setState({ endereco })
                }}
              />
            </Form.Group>

            <p>Estado</p>
            <Form.Group className="dropdown">
              <Form.Control as="select" onChange={this.handleChangeDropDown} value={endereco.estado || ''}>
                {this.createUFOptions()}
              </Form.Control>
            </Form.Group>

            <div className="formButtons">
              <Button onClick={this.goBack} style={estiloBtnCancelar}>
                Cancelar
              </Button>
              <Button onClick={this.handleSaveEndereco} style={estiloBtnAdicionar}>
                Adicionar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }

  goToAdicionar(): void {
    this.props.history.push('/:cidadeAtua/cupons/adicionar')
  }

  renderNotLogged(): JSX.Element {
    return (
      <div>
        <h1>Você deve estar logado</h1>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions =>
  bindActionCreators({ ...LoginActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AdicionarPontoFixo)
