import TipoVeiculo from '../models/TipoVeiculo'
import TipoVeiculoRepository from '../repositories/TipoVeiculoRepository'

class TipoVeiculoController {
  pegarTodos(recursive?: string[]): Promise<TipoVeiculo[]> {
    return TipoVeiculoRepository.pegarTodosTiposServicos(recursive)
  }

  pegarPorId(documentId: string, recursive?: string[]): Promise<TipoVeiculo> {
    return TipoVeiculoRepository.pegarPorId(documentId, recursive)
  }

  criar(tipoVeiculo: TipoVeiculo): Promise<TipoVeiculo> {
    return TipoVeiculoRepository.criar(tipoVeiculo)
  }

  alterar(tipoVeiculo: TipoVeiculo): Promise<TipoVeiculo> {
    return TipoVeiculoRepository.alterar(tipoVeiculo)
  }

  deletar(tipoVeiculo: TipoVeiculo): Promise<TipoVeiculo> {
    return TipoVeiculoRepository.deletar(tipoVeiculo)
  }
}

export default new TipoVeiculoController()
