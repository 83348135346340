import TipoServico from '../models/TipoServico'
import TipoServicoRepository from '../repositories/TipoServicoRepository'

class TipoServicoController {
  pegarTodos(cidade: string | string[], recursive?: string[]): AsyncGenerator<TipoServico, void, TipoServico> {
    return TipoServicoRepository.pegarTodosTiposServicos(cidade, recursive)
  }

  pegarTodosDireto(cidade: string | string[], recursive?: string[]): Promise<TipoServico[]> {
    return TipoServicoRepository.pegarTodosTiposServicosDireto(cidade, recursive)
  }

  pegarPorId(documentId: string, recursive?: string[]): Promise<TipoServico> {
    return TipoServicoRepository.pegarPorId(documentId, recursive)
  }

  criar(tipoServico: TipoServico): Promise<TipoServico> {
    return TipoServicoRepository.criar(tipoServico)
  }

  alterar(tipoServico: TipoServico): Promise<TipoServico> {
    return TipoServicoRepository.alterar(tipoServico)
  }

  deletar(tipoServico: TipoServico): Promise<TipoServico> {
    return TipoServicoRepository.deletar(tipoServico)
  }
}

export default new TipoServicoController()
