import { LoginState, LoginTypes } from './types'
import Administrador from '../../../models/Administrador'
import { Reducer } from 'redux'

const initialState: LoginState = {
  data: {
    administrador: new Administrador(''),
    user: undefined,
  },
  isLogged: false,
}

const reducer: Reducer<LoginState> = (state = initialState, action) => {
  switch (action.type) {
    case LoginTypes.LOGIN_REQUEST:
      return { ...state, isLogged: false }
    case LoginTypes.LOGIN_SUCCESS:
      return { ...state, isLogged: true, data: action.payload.data }
    case LoginTypes.LOGIN_FAILURE:
      return { ...state, isLogged: false }
    case LoginTypes.LOGOUT:
      return { ...state, isLogged: false}
    default:
      return state
  }
}

export default reducer
