import { Moment } from 'moment'
import ExtratoPagamento, { TipoFiltroExtratoPagamento } from '../models/ExtratoPagamento'
import Parceiro from '../models/Parceiro'
import ExtratoPagamentoRepository from '../repositories/ExtratoPagamentoRepository'

class ExtratoPagamentoController {
  criar(extratoPagamento: ExtratoPagamento): Promise<ExtratoPagamento> {
    return ExtratoPagamentoRepository.criar(extratoPagamento)
  }

  pegarTodos(recursive?: string[]): AsyncGenerator<ExtratoPagamento, void, ExtratoPagamento> {
    return ExtratoPagamentoRepository.pegarTodos(recursive)
  }

  pegarFiltro(
    tipoFiltro: TipoFiltroExtratoPagamento,
    dataInicio?: Moment,
    dataFim?: Moment,
    parceiro?: Parceiro,
    recursive?: string[],
  ): AsyncGenerator<ExtratoPagamento, void, ExtratoPagamento> {
    return ExtratoPagamentoRepository.pegarFiltro(tipoFiltro, parceiro, dataInicio, dataFim, recursive)
  }

  pegarPorId(documentId: string, recursive?: string[]): Promise<ExtratoPagamento> {
    return ExtratoPagamentoRepository.pegarPorId(documentId, recursive)
  }

  alterar(extratoPagamento: ExtratoPagamento): Promise<ExtratoPagamento> {
    return ExtratoPagamentoRepository.alterar(extratoPagamento)
  }

  deletar(extratoPagamento: ExtratoPagamento): Promise<ExtratoPagamento> {
    return ExtratoPagamentoRepository.deletar(extratoPagamento)
  }
}

export default new ExtratoPagamentoController()
