// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Grid
import { SearchState, IntegratedFiltering, IntegratedPaging, PagingState } from '@devexpress/dx-react-grid'
import { Grid, Table, Toolbar, SearchPanel, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui'
import { ActionColumns, ActionColumn } from '../utils/TableActionButtons'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// Cupom
import * as CuponsActions from '../../store/models/cupons/actions'
import CupomController from '../../controller/CupomController'
// Pega os icones
import Create from '@material-ui/icons/Create'
import Delete from '@material-ui/icons/Delete'
import Cupom from '../../models/Cupom'
import { TipoCupom } from '../../models/model_interfaces'
import { Button, Col, Form, Row } from 'react-bootstrap'
import ConfiguracaoController from '../../controller/ConfiguracaoController'
import { IconButton, TextField } from '@material-ui/core'
import * as CidadesAtuaAction from '../../store/models/cidadesatua/actions'
import CidadeAtua from '../../models/CidadeAtua'
import CidadeAtuaController from '../../controller/CidadeAtuaController'
import { Autocomplete } from '@material-ui/lab'

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState &
  LoginState &
  typeof LoginActions &
  typeof CuponsActions &
  typeof CidadesAtuaAction &
  RouteComponentProps
interface ConfiguracoesState {
  desconto: number
  atualizando: boolean
  todasCidades: string[]
  cidades: string[]
  cidadeSelecionada: string
}

class HomeConfiguracoes extends Component<Props, ConfiguracoesState> {
  private valorCupomIndicacao: React.RefObject<HTMLInputElement>
  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    this.valorCupomIndicacao = React.createRef<HTMLInputElement>()
    this.handleSalvarCupomIndicacao = this.handleSalvarCupomIndicacao.bind(this)
    this.renderCidades = this.renderCidades.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)

    this.state = {
      desconto: 30,
      atualizando: false,
      todasCidades: [],
      cidades: [],
      cidadeSelecionada: '',
    }
  }

  async handleSalvarCupomIndicacao(): Promise<void> {
    const refCupom = this.valorCupomIndicacao.current
    if (!refCupom) return
    try {
      this.setState({
        desconto: refCupom.valueAsNumber,
        atualizando: true,
      })
      await ConfiguracaoController.alterarConfiguracao(refCupom.valueAsNumber)
      alert('Valor alterado com sucesso')
      this.setState({
        atualizando: false,
      })
    } catch (error) {
      console.log(error)
      alert('Ocorreu um erro ao salvar.')
    }
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  async handleDeleteItem(item: CidadeAtua): Promise<void> {
    const cidades = this.props.models.cidadesAtua.data.filter(value => value.ref.id === item.ref.id)
    await CidadeAtuaController.deletar(cidades[0])
    this.props.removerCidadeAtua(cidades[0])
  }

  renderCidades(): JSX.Element[] {
    const options: JSX.Element[] = this.props.models.cidadesAtua.data.map<JSX.Element>((item, idx) => {
      return (
        <Row className="item-extra" key={'item' + idx}>
          <p>{item.nome}</p>{' '}
          <IconButton
            onClick={(): Promise<void> => this.handleDeleteItem(item)}
            size="medium"
            aria-label={'Remover item'}
            style={{ verticalAlign: 'middle' }}
          >
            <Delete></Delete>
          </IconButton>
        </Row>
      )
    })

    return options
  }

  renderLogged(): JSX.Element {
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Configurações</h1>
          <div>
            <div className="form-middle">
              <p>Valor cupom indicação:</p>
              <Form.Group controlId="formaBasicCupom">
                <Form.Control
                  defaultValue={this.state.desconto}
                  ref={this.valorCupomIndicacao}
                  type="number"
                  placeholder="Valor"
                />
              </Form.Group>
              <Button disabled={this.state.atualizando} onClick={this.handleSalvarCupomIndicacao}>
                Alterar
              </Button>
            </div>
            <p style={{ marginTop: 30 }}>Cidade atuante:</p>
            <Col>
              <Row>
                <Autocomplete
                  id="combo-box-demo"
                  options={this.state.cidades}
                  getOptionLabel={option => option}
                  style={{ width: 300, marginRight: 10 }}
                  onChange={(event, value) => {
                    if (value) {
                      this.setState({
                        cidadeSelecionada: value,
                      })
                    }
                  }}
                  noOptionsText="Digite ao menos 3 caracteres"
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Entre com a cidade"
                      onChange={event => {
                        const value = event.currentTarget.value
                        if (value && value.length > 3 && this.state.cidades.length <= 0) {
                          this.setState({
                            cidades: this.state.todasCidades,
                          })
                        }
                        if (value && value.length <= 3 && this.state.cidades.length > 0) {
                          this.setState({
                            cidades: [],
                          })
                        }
                      }}
                      variant="outlined"
                    />
                  )}
                />
                <Button
                  onClick={async () => {
                    const value = this.state.cidadeSelecionada
                    if (value && !this.props.models.cidadesAtua.data.some(v => v.nome === value)) {
                      const newCidade = new CidadeAtua()
                      newCidade.nome = value
                      const c = await CidadeAtuaController.criar(newCidade)
                      this.props.adicionarCidadeAtua(c)
                    }
                  }}
                >
                  Adicionar
                </Button>
              </Row>
              {this.renderCidades()}
            </Col>
          </div>
        </div>
      </div>
    )
  }

  renderNotLogged(): JSX.Element {
    return (
      <div>
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  // Recupera lista Tipo Serviço
  async componentDidMount(): Promise<void> {
    const desconto = await ConfiguracaoController.pegarDescontoCupom()
    const refCupom = this.valorCupomIndicacao.current
    if (refCupom) {
      refCupom.valueAsNumber = desconto
    }
    this.setState({
      desconto,
    })

    fetch('https://servicodados.ibge.gov.br/api/v1/localidades/municipios').then(async value => {
      const result = await value.json()
      const municipios: string[] = []
      for (const municipio of result) {
        municipios.push(`${municipio.nome.toUpperCase()}_${municipio.microrregiao.mesorregiao.UF.sigla.toUpperCase()}`)
      }
      this.setState({
        todasCidades: municipios,
      })
    })
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (
  dispatch: Dispatch,
): typeof LoginActions & typeof CuponsActions & typeof CidadesAtuaAction =>
  bindActionCreators({ ...LoginActions, ...CuponsActions, ...CidadesAtuaAction }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HomeConfiguracoes)
