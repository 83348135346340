import { combineReducers } from 'redux'
import servicosprestados from './servicosprestados'
import tiposservicos from './tiposservicos'
import cupons from './cupons'
import parceiros from './parceiros'
import tiposveiculos from './tiposveiculos'
import produtos from './produtos'
import clientes from './clientes'
import extratospagamento from './extratospagamento'
import cidadesatua from './cidadesatua'
import enderecos from './enderecos'

export default combineReducers({
  servicosPrestados: servicosprestados,
  tiposServicos: tiposservicos,
  cupons: cupons,
  parceiros: parceiros,
  clientes: clientes,
  tiposVeiculos: tiposveiculos,
  produtos: produtos,
  extratosPagamento: extratospagamento,
  cidadesAtua: cidadesatua,
  enderecos: enderecos,
})
