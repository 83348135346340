// React + redux
import React, { Component } from 'react'
import { RouteComponentProps, Route, Switch } from 'react-router-dom'
import TipoServiAdicionar from './Adicionar'
import AlterarTipoServico from './Alterar'
import HomeTipoServico from './Home'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState & LoginState & typeof LoginActions & RouteComponentProps

class TipoServi extends Component<Props> {
  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
  }

  render(): JSX.Element {
    return (
      <Switch>
        <Route exact path="/:cidadeAtua/tiposservicos/" component={HomeTipoServico} />
        <Route path="/:cidadeAtua/tiposservicos/adicionar" component={TipoServiAdicionar} />
        <Route path="/:cidadeAtua/tiposservicos/:id/alterar" component={AlterarTipoServico} />
      </Switch>
    )
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions =>
  bindActionCreators({ ...LoginActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TipoServi)
