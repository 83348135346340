// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// Cliente
import * as ClientesActions from '../../store/models/clientes/actions'
import ClienteController from '../../controller/ClienteController'
import Cliente from '../../models/Cliente'
import { Button } from 'react-bootstrap'

interface ClienteVisualizarState {
  cliente: Cliente
}

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState & LoginState & typeof LoginActions & typeof ClientesActions & RouteComponentProps<any>

class ClienteVisualizar extends Component<Props, ClienteVisualizarState> {
  private validado: React.RefObject<HTMLInputElement>
  private nome: React.RefObject<HTMLInputElement>

  constructor(props: Props) {
    super(props)

    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }

    // Vincula palavra this a função
    this.voltar = this.voltar.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.validado = React.createRef<HTMLInputElement>()
    this.nome = React.createRef<HTMLInputElement>()
    this.state = {
      cliente: this.props.models.clientes.selecionado
    }

    if (
      !this.props.models.clientes ||
      !this.props.models.clientes ||
      this.props.models.clientes.selecionado.isEmpty
    ) {
      if (this.props.match.params.id) {
        this.pegarClienteById(this.props.match.params.id)
      } else {
        this.props.history.replace('/clientes')
      }
    }
  }

  async pegarClienteById(documentId: string): Promise<void> {
    const cliente = await ClienteController.pegarPorId(documentId)
    this.setState({
      cliente: cliente,
    })
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  renderLogged(): JSX.Element {
    if (this.state.cliente.isEmpty) {
      return <div>Carregando...</div>
    }
    
    const estiloBtnSalvar = { backgroundColor: '#113F4F', color: '#FFFFFF', borderRadius: '0' }
    // const estiloBtnCancelar = { backgroundColor: '#1B9999', color: '#FFFFFF', borderRadius: '0' }
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Visualizar Clientes</h1>
          <br />
        <p><span className="formDescricao">Nome:</span> {this.state.cliente.nome}</p>
        <p><span className="formDescricao">Sexo:</span> {this.state.cliente.sexo}</p>
        <p><span className="formDescricao">Data de nascimento:</span> {this.state.cliente.nascimentoFormatado}</p>
        <p><span className="formDescricao">Telefone:</span> {this.state.cliente.telefone}</p>
        <br />
        <div><h3>Endereços:</h3>
        {this.renderEnderecos()}
        </div>
        <div>
          <Button onClick={this.voltar} style={estiloBtnSalvar}>
            Voltar
          </Button>
        </div>
        </div>
      </div>
    )
  }

  renderEnderecos() : JSX.Element[] {
    if(this.state.cliente.isEmpty) {
      return [<div>Carregando...</div>]
    }

    let enderecos: JSX.Element[] = []
    let count = 0
    for(const endereco of this.state.cliente.enderecos) {
      count++
      enderecos.push(<div key={count}>
        <br />
        <p><span className="formDescricao">Apelido:</span> {endereco.apelido}</p>
        <p><span className="formDescricao">Endereço:</span> {endereco.endereco1}</p>
        <p><span className="formDescricao">Número:</span> {endereco.endereco2}</p>
        <p><span className="formDescricao">Bairro:</span> {endereco.bairro}</p>
        <p><span className="formDescricao">Cidade:</span> {endereco.cidade}</p>
        <p><span className="formDescricao">Estado:</span> {endereco.estado}</p>
      </div>)
    }

    return enderecos
  }

  handleChange(evt: React.ChangeEvent<HTMLInputElement>): void {
    const cliente = this.state.cliente
    this.setState({ cliente: cliente })
  }

  async voltar(): Promise<void> {
    this.props.history.goBack()
  }

  renderNotLogged(): JSX.Element {
    return (
      <div className="Home">
        <h1>Você deve estar logado</h1>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof ClientesActions =>
  bindActionCreators({ ...LoginActions, ...ClientesActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ClienteVisualizar)
