import { ServicosPrestadosState, ServicosPrestadosTypes } from './types'
import { Reducer } from 'redux'
import ServicoPrestado from '../../../models/ServicoPrestado'

const initialState: ServicosPrestadosState = {
  data: [],
  selecionado: new ServicoPrestado(),
  listaCarregada: false,
}

const reducer: Reducer<ServicosPrestadosState> = (state = initialState, action) => {
  const currentData = state.data
  switch (action.type) {
    case ServicosPrestadosTypes.ADICIONAR_SERVICO_PRESTADO: {
      const newData = currentData.filter(p => p.id !== action.payload.id)
      newData.push(action.payload)
      return { ...state, data: newData }
    }
    case ServicosPrestadosTypes.ATUALIZAR_TODOS_SERVICOS:
      return { ...state, data: action.payload.data }
    case ServicosPrestadosTypes.MODIFICAR_SERVICO_PRESTADO_SELECIONADO:
      return { ...state, selecionado: action.payload }
    case ServicosPrestadosTypes.MODIFICAR_LISTA_CARREGADA:
      return { ...state, listaCarregada: action.payload }
    default:
      return state
  }
}

export default reducer
