import { action } from 'typesafe-actions'
import {
  CidadesAtuaState,
  CidadesAtuaTypes,
  AtualizarCidadesAtua,
  AdicionarCidadeAtua,
  LimparCidadesAtua,
  ModificarCidadeAtuaSelecionado,
  ModificarListaCarregadaCidadesAtua,
  RemoverCidadeAtua,
} from './types'
import CidadeAtua from '../../../models/CidadeAtua'
export const atualizarCidadesAtua = (data: CidadesAtuaState): AtualizarCidadesAtua =>
  action(CidadesAtuaTypes.ATUALIZAR_CIDADES_ATUA, data)

export const adicionarCidadeAtua = (newCidadeAtua: CidadeAtua): AdicionarCidadeAtua => {
  return action(CidadesAtuaTypes.ADICIONAR_CIDADE_ATUA, newCidadeAtua)
}

export const removerCidadeAtua = (newCidadeAtua: CidadeAtua): RemoverCidadeAtua => {
  return action(CidadesAtuaTypes.REMOVER_CIDADE_ATUA, newCidadeAtua)
}

export const limparCidadesAtua = (): LimparCidadesAtua => {
  return action(CidadesAtuaTypes.LIMPAR_CIDADES_ATUA)
}

export const modificarCidadeAtuaSelecionado = (newCidadeAtua: CidadeAtua): ModificarCidadeAtuaSelecionado => {
  return action(CidadesAtuaTypes.MODIFICAR_CIDADE_ATUA_SELECIONADO, newCidadeAtua)
}

export const modificarListaCarregadaCidadesAtua = (estaCarregada: boolean): ModificarListaCarregadaCidadesAtua => {
  return action(CidadesAtuaTypes.MODIFICAR_LISTA_CARREGADA, estaCarregada)
}
