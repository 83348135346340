import FirebaseManager from '../utils/FirebaseManager'
import Disponibilidade from '../models/Disponibilidade'
import Parceiro from '../models/Parceiro'
class DisponibilidadeRepository {
  async pegarTodasDisponibilidades(): Promise<Array<Disponibilidade>> {
    const list: Disponibilidade[] = []
    const query = await FirebaseManager.database.collection('disponibilidades').get()
    for (const doc of query.docs) {
      const disponibilidade = await new Disponibilidade().convert(doc)
      if (disponibilidade instanceof Disponibilidade) {
        list.push(disponibilidade)
      }
    }
    return list
  }

  async pegarDisponibilidadePorParceiro(parceiro: Parceiro): Promise<Array<Disponibilidade>> {
    const list: Disponibilidade[] = []
    let ref = parceiro.pparceiro
    if (!ref) {
      ref = parceiro.ref
    }
    const query = await FirebaseManager.database
      .collection('disponibilidades')
      .where('parceiroRef', '==', ref)
      .get()
    for (const doc of query.docs) {
      const disponibilidade = await new Disponibilidade().convert(doc)
      if (disponibilidade instanceof Disponibilidade) {
        list.push(disponibilidade)
      }
    }
    return list
  }
}

export default new DisponibilidadeRepository()
