import FirebaseManager from '../utils/FirebaseManager'
import Cliente from '../models/Cliente'

class ClienteRepository {
  async alterar(cliente: Cliente): Promise<Cliente> {
    const document: firebase.firestore.DocumentReference = cliente.ref
    const map = {}

    const user: firebase.firestore.DocumentReference = FirebaseManager.database.collection('usuarios').doc(cliente.uid)

    const batch = FirebaseManager.database.batch()
    batch.update(user, {})
    batch.update(document, map)
    await batch.commit()
    cliente.ref = document
    return cliente
  }

  async deletar(cliente: Cliente): Promise<Cliente> {
    const batch = FirebaseManager.database.batch()
    const user: firebase.firestore.DocumentSnapshot = await FirebaseManager.database
      .collection('private')
      .doc(cliente.uid)
      .get()

    batch.update(user.ref, {
      validado: false,
    })

    batch.update(cliente.ref, {
      validado: false,
    })

    await batch.commit()
    return cliente
  }

  async pegarInformacoesCliente(cliente: Cliente): Promise<Cliente> {
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('usuarios')
      .where('publicUid.clientes', '==', cliente.pid)
      .get()
    const usuarioId = query.docs[0].id
    const queryPrivate: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('private')
      .doc(usuarioId)
      .collection('cliente')
      .get()
    const clienteDoc = queryPrivate.docs[0].data()
    await cliente.fillFromJson(clienteDoc)
    return cliente
  }

  async pegarQuantidadeDeClientes(): Promise<number> {
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('usuarios')
      .where('role', '==', 200)
      .get()

    return query.docs.length
  }

  async *pegarTodos(recursive?: string[]): AsyncGenerator<Cliente, void, Cliente> {
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('usuarios')
      .where('role', '==', 200)
      .get()
    const queryDocsSnapshot = query.docs
    if (queryDocsSnapshot) {
      for (const doc of queryDocsSnapshot) {
        const queryCliente: firebase.firestore.QuerySnapshot = await FirebaseManager.database
          .collection('private')
          .doc(doc.id)
          .collection('cliente')
          .get()

        const clienteDoc = queryCliente.docs[0]
        const cliente = await new Cliente().convert(clienteDoc, recursive)

        if (cliente instanceof Cliente) {
          cliente.uid = doc.id
          cliente.email = doc.data().email

          yield cliente
        }
      }
    }
  }

  async pegarPorId(documentId: string, recursive?: string[]): Promise<Cliente> {
    const queryCliente: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('private')
      .doc(documentId)
      .collection('cliente')
      .get()

    const clienteDoc = queryCliente.docs[0]

    const cliente = await new Cliente().convert(clienteDoc, recursive)

    if (cliente instanceof Cliente) {
      cliente.uid = documentId

      return cliente
    }
    return new Cliente()
  }

  async *pegarTodosClienteUid(): AsyncGenerator<string, void, string> {
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('usuarios')
      .where('role', '==', 200)
      .get()
    const queryDocsSnapshot = query.docs
    if (queryDocsSnapshot) {
      const user = queryDocsSnapshot[0].data()
      if (user) {
        yield user.renderServicosPorVeiculo
      }
    }
  }
}

export default new ClienteRepository()
