import ExtratoPagamento from '../../../models/ExtratoPagamento'

export enum ExtratosPagamentoTypes {
  ATUALIZAR_EXTRATOS_PAGAMENTO = '@extratosPagamento/ATUALIZAR_TODOS',
  ADICIONAR_EXTRATO_PAGAMENTO = '@extratosPagamento/ADICIONAR',
  REMOVER_EXTRATO_PAGAMENTO = '@extratosPagamento/REMOVER',
  MODIFICAR_EXTRATO_PAGAMENTO_SELECIONADO = '@extratosPagamento/MODIFICAR_SELECIONADO',
  LIMPAR_EXTRATOS_PAGAMENTO = '@extratosPagamento/LIMPAR',
  MODIFICAR_LISTA_CARREGADA = '@extratosPagamento/MODIFICAR_LISTA_CARREGADA',
}

export interface ExtratosPagamentoState {
  readonly data: ExtratoPagamento[]
  readonly selecionado: ExtratoPagamento
  readonly listaCarregada: boolean
}

export interface AtualizarExtratosPagamento {
  type: typeof ExtratosPagamentoTypes.ATUALIZAR_EXTRATOS_PAGAMENTO
}

export interface AdicionarExtratoPagamento {
  type: typeof ExtratosPagamentoTypes.ADICIONAR_EXTRATO_PAGAMENTO
}

export interface RemoverExtratoPagamento {
  type: typeof ExtratosPagamentoTypes.REMOVER_EXTRATO_PAGAMENTO
}

export interface LimparExtratosPagamento {
  type: typeof ExtratosPagamentoTypes.LIMPAR_EXTRATOS_PAGAMENTO
}

export interface ModificarExtratoPagamentoSelecionado {
  type: typeof ExtratosPagamentoTypes.MODIFICAR_EXTRATO_PAGAMENTO_SELECIONADO
}

export interface ModificarListaCarregadaExtratoPagamento {
  type: typeof ExtratosPagamentoTypes.MODIFICAR_LISTA_CARREGADA
}
