import React, { Component } from 'react'
import { RouteComponentProps, Switch, Route } from 'react-router-dom'

import HomePontosFixos from './Home'
import AdicionarPontoFixo from './Adicionar'
import AlterarPontoFixo from './Alterar'

import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'

type Props = ApplicationState & LoginState & typeof LoginActions & RouteComponentProps

interface ClienteState {
  quantidade: number
}

class PontosFixos extends Component<Props, ClienteState> {
  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
  }

  render(): JSX.Element {
    return (
      <Switch>
        <Route exact path="/:cidadeAtua/pontos_fixos/" component={HomePontosFixos} />
        <Route path="/:cidadeAtua/pontos_fixos/adicionar" component={AdicionarPontoFixo} />
        <Route path="/:cidadeAtua/pontos_fixos/:id/alterar" component={AlterarPontoFixo} />
      </Switch>
    )
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions =>
  bindActionCreators({ ...LoginActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PontosFixos)
