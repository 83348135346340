import Endereco from '../models/Endereco'
import EnderecoRepository from '../repositories/EnderecoRepository'

class EnderecoController {
  pegarTodos(recursive?: string[]): AsyncGenerator<Endereco, void, Endereco> {
    return EnderecoRepository.pegarTodos(recursive)
  }

  criar(endereco: Endereco): Promise<Endereco> {
    return EnderecoRepository.criar(endereco)
  }

  pegarPorId(documentId: string): Promise<Endereco> {
    return EnderecoRepository.pegarPorId(documentId)
  }

  alterar(endereco: Endereco): Promise<Endereco> {
    return EnderecoRepository.alterar(endereco)
  }

  deletar(endereco: Endereco): Promise<Endereco> {
    return EnderecoRepository.deletar(endereco)
  }
}

export default new EnderecoController()
