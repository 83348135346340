import FirebaseManager from '../utils/FirebaseManager'
import Produto from '../models/Produto'

class ProdutoRepository {
  async criar(produto: Produto): Promise<Produto> {
    const document: firebase.firestore.DocumentReference = FirebaseManager.database.collection('produtos').doc()

    const map = {
      nome: produto.nome,
      descricao: produto.descricao,
      validade: produto.validade.format(),
      quantidade: produto.quantidade,
      preco: produto.preco,
      ativo: true,
    }
    await document.set(map)
    produto.ref = document
    return produto
  }

  async alterar(produto: Produto): Promise<Produto> {
    const document: firebase.firestore.DocumentReference = produto.ref
    const map = {
      nome: produto.nome,
      descricao: produto.descricao,
      validade: produto.validade.format(),
      quantidade: produto.quantidade,
      preco: produto.preco,
      ativo: true,
    }

    await document.update(map)
    produto.ref = document
    return produto
  }

  async deletar(produto: Produto): Promise<Produto> {
    await produto.ref.update({
      ativo: false,
    })
    return produto
  }

  async pegarPorId(documentId: string, recursive?: string[]): Promise<Produto> {
    const reference: firebase.firestore.DocumentReference = await FirebaseManager.database
      .collection('produtos')
      .doc(documentId)
    const doc = await reference.get()
    if (doc.exists) {
      const produto = await new Produto().convert(doc, recursive)

      if (produto instanceof Produto) {
        return produto
      }
    }

    return new Produto()
  }

  async *pegarTodos(recursive?: string[]): AsyncGenerator<Produto, void, Produto> {
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('produtos')
      .where('ativo', '==', true)
      .get()
    const queryDocsSnapshot = query.docs
    if (queryDocsSnapshot) {
      for (const doc of queryDocsSnapshot) {
        const produto = await new Produto().convert(doc, recursive)

        if (produto instanceof Produto) {
          yield produto
        }
      }
    }
  }
}

export default new ProdutoRepository()
