import IuguCommon from './common'
import { IuguPaymentToken } from '../models'

class IuguPaymentsTokens extends IuguCommon<IuguPaymentToken> {
  get routeName(): string {
    return 'payment_token'
  }
}

export default new IuguPaymentsTokens()
