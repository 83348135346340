// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// CSS
import './Home.css'
import logo from '../imagens/home_logo.svg'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
import { Form } from 'react-bootstrap'
import CidadeAtua from '../../models/CidadeAtua'
import CidadeAtuaController from '../../controller/CidadeAtuaController'
import * as CidadesAtuaAction from '../../store/models/cidadesatua/actions'

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState & LoginState & typeof LoginActions & RouteComponentProps & typeof CidadesAtuaAction

class Home extends Component<Props> {
  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  renderLogged(): JSX.Element {
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <img id="logoHome" src={logo} alt="Lincar"></img>
        </div>
      </div>
    )
  }

  renderNotLogged(): JSX.Element {
    return (
      <div>
        <h1>Você deve estar logado</h1>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof CidadesAtuaAction =>
  bindActionCreators({ ...LoginActions, ...CidadesAtuaAction }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Home)
