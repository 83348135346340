// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Grid
import { SearchState, IntegratedFiltering, IntegratedPaging, PagingState } from '@devexpress/dx-react-grid'
import { Grid, Table, Toolbar, SearchPanel, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui'
import { ActionColumns, ActionColumn } from '../utils/TableActionButtons'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// Cupom
import * as CuponsActions from '../../store/models/cupons/actions'
import CupomController from '../../controller/CupomController'
// Pega os icones
import Create from '@material-ui/icons/Create'
import Delete from '@material-ui/icons/Delete'
import Cupom from '../../models/Cupom'
import { TipoCupom } from '../../models/model_interfaces'

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState & LoginState & typeof LoginActions & typeof CuponsActions & RouteComponentProps

// Determinar cabecalho do grid
const cabecalho = [
  { name: 'alterar' },
  { name: 'excluir' },
  { name: 'codigo', title: 'Código' },
  { name: 'desconto', title: 'Desconto' },
  { name: 'tipoCupom', title: 'Tipo de Cupom' },
  { name: 'validade', title: 'Validade' },
  { name: 'restante', title: 'restante' },
]

interface RowCupom {
  codigo: string
  restante: number
  tipoCupom: string
  validade: string
  cupom: Cupom
  desconto: string
}

// Valores padrão no grid
const tableMessages = {
  noData: 'Sem registros',
}
const searchMessages = {
  searchPlaceholder: 'Pesquisar',
}

class HomeCupons extends Component<Props> {
  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    // Vincula palavra this a função
    this.goToAdicionar = this.goToAdicionar.bind(this)
    this.handleClickAlterar = this.handleClickAlterar.bind(this)
    this.handleClickExcluir = this.handleClickExcluir.bind(this)
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  renderLogged(): JSX.Element {
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Cadastro de Cupom</h1>
          <button onClick={this.goToAdicionar} className="botaoCrud">
            Novo Cupom
          </button>
          {this.renderGrid()}
        </div>
      </div>
    )
  }

  goToAdicionar(): void {
    this.props.history.push('cupons/adicionar')
  }

  renderNotLogged(): JSX.Element {
    return (
      <div>
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  // Recupera lista Tipo Serviço
  async componentDidMount(): Promise<void> {
    let done: boolean | undefined = false
    this.props.limparCupons()
    const asyncGeneratorCupom = CupomController.pegarTodos()
    while (!done) {
      const iteratorResult = await asyncGeneratorCupom.next()
      if (iteratorResult.value) {
        this.props.adicionarCupom(iteratorResult.value)
      }
      done = iteratorResult.done
    }
  }

  private handleClickAlterar(row: RowCupom): void {
    this.props.modificarCupomSelecionado(row.cupom)
    this.props.history.push('cupons/' + row.cupom.id + '/alterar')
  }

  private handleClickExcluir(row: RowCupom): void {
    CupomController.deletar(row.cupom)
    this.props.removerCupom(row.cupom)
  }

  renderGrid(): JSX.Element {
    // Busca serviços e estrutura registros
    const listCupons = this.props.models.cupons ? this.props.models.cupons.data : []
    let tuplas: readonly RowCupom[] = []
    if (listCupons) {
      tuplas = listCupons.map(cupom => {
        return {
          codigo: cupom.codigo,
          desconto:
            cupom.tipoCupom === TipoCupom.PORCENTAGEM
              ? cupom.desconto.toString() + '%'
              : cupom.desconto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
          restante: cupom.restante,
          validade: cupom.validade.toDate().toLocaleDateString(),
          tipoCupom: cupom.tipoCupom === TipoCupom.PORCENTAGEM ? 'Porcentagem' : 'Valor',
          cupom: cupom,
        }
      })
    }
    const actionColumns: ActionColumn[] = [
      { columnName: 'alterar', label: 'Abrir modo edição', onClick: this.handleClickAlterar, icon: <Create /> },
      { columnName: 'excluir', label: 'Excluir linha', onClick: this.handleClickExcluir, icon: <Delete /> },
    ]
    // Monta Grid
    return (
      <div className="grid">
        <Grid rows={tuplas} columns={cabecalho}>
          <PagingState defaultCurrentPage={0} defaultPageSize={5} />
          <SearchState defaultValue="" />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table messages={tableMessages} />
          <TableHeaderRow />
          <Toolbar />
          <SearchPanel messages={searchMessages} />
          <ActionColumns actionColumns={actionColumns} />
          <PagingPanel
            pageSizes={[5, 10, 20]}
            messages={{
              showAll: 'Mostrar todos',
              rowsPerPage: 'Linhas por página',
              info: (parameters: { from: number; to: number; count: number }): string =>
                parameters.from + '-' + parameters.to + ' de ' + parameters.count,
            }}
          />
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof CuponsActions =>
  bindActionCreators({ ...LoginActions, ...CuponsActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HomeCupons)
