import FirebaseManager from '../utils/FirebaseManager'

class SessionRepository {
  public async login(email: string, senha: string): Promise<firebase.auth.UserCredential> {
    return FirebaseManager.auth.signInWithEmailAndPassword(email, senha)
  }

  public async getLoggedUser(): Promise<firebase.User | undefined> {
    let user: firebase.User | undefined
    if (FirebaseManager.auth.currentUser != null) {
      user = FirebaseManager.auth.currentUser
    }
    return user
  }

  public async getParceiroUid(uid: string): Promise<string> {
    const ref: firebase.firestore.DocumentReference = await FirebaseManager.database.collection('usuarios').doc(uid)
    const data = await ref.get()
    return data.data()?.publicUid.servicosprestados || ''
  }

  // Faz chamada para logout para firebase
  public logout() {
    return FirebaseManager.auth.signOut()
  }
}

export default new SessionRepository()
