import { action } from 'typesafe-actions'
import {
  ExtratosPagamentoState,
  ExtratosPagamentoTypes,
  AtualizarExtratosPagamento,
  AdicionarExtratoPagamento,
  LimparExtratosPagamento,
  ModificarExtratoPagamentoSelecionado,
  ModificarListaCarregadaExtratoPagamento,
  RemoverExtratoPagamento,
} from './types'
import ExtratoPagamento from '../../../models/ExtratoPagamento'
export const atualizarExtratosPagamento = (data: ExtratosPagamentoState): AtualizarExtratosPagamento =>
  action(ExtratosPagamentoTypes.ATUALIZAR_EXTRATOS_PAGAMENTO, data)

export const adicionarExtratoPagamento = (newExtratoPagamento: ExtratoPagamento): AdicionarExtratoPagamento => {
  return action(ExtratosPagamentoTypes.ADICIONAR_EXTRATO_PAGAMENTO, newExtratoPagamento)
}

export const removerExtratoPagamento = (newExtratoPagamento: ExtratoPagamento): RemoverExtratoPagamento => {
  return action(ExtratosPagamentoTypes.REMOVER_EXTRATO_PAGAMENTO, newExtratoPagamento)
}

export const limparExtratosPagamento = (): LimparExtratosPagamento => {
  return action(ExtratosPagamentoTypes.LIMPAR_EXTRATOS_PAGAMENTO)
}

export const modificarExtratoPagamentoSelecionado = (
  newExtratoPagamento: ExtratoPagamento,
): ModificarExtratoPagamentoSelecionado => {
  return action(ExtratosPagamentoTypes.MODIFICAR_EXTRATO_PAGAMENTO_SELECIONADO, newExtratoPagamento)
}

export const modificarListaCarregadaExtratosPagamento = (
  estaCarregada: boolean,
): ModificarListaCarregadaExtratoPagamento => {
  return action(ExtratosPagamentoTypes.MODIFICAR_LISTA_CARREGADA, estaCarregada)
}
