import ConfiguracaoRepository from '../repositories/ConfiguracaoRepository'
import FirebaseManager from '../utils/FirebaseManager'
import iugu from '../utils/iugu'

class ConfiguracaoController {
  pegarDescontoCupom(): Promise<number> {
    return ConfiguracaoRepository.pegarCupom()
  }

  async alterarConfiguracao(desconto: number): Promise<void> {
    const idToken: string = (await FirebaseManager.auth.currentUser?.getIdToken()) || ''
    iugu.setToken(idToken)
    return iugu.cupom.upgrade({ desconto })
  }
}

export default new ConfiguracaoController()
