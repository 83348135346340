// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

// Une todos em props antes de passar ao ao componente
type Props = RouteComponentProps

export class Club extends Component<Props> {
  private navCollapse: React.RefObject<HTMLDivElement>
  constructor(props: Props) {
    super(props)
    this.navCollapse = React.createRef<HTMLDivElement>()
    this.handleNavToggle = this.handleNavToggle.bind(this)
  }

  handleNavToggle(): void {
    const current = this.navCollapse.current
    if (current) {
      if (current.className.includes('show')) {
        current.className = current.className.replace('show', '')
      } else {
        current.className = current.className + ' show'
      }
    }
  }

  render(): JSX.Element {
    return (
      <div id="page-top">
        <nav className="navbar navbar-expand-lg bg-secondary fixed-top" id="mainNav">
          <div className="container">
            <a className="navbar-brand js-scroll-trigger" href="#page-top">
              <img src={process.env.PUBLIC_URL + '/assets/img/logo_icon.png'} alt="Símbolo da empresa Lincar" />
              <img src={process.env.PUBLIC_URL + '/assets/img/logo_nome.png'} alt="Lincar" />
            </a>
            <button
              className="navbar-toggler navbar-toggler-right font-weight-bold bg-primary text-white rounded"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              onClick={this.handleNavToggle}
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              Menu
              <FontAwesomeIcon icon={faBars} size="lg" color="white" style={{ marginLeft: 10 }} />
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive" ref={this.navCollapse}>
              <ul className="navbar-nav ml-auto">
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger" href="servicos">
                    Serviços
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger nav-item-selected" href="club">
                    Lincar Club
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger" href="faleconosco">
                    Fale conosco
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger seja" href="seja-parceiro">
                    Seja Parceiro
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <br />
        <br />
        <br />
        <section className="faixaclube">
          <br />
          <br />
          <br />
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="imagem"
                  src={process.env.PUBLIC_URL + '/assets/img/lincar_club.png'}
                  alt=""
                  style={{ padding: 10 }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="blocoassinatura">
          <br />
          <br />
          <div className="container">
            <div className="row">
              <div className="col-lg-8 ml-auto">
                <h1 className="tituloclube">A assinatura que seu carro precisa!</h1>
                <div className="subtituloclube">
                  Mude a forma como você se relaciona com o seu carro. Sem se preocupar, e com toda comodidade, seu
                  carro estará sempre limpo e você garantirá todos os benefícios de ser um assinante LinCar. Faça já seu
                  orçamento, diretamente no App, e desfrute de descontos exclusivos!
                </div>
              </div>
              <div className="col-lg-4 mr-auto">
                <img className="assinante" src={process.env.PUBLIC_URL + '/assets/img/Assinante.png'} alt="" />
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
        <br />
        <section>
          <div className="container" style={{ width: '85%' }}>
            <div className="row">
              <div>
                <p className="benebene">VANTAGENS</p>
                <p className="benetitulo">Veja os benefícios de ser um assinante</p>
              </div>
            </div>
          </div>
        </section>
        <section className="page-section portfolio" id="portfolio">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="mx-auto benecaixa" style={{ height: 300 }}>
                  <br />
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/Estrela.png'} alt="" />
                  <p className="beneclube">Sem taxa de adesão e Cancelamento</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="mx-auto benecaixa" style={{ height: 300 }}>
                  <br />
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/Estrela.png'} alt="" />
                  <p className="beneclube">15% de desconto no valor oficial das lavagens</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="mx-auto benecaixa" style={{ height: 300 }}>
                  <br />
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/Estrela.png'} alt="" />
                  <p className="beneclube">15% de desconto em cima de todos os serviços</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="mx-auto benecaixa" style={{ height: 300 }}>
                  <br />
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/Estrela.png'} alt="" />
                  <p className="beneclube">Promoções Exclusivas</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="mx-auto benecaixa" style={{ height: 300 }}>
                  <br />
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/Estrela.png'} alt="" />
                  <p className="beneclube">
                    Descontos Exclusivos em todos Parceiros Lincar Club: Alinhamento, Balanceamento, Borracharia,
                    Chaveiro, Auto Peças, Auto Eletrica, Martelinho de ouro, lanternagem, entre outros
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-5">
                <div className="mx-auto benecaixa" style={{ height: 300 }}>
                  <br />
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/Estrela.png'} alt="" />
                  <p className="beneclube">
                    Sistema de pontuação relacionada a água economizada em cada lavagem para ser trocada por serviços
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 mb-5 mb-lg-0">
                <img
                  src={process.env.PUBLIC_URL + '/assets/img/logo_icon_nome_branco.png'}
                  alt="Símbolo da empresa Lincar"
                />
              </div>
              <div className="col-lg-3 mb-5 mb-lg-0">
                <ul className="linkrodape">
                  <li>
                    <a href="index">Home</a>
                  </li>
                  <li>
                    <a href="servicos">Serviços</a>
                  </li>
                  <li>
                    <a href="club">Lincar Club</a>
                  </li>
                  <li>
                    <a href="faleconosco">Fale conosco</a>
                  </li>
                  <li>
                    <a href="seja-parceiro" style={{ fontWeight: 'bold' }}>
                      Seja Parceiro
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-7">
                <p className="mb-0 endereco">Travessa Julio Feydit, 19 Lapa / Campos dos Goytacazes Cep:28010-180</p>
              </div>
            </div>
          </div>
        </footer>
        <section className="page-section">
          <div className="container">
            <div className="row" style={{ paddingTop: 20, textAlign: 'center' }}>
              <div className="col-md-6 col-lg-4 mb-5" style={{ paddingTop: 10 }}>
                <img src={process.env.PUBLIC_URL + '/assets/img/logo_teccampos.png'} />
              </div>
              <div className="col-md-6 col-lg-4 mb-5" style={{ textAlign: 'center' }}>
                <img src={process.env.PUBLIC_URL + '/assets/img/logo_embrapii.png'} />
              </div>
              <div className="col-md-6 col-lg-4 mb-5" style={{ textAlign: 'center' }}>
                <img src={process.env.PUBLIC_URL + '/assets/img/logo_fundecam.png'} width="120px" />
              </div>
            </div>
          </div>
        </section>
        <div className="scroll-to-top d-lg-none position-fixed">
          <a className="js-scroll-trigger d-block text-center text-white rounded" href="#page-top">
            <i className="fa fa-chevron-up"></i>
          </a>
        </div>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.bundle.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js"></script>
        <script src={process.env.PUBLIC_URL + '/scripts.js'}></script>
      </div>
    )
  }
}
