import { action } from 'typesafe-actions'
import {
  ProdutosState,
  ProdutosTypes,
  AtualizarProdutos,
  AdicionarProduto,
  LimparProdutos,
  ModificarProdutoSelecionado,
  ModificarListaCarregadaProdutos,
  RemoverProduto,
} from './types'
import Produto from '../../../models/Produto'
export const atualizarProdutos = (data: ProdutosState): AtualizarProdutos =>
  action(ProdutosTypes.ATUALIZAR_PRODUTOS, data)

export const adicionarProduto = (newProduto: Produto): AdicionarProduto => {
  return action(ProdutosTypes.ADICIONAR_PRODUTO, newProduto)
}

export const removerProduto = (newProduto: Produto): RemoverProduto => {
  return action(ProdutosTypes.REMOVER_PRODUTO, newProduto)
}

export const limparProdutos = (): LimparProdutos => {
  return action(ProdutosTypes.LIMPAR_PRODUTOS)
}

export const modificarProdutoSelecionado = (newProduto: Produto): ModificarProdutoSelecionado => {
  return action(ProdutosTypes.MODIFICAR_PRODUTO_SELECIONADO, newProduto)
}

export const modificarListaCarregadaProdutos = (estaCarregada: boolean): ModificarListaCarregadaProdutos => {
  return action(ProdutosTypes.MODIFICAR_LISTA_CARREGADA, estaCarregada)
}
