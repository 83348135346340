import React, { Component } from 'react'
import { ApplicationState } from '../../store'
import * as ExtratosPagamentoActions from '../../store/models/extratospagamento/actions'
import * as LoginActions from '../../store/administrador/login/actions'
import * as ParceirosActions from '../../store/models/parceiros/actions'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { LoginState } from '../../store/administrador/login/types'
import { RouteComponentProps } from 'react-router-dom'
import ExtratoPagamentoController from '../../controller/ExtratoPagamentoController'
import ServicoPrestado from '../../models/ServicoPrestado'
import { ItemExtra } from '../../models/model_interfaces'
import ExtratoPagamento from '../../models/ExtratoPagamento'
import Parceiro from '../../models/Parceiro'
import * as CidadesAtuaAction from '../../store/models/cidadesatua/actions'

type Props = ApplicationState &
  LoginState &
  typeof LoginActions &
  typeof ParceirosActions &
  typeof CidadesAtuaAction &
  RouteComponentProps<any> &
  typeof ExtratosPagamentoActions

interface ExtratoPagamentoState {
  parceiro: Parceiro
  extratoPagamento: ExtratoPagamento
  dataInicio: Date
  dataFim: Date
  dataPagamento?: Date | null
  servicosPrestados: ServicoPrestado[]
  recebimentosExtras: ItemExtra[]
  descontosExtras: ItemExtra[]
  carregando: boolean
  taxaCartao: number
  posParceiro: number
  valorBruto: number
  valorLiquido: number
  pago: boolean
  dadosCarregados: boolean
}
class PaginaPDF extends Component<Props, ExtratoPagamentoState> {
  constructor(props: Props) {
    super(props)

    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    if (!this.props.models.extratosPagamento || this.props.models.extratosPagamento.selecionado.isEmpty) {
      if (this.props.match.params.id) {
        this.pegarExtratoPagamentoById(this.props.match.params.id)
      } else {
        this.props.history.replace('/financeiro')
      }
    }

    this.state = {
      posParceiro: -1,
      parceiro: new Parceiro(),
      extratoPagamento: this.props.models.extratosPagamento.selecionado,
      dataInicio: new Date(),
      dataFim: new Date(),
      carregando: false,
      taxaCartao: 2.51,
      servicosPrestados: [],
      descontosExtras: [],
      recebimentosExtras: [],
      pago: false,
      valorBruto: 0,
      valorLiquido: 0,
      dataPagamento: null,
      dadosCarregados: false,
    }
  }

  async pegarExtratoPagamentoById(documentId: string): Promise<void> {
    this.setState({
      carregando: true,
    })
    const cupom = await ExtratoPagamentoController.pegarPorId(documentId, [
      'carrinhoCompras',
      'cliente',
      'tipoServico',
      'servicosCarregados',
      'parceiro',
      'situacaoInicial',
    ])
    this.props.modificarExtratoPagamentoSelecionado(cupom)
    this.setState({
      extratoPagamento: cupom,
      dadosCarregados: true,
    })
  }

  render(): JSX.Element {
    if (this.state.dadosCarregados) {
      this.props.history.replace('/' + this.props.match.params.cidadeAtua + '/financeiro/pdf')
    }
    return <div>Carregando..</div>
  }

  async componentDidMount(): Promise<void> {
    if (!this.state.extratoPagamento.isEmpty && !this.state.dadosCarregados && !this.state.carregando) {
      const newExtrato = await this.state.extratoPagamento.fillFromJson(undefined, [
        'carrinhoCompras',
        'cliente',
        'tipoServico',
        'servicosCarregados',
        'situacaoInicial',
      ])

      this.props.modificarExtratoPagamentoSelecionado(newExtrato)

      this.setState({
        dadosCarregados: true,
      })
    }
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (
  dispatch: Dispatch,
): typeof LoginActions & typeof CidadesAtuaAction & typeof ParceirosActions & typeof ExtratosPagamentoActions =>
  bindActionCreators(
    { ...LoginActions, ...ParceirosActions, ...ExtratosPagamentoActions, ...CidadesAtuaAction },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(PaginaPDF)
