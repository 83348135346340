import { action } from 'typesafe-actions'
import {
  ParceirosState,
  ParceirosTypes,
  AtualizarParceiros,
  AdicionarParceiro,
  LimparParceiros,
  ModificarParceiroSelecionado,
  ModificarListaCarregadaParceiros,
  RemoverParceiro,
} from './types'
import Parceiro from '../../../models/Parceiro'
export const atualizarParceiros = (data: ParceirosState): AtualizarParceiros =>
  action(ParceirosTypes.ATUALIZAR_PARCEIROS, data)

export const adicionarParceiro = (newParceiro: Parceiro): AdicionarParceiro => {
  return action(ParceirosTypes.ADICIONAR_PARCEIRO, newParceiro)
}

export const removerParceiro = (newParceiro: Parceiro): RemoverParceiro => {
  return action(ParceirosTypes.REMOVER_PARCEIRO, newParceiro)
}

export const limparParceiros = (): LimparParceiros => {
  return action(ParceirosTypes.LIMPAR_PARCEIROS)
}

export const modificarParceiroSelecionado = (newParceiro: Parceiro): ModificarParceiroSelecionado => {
  return action(ParceirosTypes.MODIFICAR_PARCEIRO_SELECIONADO, newParceiro)
}

export const modificarListaCarregadaParceiros = (estaCarregada: boolean): ModificarListaCarregadaParceiros => {
  return action(ParceirosTypes.MODIFICAR_LISTA_CARREGADA, estaCarregada)
}
