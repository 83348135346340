import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import Menu from '../menu/Menu'
import { connect } from 'react-redux'

import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'

import { SearchState, IntegratedFiltering, IntegratedPaging, PagingState } from '@devexpress/dx-react-grid'
import { Grid, Table, Toolbar, SearchPanel, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui'

import * as EnderecosActions from '../../store/models/enderecos/actions'
import { ActionColumn, ActionColumns } from '../utils/TableActionButtons'
// Pega os icones
import Create from '@material-ui/icons/Create'
import Delete from '@material-ui/icons/Delete'
import Endereco from '../../models/Endereco'
import EnderecoController from '../../controller/EnderecoController'

type Props = ApplicationState & LoginState & typeof LoginActions & RouteComponentProps & typeof EnderecosActions

const cabecalho = [
  { name: 'alterar' },
  { name: 'excluir' },
  { name: 'apelido', title: 'Apelido' },
  { name: 'formattedEndereco', title: 'Endereco' },
]
const tableMessages = {
  noData: 'Sem registros',
}
const searchMessages = {
  searchPlaceholder: 'Pesquisar',
}

interface RowPontoFixo {
  apelido: string
  formattedEndereco: string
  endereco: Endereco
}

class HomePontosFixos extends Component<Props> {
  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    this.handleClickAlterar = this.handleClickAlterar.bind(this)
    this.handleClickExcluir = this.handleClickExcluir.bind(this)
    this.renderGrid = this.renderGrid.bind(this)
    this.goToAdicionar = this.goToAdicionar.bind(this)
    this.renderLogged = this.renderLogged.bind(this)
    this.renderNotLogged = this.renderNotLogged.bind(this)
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  renderLogged(): JSX.Element {
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Cadastro de Ponto fixo</h1>
          <button onClick={this.goToAdicionar} className="botaoCrud">
            Novo PontoFixo
          </button>
          {this.renderGrid()}
        </div>
      </div>
    )
  }

  goToAdicionar(): void {
    this.props.history.push('pontos_fixos/adicionar')
  }

  renderNotLogged(): JSX.Element {
    return (
      <div>
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  private handleClickAlterar(row: RowPontoFixo): void {
    this.props.modificarEnderecoSelecionado(row.endereco)
    this.props.history.push('pontos_fixos/' + row.endereco.id + '/alterar')
  }

  private handleClickExcluir(row: RowPontoFixo): void {
    EnderecoController.deletar(row.endereco)
    this.props.removerEndereco(row.endereco)
  }

  renderGrid(): JSX.Element {
    // Busca serviços e estrutura registros
    const listEnderecos = this.props.models.enderecos ? this.props.models.enderecos.data : []
    let tuplas: readonly RowPontoFixo[] = []
    if (listEnderecos) {
      tuplas = listEnderecos.map(endereco => {
        return {
          apelido: endereco.apelido,
          formattedEndereco: endereco.formatarEndereco(),
          endereco,
        }
      })
    }
    const actionColumns: ActionColumn[] = [
      { columnName: 'alterar', label: 'Abrir modo edição', onClick: this.handleClickAlterar, icon: <Create /> },
      { columnName: 'excluir', label: 'Excluir linha', onClick: this.handleClickExcluir, icon: <Delete /> },
    ]
    // Monta Grid
    return (
      <div className="grid">
        <Grid rows={tuplas} columns={cabecalho}>
          <PagingState defaultCurrentPage={0} defaultPageSize={5} />
          <SearchState defaultValue="" />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table messages={tableMessages} />
          <TableHeaderRow />
          <Toolbar />
          <SearchPanel messages={searchMessages} />
          <ActionColumns actionColumns={actionColumns} />
          <PagingPanel
            pageSizes={[5, 10, 20]}
            messages={{
              showAll: 'Mostrar todos',
              rowsPerPage: 'Linhas por página',
              info: (parameters: { from: number; to: number; count: number }): string =>
                parameters.from + '-' + parameters.to + ' de ' + parameters.count,
            }}
          />
        </Grid>
      </div>
    )
  }

  // Recupera lista Tipo Serviço
  async componentDidMount(): Promise<void> {
    let done: boolean | undefined = false
    this.props.limparEnderecos()
    const asyncGeneratorEndereco = EnderecoController.pegarTodos()
    while (!done) {
      const iteratorResult = await asyncGeneratorEndereco.next()
      if (iteratorResult.value) {
        this.props.adicionarEndereco(iteratorResult.value)
      }
      done = iteratorResult.done
    }
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof EnderecosActions =>
  bindActionCreators({ ...LoginActions, ...EnderecosActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HomePontosFixos)
