class Administrador {
  private _nome: string
  get nome(): string {
    return this._nome
  }

  constructor(nome: string) {
    this._nome = nome
  }
}

export default Administrador
