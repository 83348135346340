import Endereco from '../../../models/Endereco'

export enum EnderecosTypes {
  ATUALIZAR_ENDERECOS = '@enderecos/ATUALIZAR_TODOS',
  ADICIONAR_ENDERECO = '@enderecos/ADICIONAR',
  REMOVER_ENDERECO = '@enderecos/REMOVER',
  MODIFICAR_ENDERECO_SELECIONADO = '@enderecos/MODIFICAR_SELECIONADO',
  MODIFICAR_LISTA_CARREGADA = '@enderecos/MODIFICAR_LISTA_CARREGADA',
  LIMPAR_ENDERECOS = '@enderecos/LIMPAR',
}

export interface EnderecosState {
  readonly data: Endereco[]
  readonly selecionado: Endereco
  readonly listaCarregada: boolean
}

export interface AtualizarEnderecos {
  type: typeof EnderecosTypes.ATUALIZAR_ENDERECOS
}

export interface AdicionarEndereco {
  type: typeof EnderecosTypes.ADICIONAR_ENDERECO
}

export interface RemoverEndereco {
  type: typeof EnderecosTypes.REMOVER_ENDERECO
}

export interface LimparEnderecos {
  type: typeof EnderecosTypes.LIMPAR_ENDERECOS
}

export interface ModificarListaCarregadaEnderecos {
  type: typeof EnderecosTypes.MODIFICAR_LISTA_CARREGADA
}

export interface ModificarEnderecoSelecionado {
  type: typeof EnderecosTypes.MODIFICAR_ENDERECO_SELECIONADO
}
