import { Reducer } from 'redux'
import { TiposServicosState, TiposServicosTypes } from './types'
import TipoServico from '../../../models/TipoServico'

const initialState: TiposServicosState = {
  data: [],
  selecionado: new TipoServico(),
  listaCarregada: false,
}

const reducer: Reducer<TiposServicosState> = (state = initialState, action) => {
  const currentData = state.data

  switch (action.type) {
    case TiposServicosTypes.ADICIONAR_TIPO_SERVICO: {
      const newData = currentData.filter(p => p.id !== action.payload.id)
      newData.push(action.payload)
      return { ...state, data: newData }
    }
    case TiposServicosTypes.ATUALIZAR_TIPOS_SERVICOS:
      return { ...state, data: action.payload.data }
    case TiposServicosTypes.LIMPAR_TIPOS_SERVICOS:
      return { ...state, data: [] }
    case TiposServicosTypes.MODIFICAR_TIPO_SERVICO_SELECIONADO:
      return { ...state, selecionado: action.payload }
    case TiposServicosTypes.REMOVER_TIPO_SERVICO: {
      const newData = currentData.filter(tipoServico => tipoServico.id !== action.payload.id)
      return { ...state, data: newData }
    }
    case TiposServicosTypes.MODIFICAR_LISTA_CARREGADA:
      return { ...state, listaCarregada: action.payload }
    default:
      return state
  }
}

export default reducer
