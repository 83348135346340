import IuguMethods from '../iugu_methods'

class CupomResource {
  async upgrade(
    data: string | object,
    urlParams?: Map<string, string> | undefined,
    queryParams?: Map<string, string> | undefined,
  ): Promise<any> {
    return IuguMethods.createIuguMethod<any>({
      method: 'post',
      path: '/' + this.routeName + '/upgrade',
      urlParams: ['documentId'],
    })(data, urlParams)
  }

  get routeName(): string {
    return 'cupom'
  }
}

export default new CupomResource()
