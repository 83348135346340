import IuguMethods from './iugu_methods'
import IuguCustomers from './resources/customers'
import IuguPaymentsTokens from './resources/payment_token'
import IuguInvoices from './resources/invoices'
import IuguCharges from './resources/taxes'
import IuguAccounts from './resources/accounts'
import IuguPayment from './resources/payment'
import IuguPaymentMethod from './resources/payment_method'
import CupomResource from './resources/cupom'
import IuguAgendamentoCancelamento from './resources/agendamento_cancelamento'

class Iugu {
  public get customers(): typeof IuguCustomers {
    return IuguCustomers
  }

  public get paymentToken(): typeof IuguPaymentsTokens {
    return IuguPaymentsTokens
  }

  public get invoices(): typeof IuguInvoices {
    return IuguInvoices
  }

  public get charge(): typeof IuguCharges {
    return IuguCharges
  }

  public get accounts(): typeof IuguAccounts {
    return IuguAccounts
  }

  public get paymentos(): typeof IuguPayment {
    return IuguPayment
  }

  public get paymentMethods(): typeof IuguPaymentMethod {
    return IuguPaymentMethod
  }

  public get cupom(): typeof CupomResource {
    return CupomResource
  }

  public get agendamentosCancelamentos(): typeof IuguAgendamentoCancelamento {
    return IuguAgendamentoCancelamento
  }

  setToken(apikey: string): void {
    IuguMethods.setToken(apikey)
  }
}

export default new Iugu()
