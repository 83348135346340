import Administrador from '../../../models/Administrador'

/**
 * Actions types
 */

export enum LoginTypes {
  LOGIN_REQUEST = '@administrador/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@administrador/LOGIN_SUCCESS',
  LOGIN_FAILURE = '@administrador/LOGIN_FAILURE',
  LOGOUT = '@administrador/LOGOUT'
}

export interface LoginSuccess {
  type: typeof LoginTypes.LOGIN_SUCCESS
}

export interface LoginRequest {
  type: typeof LoginTypes.LOGIN_REQUEST
}

export interface LoginFailure {
  type: typeof LoginTypes.LOGIN_FAILURE
}

export interface Logout {
  type: typeof LoginTypes.LOGOUT
}

export interface Login {
  user?: firebase.User
  administrador: Administrador
}

export interface LoginState {
  readonly data: Login
  readonly isLogged: boolean
}
