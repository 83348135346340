import FirebaseManager from '../utils/FirebaseManager'
import Cupom from '../models/Cupom'

class CupomRepository {
  async criar(cupom: Cupom): Promise<Cupom> {
    const document: firebase.firestore.DocumentReference = FirebaseManager.database.collection('cupons').doc()
    const map = {
      validade: cupom.validade.format(),
      codigo: cupom.codigo,
      restante: cupom.restante,
      desconto: cupom.desconto,
      tipoCupom: cupom.tipoCupom,
      valorMinimo: cupom.valorMinimo,
      ativo: true,
    }
    await document.set(map)
    cupom.ref = document
    return cupom
  }

  async alterar(cupom: Cupom): Promise<Cupom> {
    const document: firebase.firestore.DocumentReference = cupom.ref
    const map = {
      validade: cupom.validade.format(),
      codigo: cupom.codigo,
      restante: cupom.restante,
      desconto: cupom.desconto,
      tipoCupom: cupom.tipoCupom,
      valorMinimo: cupom.valorMinimo,
      ativo: true,
    }

    await document.update(map)
    cupom.ref = document
    return cupom
  }

  async deletar(cupom: Cupom): Promise<Cupom> {
    await cupom.ref.update({
      ativo: false,
    })
    return cupom
  }

  async pegarPorId(documentId: string, recursive?: string[]): Promise<Cupom> {
    const reference: firebase.firestore.DocumentReference = await FirebaseManager.database
      .collection('cupons')
      .doc(documentId)
    const doc = await reference.get()
    if (doc.exists) {
      const cupom = await new Cupom().convert(doc, recursive)

      if (cupom instanceof Cupom) {
        return cupom
      }
    }

    return new Cupom()
  }

  async *pegarTodos(recursive?: string[]): AsyncGenerator<Cupom, void, Cupom> {
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('cupons')
      .where('ativo', '==', true)
      .get()
    const queryDocsSnapshot = query.docs
    if (queryDocsSnapshot) {
      for (const doc of queryDocsSnapshot) {
        const data = doc.data()
        if (data && data.pidCliente) {
          continue
        }
        const cupom = await new Cupom().convert(doc, recursive)

        if (cupom instanceof Cupom) {
          yield cupom
        }
      }
    }
  }
}

export default new CupomRepository()
