import FirebaseManager from '../utils/FirebaseManager'
import moment, { Moment } from 'moment-timezone'
import { Situacoes, Fatura, StatusPagamento } from '../models/model_interfaces'

class FaturaRepository {
  async getByDate(pid: string, dateFrom: Moment, dateAt: Moment): Promise<Fatura[]> {
    const queryServicos = await FirebaseManager.database
      .collection('servicosprestados')
      .where('parceiroUid', '==', pid)
      .where('statusPagamento', '==', StatusPagamento.PAGO)
      .get()

    const querySituacoes = await FirebaseManager.database
      .collection('situacoes')
      .where('estado', '>=', Situacoes.FINALIZADO)
      .where('estado', '<=', Situacoes.SUGESTOES)
      .get()

    const listServicos: Fatura[] = []
    if (queryServicos.docs.length > 0 && querySituacoes.docs.length > 0) {
      for (const docServico of queryServicos.docs) {
        for (const docSituacao of querySituacoes.docs) {
          const dataSituacao = docSituacao.data()
          if (
            dataSituacao &&
            moment(dataSituacao.dataHora).isAfter(dateFrom) &&
            moment(dataSituacao.dataHora).isBefore(dateAt)
          ) {
            const listSituacoes: firebase.firestore.DocumentReference[] = docServico.data().situacoes || []
            if (listSituacoes.filter(ref => ref.id === docSituacao.id).length > 0) {
              const data = docServico.data()
              if (data) {
                const fatura: Fatura = {
                  bruto: data.valor,
                  liquido: data.valor,
                  data: moment(dataSituacao.dataHora),
                  forma: data.tipoPagamento === 1 ? 'Cartão' : 'Dinheiro',
                  ativo: true,
                }
                listServicos.push(fatura)
              }
              break
            }
          }
        }
      }
    }

    return listServicos
  }
}

export default new FaturaRepository()
