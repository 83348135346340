import FirebaseManager from '../utils/FirebaseManager'
import TipoServico from '../models/TipoServico'

class TipoServicoRepository {
  async criar(tipoServico: TipoServico): Promise<TipoServico> {
    const document: firebase.firestore.DocumentReference = FirebaseManager.database.collection('tiposservicos').doc()
    const map = {
      descricao: tipoServico.descricao,
      infosTipoServico: tipoServico.infosTipoServico,
      dica: tipoServico.dica,
      paiRef: tipoServico.paiRef,
      ativo: true,
      cidade: tipoServico.cidade,
      aptoPontoFixo: tipoServico.aptoPontoFixo || false,
      aptoLoja: tipoServico.aptoLoja || false,
      aptoDelivery: tipoServico.aptoDelivery === undefined ? true : tipoServico.aptoDelivery,
    }
    await document.set(map)
    tipoServico.ref = document
    return tipoServico
  }

  async alterar(tipoServico: TipoServico): Promise<TipoServico> {
    const document: firebase.firestore.DocumentReference = tipoServico.ref
    const map = {
      descricao: tipoServico.descricao,
      infosTipoServico: tipoServico.infosTipoServico,
      paiRef: tipoServico.paiRef,
      dica: tipoServico.dica,
      aptoPontoFixo: tipoServico.aptoPontoFixo || false,
      aptoLoja: tipoServico.aptoLoja || false,
      aptoDelivery: tipoServico.aptoDelivery === undefined ? true : tipoServico.aptoDelivery,
    }

    await document.update(map)
    tipoServico.ref = document
    return tipoServico
  }

  async deletar(tipoServico: TipoServico): Promise<TipoServico> {
    await tipoServico.ref.update({
      ativo: false,
    })
    return tipoServico
  }

  async pegarPorId(documentId: string, recursive?: string[]): Promise<TipoServico> {
    const reference: firebase.firestore.DocumentReference = await FirebaseManager.database
      .collection('tiposservicos')
      .doc(documentId)
    const doc = await reference.get()
    if (doc.exists) {
      const tipoServico = await new TipoServico().convert(doc, recursive)

      if (tipoServico instanceof TipoServico) {
        return tipoServico
      }
    }

    return new TipoServico()
  }

  async *pegarTodosTiposServicos(
    cidade: string | string[],
    recursive?: string[],
  ): AsyncGenerator<TipoServico, void, TipoServico> {
    const query: firebase.firestore.QuerySnapshot =
      cidade instanceof Array
        ? await FirebaseManager.database
            .collection('tiposservicos')
            .where('cidade', 'in', cidade)
            .where('ativo', '==', true)
            .get()
        : await FirebaseManager.database
            .collection('tiposservicos')
            .where('cidade', '==', cidade)
            .where('ativo', '==', true)
            .get()
    const queryDocsSnapshot = query.docs
    if (queryDocsSnapshot) {
      for (const doc of queryDocsSnapshot) {
        const tipoServico = await new TipoServico().convert(doc, recursive)

        if (tipoServico instanceof TipoServico) {
          yield tipoServico
        }
      }
    }
  }

  async pegarTodosTiposServicosDireto(cidade: string | string[], recursive?: string[]): Promise<TipoServico[]> {
    const query: firebase.firestore.QuerySnapshot =
      cidade instanceof Array
        ? await FirebaseManager.database
            .collection('tiposservicos')
            .where('cidade', 'in', cidade)
            .where('ativo', '==', true)
            .get()
        : await FirebaseManager.database
            .collection('tiposservicos')
            .where('cidade', '==', cidade)
            .where('ativo', '==', true)
            .get()
    const list = []
    const queryDocsSnapshot = query.docs
    if (queryDocsSnapshot) {
      for (const doc of queryDocsSnapshot) {
        const tipoServico = await new TipoServico().convert(doc, recursive)

        if (tipoServico instanceof TipoServico) {
          list.push(tipoServico)
        }
      }
    }
    return list
  }
}

export default new TipoServicoRepository()
