import { Reducer } from 'redux'
import { TiposVeiculosState, TiposVeiculosTypes } from './types'
import TipoVeiculo from '../../../models/TipoVeiculo'

const initialState: TiposVeiculosState = {
  data: [],
  selecionado: new TipoVeiculo(),
  listaCarregada: false,
}

const reducer: Reducer<TiposVeiculosState> = (state = initialState, action) => {
  const currentData = state.data

  switch (action.type) {
    case TiposVeiculosTypes.ADICIONAR_TIPO_VEICULO: {
      const newData = currentData.filter(p => p.id !== action.payload.id)
      newData.push(action.payload)
      return { ...state, data: newData }
    }
    case TiposVeiculosTypes.ATUALIZAR_TIPO_VEICULOS:
      return { ...state, data: action.payload.data }
    case TiposVeiculosTypes.LIMPAR_TIPO_VEICULOS:
      return { ...state, data: [], listaCarregada: false }
    case TiposVeiculosTypes.MODIFICAR_TIPO_VEICULO_SELECIONADO:
      return { ...state, selecionado: action.payload }
    case TiposVeiculosTypes.REMOVER_TIPO_VEICULO: {
      const newData = currentData.filter(cliente => cliente.id !== action.payload.id)
      return { ...state, data: newData }
    }
    case TiposVeiculosTypes.MODIFICAR_LISTA_CARREGADA:
      return { ...state, listaCarregada: action.payload }
    default:
      return state
  }
}

export default reducer
