import BaseDocument from './BaseDocument'
import { ProdutoInterface } from './model_interfaces'
import moment, { Moment } from 'moment-timezone'

export default class Produto extends BaseDocument<Produto> implements ProdutoInterface {
  nome!: string
  descricao!: string
  validade!: Moment
  quantidade!: number
  preco!: number
  dataFormatado!: string
  horaFormatado!: string
  async fillFromJson(json?: any, recursive?: string[] | undefined): Promise<Produto> {
    if (!json) {
      json = this.dados
    }

    this.nome = json.nome
    this.descricao = json.descricao
    this.quantidade = json.quantidade
    this.preco = json.preco
    this.validade = moment(json.validade)
    this.dataFormatado = this.validade.toDate().toLocaleDateString()
    this.horaFormatado = this.validade.toDate().toLocaleTimeString()

    return this
  }

  canInsertOrUpdate(): void {
    if (this.nome.length < 3) {
      throw Error('Nome muito curto')
    }

    if (this.descricao.length < 3) {
      throw Error('Descrição muito curta')
    }

    if (this.validade.isBefore(moment())) {
      throw Error('A validade não pode ser anterior ao dia de hoje')
    }

    if (this.quantidade < 0) {
      throw Error('Não é possível ter um quantidade de produtos negativa')
    }

    if (this.preco <= 0) {
      throw Error('Não é possível um produto ser grátis')
    }
  }
}
