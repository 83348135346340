import BaseDocument from './BaseDocument'
import { ClienteInterface } from './model_interfaces'
import { firestore } from 'firebase'
import Veiculo from './Veiculo'
import Endereco from './Endereco'
import moment, { Moment } from 'moment-timezone'

export default class Cliente extends BaseDocument<Cliente> implements ClienteInterface {
  tokenCliente!: string
  nome!: string
  telefone!: string
  nascimento!: Moment
  sexo!: string
  carteira!: number
  mediaNota!: number
  veiculos!: Veiculo[]
  enderecos!: Endereco[]
  pcliente!: firestore.DocumentReference
  pid!: string
  uid!: string
  email!: string

  get nascimentoFormatado(): string {
    return this.nascimento.format('DD/MM/YYYY')
  }

  async fillFromJson(json?: any, recursive?: string[] | undefined): Promise<Cliente> {
    if (!json) {
      json = this.dados
    }
    recursive = recursive || ['veiculo', 'endereco']
    this.nome = json.nome

    const pcliente: firestore.DocumentReference = json.pcliente
    if (!recursive.includes('not_pcliente') && pcliente) {
      this.pcliente = pcliente
      const snapshot = await pcliente.get()
      const data = snapshot.data()
      if (data) {
        this.nome = data.nome
      }
    }

    const veiculos: firestore.DocumentReference[] = json.veiculos
    if ('veiculos' in json && recursive.includes('veiculo') && veiculos) {
      this.veiculos = []
      for (const veiculo of veiculos) {
        const snapshot = await veiculo.get()
        const newVeiculo = await new Veiculo().convert(snapshot, recursive)
        if (newVeiculo instanceof Veiculo) {
          this.veiculos.push(newVeiculo)
        }
      }
    }

    const enderecos: firestore.DocumentReference[] = json.enderecos
    if ('enderecos' in json && recursive.includes('endereco') && enderecos) {
      this.enderecos = []
      for (const endereco of enderecos) {
        const snapshot = await endereco.get()
        const newEndereco = await new Endereco().convert(snapshot, recursive)
        if (newEndereco instanceof Endereco) {
          this.enderecos.push(newEndereco)
        }
      }
    }
    if (!this.pid) {
      this.pid = json.pid
    }
    this.telefone = json.telefone
    this.sexo = json.sexo
    this.carteira = json.carteira
    this.mediaNota = json.mediaNota
    this.tokenCliente = json.tokenCliente
    this.nascimento = moment(json.nascimento)
    return this
  }
}
