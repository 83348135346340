// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// TipoServico
import * as TipoServicosActions from '../../store/models/tiposservicos/actions'
import TipoServicoController from '../../controller/TipoServicoController'
import TipoServico from '../../models/TipoServico'
import { InfoTipoServico } from '../../models/model_interfaces'
// Form
import { Form, Button } from 'react-bootstrap'

interface TipoServicoAlterarState {
  tipoServico: TipoServico
  pai: TipoServico
  posServico: number
}
// Une todos em props antes de passar ao ao componente
type Props = ApplicationState & LoginState & typeof LoginActions & typeof TipoServicosActions & RouteComponentProps<any>

class TipoServiAlterar extends Component<Props, TipoServicoAlterarState> {
  private descricao: React.RefObject<HTMLInputElement>
  private temposMedio: React.RefObject<HTMLInputElement>[]
  private valores: React.RefObject<HTMLInputElement>[]
  private dica: React.RefObject<HTMLTextAreaElement>
  private tamanhos = ['Pequeno', 'Medio', 'Grande']

  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    this.renderDropdown = this.renderDropdown.bind(this)
    this.pegarPai = this.pegarPai.bind(this)
    this.handleChangeDropDown = this.handleChangeDropDown.bind(this)
    this.state = {
      tipoServico: this.props.models.tiposServicos.selecionado,
      posServico: -1,
      pai: new TipoServico(),
    }
    // Vincula palavra this a função
    this.goBack = this.goBack.bind(this)
    this.salvarTipoServico = this.salvarTipoServico.bind(this)

    this.temposMedio = new Array<React.RefObject<HTMLInputElement>>(3)
    this.valores = new Array<React.RefObject<HTMLInputElement>>(3)
    for (let i = 0; i < 3; i++) {
      this.temposMedio[i] = React.createRef<HTMLInputElement>()
      this.valores[i] = React.createRef<HTMLInputElement>()
    }
    this.descricao = React.createRef<HTMLInputElement>()
    this.dica = React.createRef<HTMLTextAreaElement>()
    if (
      !this.props.models.tiposServicos ||
      !this.props.models.tiposServicos.data ||
      this.props.models.tiposServicos.selecionado.isEmpty
    ) {
      if (this.props.match.params.id) {
        this.pegarTipoServicoById(this.props.match.params.id)
      } else {
        this.props.history.replace('/tiposservicos')
      }
    }
  }

  pegarPai(): void {
    if (!this.state.tipoServico.isEmpty && this.state.tipoServico.paiRef) {
      let idx = 0
      for (const ts of this.props.models.tiposServicos.data) {
        if (ts.ref.id === this.state.tipoServico.paiRef.id) {
          this.setState({
            pai: ts,
            posServico: idx,
          })
        }
        idx++
      }
    }
  }

  async pegarTipoServicoById(documentId: string): Promise<void> {
    const tipoServico = await TipoServicoController.pegarPorId(documentId)
    this.setState({
      tipoServico: tipoServico,
    })
    this.pegarPai()
  }

  renderDropdown(): JSX.Element {
    const options: JSX.Element[] = this.props.models.tiposServicos.data.map<JSX.Element>((tipoServico, idx) => {
      return (
        <option key={idx + 1} value={idx}>
          {tipoServico.descricao}
        </option>
      )
    })
    options.unshift(
      <option key={0} value={-1}>
        Nenhum
      </option>,
    )
    return (
      <Form.Group className="dropdown" style={{ position: 'inherit' }}>
        <Form.Control as="select" onChange={this.handleChangeDropDown} value={this.state.posServico}>
          {options}
        </Form.Control>
      </Form.Group>
    )
  }

  handleChangeDropDown(event: React.ChangeEvent<HTMLSelectElement>): void {
    if (Number(event.target.value) === -1) {
      this.setState({
        posServico: -1,
        pai: new TipoServico(),
      })
    } else {
      this.setState({
        posServico: Number(event.target.value),
        pai: this.props.models.tiposServicos.data[Number(event.target.value)],
      })
    }
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  renderInfoTipoServicos(): JSX.Element[] {
    const forms: JSX.Element[] = []
    for (let i = 0; i < 3; i++) {
      forms.push(
        <Form.Group controlId={'formTamanho' + i} key={i}>
          <p>{this.tamanhos[i]}</p>
          <Form.Control
            defaultValue={this.state.tipoServico.infosTipoServico[i].valor}
            ref={this.valores[i]}
            type="number"
            placeholder="Valor"
          />
          <Form.Control
            defaultValue={this.state.tipoServico.infosTipoServico[i].tempoMedio}
            ref={this.temposMedio[i]}
            type="number"
            placeholder="Tempo"
          />
        </Form.Group>,
      )
    }

    return forms
  }

  handleChange(tipo: 'aptoLoja' | 'aptoPontoFixo' | 'aptoDelivery', evt: React.ChangeEvent<HTMLInputElement>): void {
    const tipoServico = this.state.tipoServico
    tipoServico[tipo] = evt.target.checked
    this.setState({ tipoServico })
  }

  renderLogged(): JSX.Element {
    // Define estilo dos botões
    if (this.state.tipoServico.isEmpty) {
      return <div>Carregando...</div>
    }
    const estiloBtnAdicionar = { backgroundColor: '#113F4F', color: '#FFFFFF', borderRadius: '0' }
    const estiloBtnCancelar = { backgroundColor: '#1B9999', color: '#FFFFFF', borderRadius: '0' }
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Alterar Tipo de Serviços</h1>
          <br />
          <Form className="form">
            <p>Nome</p>
            <Form.Group controlId="formBasicDescricao">
              <Form.Control
                defaultValue={this.state.tipoServico.descricao}
                ref={this.descricao}
                type="text"
                placeholder="Descrição"
              />
            </Form.Group>
            <p>Dica</p>
            <Form.Group controlId="formaBasicDica">
              <Form.Control
                defaultValue={this.state.tipoServico.dica}
                ref={this.dica}
                as="textarea"
                placeholder="Dica"
              />
            </Form.Group>
            <p>Apto na loja?</p>
            <Form.Group controlId="formBasicVerificacaoLoja" className="checkBoxValidacaoParceiro">
              <Form.Check
                defaultChecked={this.state.tipoServico.aptoLoja}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => this.handleChange('aptoLoja', evt)}
                type="checkbox"
              />
            </Form.Group>
            <p>Apto no ponto fixo?</p>
            <Form.Group controlId="formBasicPontoFixo" className="checkBoxValidacaoParceiro">
              <Form.Check
                defaultChecked={this.state.tipoServico.aptoPontoFixo}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => this.handleChange('aptoPontoFixo', evt)}
                type="checkbox"
              />
            </Form.Group>
            <p>Apto delivery?</p>
            <Form.Group controlId="formBasicPontoFixo" className="checkBoxValidacaoParceiro">
              <Form.Check
                defaultChecked={
                  this.state.tipoServico.aptoDelivery === undefined ? true : this.state.tipoServico.aptoDelivery
                }
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => this.handleChange('aptoDelivery', evt)}
                type="checkbox"
              />
            </Form.Group>
            <div className="formItensMenores">{this.renderInfoTipoServicos()}</div>
            {this.renderDropdown()}
            <div className="formButtons formButtonsTipoServico">
              <Button onClick={this.goBack} style={estiloBtnCancelar}>
                Cancelar
              </Button>
              <Button onClick={this.salvarTipoServico} style={estiloBtnAdicionar}>
                Alterar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }

  async salvarTipoServico(): Promise<void> {
    try {
      const infosTipoServico: InfoTipoServico[] = []
      const tamanhos = ['Pequeno', 'Medio', 'Grande']
      for (let i = 0; i < 3; i++) {
        const valor = this.valores[i].current?.valueAsNumber
        const tempoMedio = this.temposMedio[i].current?.valueAsNumber
        if (valor && tempoMedio) {
          const infoTipoServico: InfoTipoServico = {
            valor: valor,
            tempoMedio: tempoMedio,
            tamanho: tamanhos[i],
          }

          infosTipoServico.push(infoTipoServico)
        } else {
          throw new Error('Dados inválidos')
        }
      }
      const descricao = this.descricao.current?.value
      const dica = this.dica.current?.value
      if (infosTipoServico && descricao && descricao) {
        this.state.tipoServico.infosTipoServico = infosTipoServico
        this.state.tipoServico.descricao = descricao
        this.state.tipoServico.dica = dica || ''
        this.state.tipoServico.cidade = this.props.match.params.cidadeAtua
        this.state.tipoServico.paiRef = this.state.pai.isEmpty ? null : this.state.pai.ref
        this.state.tipoServico.canInsertOrUpdate()
        this.setState({
          tipoServico: await TipoServicoController.alterar(this.state.tipoServico),
        })
        this.props.adicionarTipoServico(this.state.tipoServico)
        this.props.history.goBack()
      } else {
        throw new Error('Dados inválidos')
      }
    } catch (error) {
      alert(error)
    }
  }

  goBack(): void {
    this.props.history.goBack()
  }

  renderNotLogged(): JSX.Element {
    return (
      <div className="Home">
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  // Recupera lista Tipo Serviço
  async componentDidMount(): Promise<void> {
    let done: boolean | undefined = false
    const asyncGeneratorTipoServico = TipoServicoController.pegarTodos(this.props.match.params.cidadeAtua)
    while (!done) {
      const iteratorResult = await asyncGeneratorTipoServico.next()
      if (iteratorResult.value) {
        this.props.adicionarTipoServico(iteratorResult.value)
      }
      done = iteratorResult.done
    }
    this.pegarPai()
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof TipoServicosActions =>
  bindActionCreators({ ...LoginActions, ...TipoServicosActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TipoServiAlterar)
