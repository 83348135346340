import BaseDocument from './BaseDocument'
import { TipoVeiculoInterface } from './model_interfaces'

export default class TipoVeiculo extends BaseDocument<TipoVeiculo> implements TipoVeiculoInterface {
  modelo!: string
  tamanho!: string
  marca!: string

  async fillFromJson(json?: any, recursive?: string[] | undefined): Promise<TipoVeiculo> {
    if (!json) {
      json = this.dados
    }

    this.tamanho = json.tamanho
    this.modelo = json.modelo
    this.marca = json.marca

    return this
  }

  canInsertOrUpdate() : void {

  }
}
