// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faBars } from '@fortawesome/free-solid-svg-icons'
import Modal from './Modal'

export enum TipoServico {
  LAVAGEM_INTERNA,
  LAVAGEM_EXTERNA,
  LAVAGEM_COMPLETA,
  PROTECT,
  MEGA,
  PREMIUM,
  PRO,
}
// Une todos em props antes de passar ao ao componente
interface ServicosState {
  showModal: boolean
  servico: TipoServico
}
type Props = RouteComponentProps

export class Servicos extends Component<Props, ServicosState> {
  private navCollapse: React.RefObject<HTMLDivElement>
  constructor(props: Props) {
    super(props)
    this.navCollapse = React.createRef<HTMLDivElement>()
    this.handleButton = this.handleButton.bind(this)
    this.handleNavToggle = this.handleNavToggle.bind(this)
    this.state = {
      showModal: false,
      servico: 0,
    }
  }

  handleNavToggle(): void {
    const current = this.navCollapse.current
    if (current) {
      if (current.className.includes('show')) {
        current.className = current.className.replace('show', '')
      } else {
        current.className = current.className + ' show'
      }
    }
  }

  render(): JSX.Element {
    return (
      <div id="page-top">
        <nav className="navbar navbar-expand-lg bg-secondary fixed-top" id="mainNav">
          <div className="container">
            <a className="navbar-brand js-scroll-trigger" href="#page-top">
              <img src={process.env.PUBLIC_URL + '/assets/img/logo_icon.png'} alt="Símbolo da empresa Lincar" />
              <img src={process.env.PUBLIC_URL + '/assets/img/logo_nome.png'} alt="Lincar" />
            </a>
            <button
              className="navbar-toggler navbar-toggler-right font-weight-bold bg-primary text-white rounded"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              onClick={this.handleNavToggle}
              aria-label="Toggle navigation"
            >
              Menu
              <FontAwesomeIcon icon={faBars} size="lg" color="white" style={{ marginLeft: 10 }} />
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive" ref={this.navCollapse}>
              <ul className="navbar-nav ml-auto">
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger nav-item-selected" href="servicos">
                    Serviços
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger" href="club">
                    Lincar Club
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger" href="faleconosco">
                    Fale conosco
                  </a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                  <a className="nav-link py-3 px-0 px-lg-3 js-scroll-trigger seja" href="seja-parceiro">
                    Seja Parceiro
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <br />
        <br />
        <br />
        <Modal
          servico={this.state.servico}
          show={this.state.showModal}
          onClose={(): void => this.setState({ showModal: false })}
        />
        <section className="page-section portfolio" id="portfolio">
          <div className="container">
            <h1 className="tituloPaginas">Serviços</h1>
            <div className="row">
              <div className="col-md-6 col-lg-3 mb-5">
                <div
                  onClick={(): void => this.handleButton(TipoServico.LAVAGEM_INTERNA)}
                  className="portfolio-item mx-auto benecaixa"
                  data-toggle="modal"
                  data-target="#portfolioModal1"
                >
                  <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                      <FontAwesomeIcon icon={faPlus} size="3x" color="white" />
                    </div>
                  </div>
                  <p className="benecaixatitulo">Lavagem Ecológica Interna</p>
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/serv_poltrona.png'} alt="" />
                  <p className="benecaixatexto">
                    {' '}
                    Limpeza interna do seu carro, utilizando produtos de alta tecnologia e ecologicamente correto.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-5">
                <div
                  className="portfolio-item mx-auto benecaixa"
                  data-toggle="modal"
                  data-target="#portfolioModal2"
                  onClick={(): void => this.handleButton(TipoServico.LAVAGEM_EXTERNA)}
                >
                  <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                      <FontAwesomeIcon icon={faPlus} size="3x" color="white" />
                    </div>
                  </div>
                  <p className="benecaixatitulo">Lavagem Ecológica Externa</p>
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/serv_carro.png'} alt="" />
                  <p className="benecaixatexto">
                    {' '}
                    Limpeza externa do seu carro, utilizando produtos de alta tecnologia e ecologicamente correto.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-5">
                <div
                  className="portfolio-item mx-auto benecaixa"
                  data-toggle="modal"
                  data-target="#portfolioModal3"
                  onClick={(): void => this.handleButton(TipoServico.LAVAGEM_COMPLETA)}
                >
                  <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                      <FontAwesomeIcon icon={faPlus} size="3x" color="white" />
                    </div>
                  </div>
                  <p className="benecaixatitulo">Lavagem Ecológica Completa</p>
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/serv_spray.png'} alt="" />
                  <p className="benecaixatexto">
                    {' '}
                    Limpeza externa e interna do seu carro, utilizando produtos de alta tecnologia e ecologicamente
                    correto.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-5 mb-lg-0">
                <div
                  className="portfolio-item mx-auto benecaixa"
                  data-toggle="modal"
                  data-target="#portfolioModal4"
                  onClick={(): void => this.handleButton(TipoServico.PROTECT)}
                >
                  <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                      <FontAwesomeIcon icon={faPlus} size="3x" color="white" />
                    </div>
                  </div>
                  <p className="benecaixatitulo">Protect</p>
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/serv_protect.png'} alt="" />
                  <p className="benecaixatexto">
                    {' '}
                    Além de todos os benefícios da Lavagem ecológica completa, mais proteção para lataria do seu
                    veículo.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-5 mb-md-0">
                <div
                  className="portfolio-item mx-auto benecaixa"
                  data-toggle="modal"
                  data-target="#portfolioModal5"
                  onClick={(): void => this.handleButton(TipoServico.MEGA)}
                >
                  <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                      <FontAwesomeIcon icon={faPlus} size="3x" color="white" />
                    </div>
                  </div>
                  <p className="benecaixatitulo">Mega</p>
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/serv_mega.png'} alt="" />
                  <p className="benecaixatexto">
                    Além de todos os benefícios da Protect, higieniza ar condicionado e garante vidros desembaçados.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className="portfolio-item mx-auto benecaixa"
                  data-toggle="modal"
                  data-target="#portfolioModal6"
                  onClick={(): void => this.handleButton(TipoServico.PREMIUM)}
                >
                  <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                      <FontAwesomeIcon icon={faPlus} size="3x" color="white" />
                    </div>
                  </div>
                  <p className="benecaixatitulo">Premium</p>
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/serv_premium.png'} alt="" />
                  <p className="benecaixatexto">
                    {' '}
                    Além de todos os benefícios da Mega, garante vidros cristalizados e neutralização de odores
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className="portfolio-item mx-auto benecaixa"
                  data-toggle="modal"
                  data-target="#portfolioModal7"
                  onClick={(): void => this.handleButton(TipoServico.PRO)}
                >
                  <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                      <FontAwesomeIcon icon={faPlus} size="3x" color="white" />
                    </div>
                  </div>
                  <p className="benecaixatitulo">Pro</p>
                  <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/img/serv_pro.png'} alt="" />
                  <p className="benecaixatexto">
                    Além de todos os benefícios da Premiun, bancos de tecido ou de couro limpos, hidratados e
                    higienizados.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 mb-5 mb-lg-0">
                <img
                  src={process.env.PUBLIC_URL + '/assets/img/logo_icon_nome_branco.png'}
                  alt="Símbolo da empresa Lincar"
                />
              </div>
              <div className="col-lg-3 mb-5 mb-lg-0">
                <ul className="linkrodape">
                  <li>
                    <a href="index">Home</a>
                  </li>
                  <li>
                    <a href="servicos">Serviços</a>
                  </li>
                  <li>
                    <a href="club">Lincar Club</a>
                  </li>
                  <li>
                    <a href="faleconosco">Fale conosco</a>
                  </li>
                  <li>
                    <a href="seja-parceiro" style={{ fontWeight: 'bold' }}>
                      Seja Parceiro
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-7">
                <p className="mb-0 endereco">Travessa Julio Feydit, 19 Lapa / Campos dos Goytacazes Cep:28010-180</p>
              </div>
            </div>
          </div>
        </footer>
        <section className="page-section">
          <div className="container">
            <div className="row" style={{ paddingTop: 20, textAlign: 'center' }}>
              <div className="col-md-6 col-lg-4 mb-5" style={{ paddingTop: 10 }}>
                <img src={process.env.PUBLIC_URL + '/assets/img/logo_teccampos.png'} />
              </div>
              <div className="col-md-6 col-lg-4 mb-5" style={{ textAlign: 'center' }}>
                <img src={process.env.PUBLIC_URL + '/assets/img/logo_embrapii.png'} />
              </div>
              <div className="col-md-6 col-lg-4 mb-5" style={{ textAlign: 'center' }}>
                <img src={process.env.PUBLIC_URL + '/assets/img/logo_fundecam.png'} width="120px" />
              </div>
            </div>
          </div>
        </section>

        <div className="scroll-to-top d-lg-none position-fixed">
          <a className="js-scroll-trigger d-block text-center text-white rounded" href="#page-top">
            <i className="fa fa-chevron-up"></i>
          </a>
        </div>
      </div>
    )
  }

  handleButton(servico: TipoServico): void {
    this.setState({
      showModal: true,
      servico,
    })
  }
}
