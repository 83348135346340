import Disponibilidade from '../models/Disponibilidade'
import Parceiro from '../models/Parceiro'
import DisponibilidadeRepository from '../repositories/DisponibilidadeRepository'

class DisponibilidadeController {
  pegarTodasDisponibilidades(): Promise<Disponibilidade[]> {
    return DisponibilidadeRepository.pegarTodasDisponibilidades()
  }

  pegarPorParceiro(parceiro: Parceiro): Promise<Disponibilidade[]> {
    return DisponibilidadeRepository.pegarDisponibilidadePorParceiro(parceiro)
  }
}

export default new DisponibilidadeController()
