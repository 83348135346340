// React
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
// Interno
import SessionController from '../../controller/SessionController'
import { ApplicationState } from '../../store'
import { Login, LoginState } from '../../store/administrador/login/types'
import * as LoginActions from '../../store/administrador/login/actions'
// CSS e imagens
import './Login.css'
import logo from '../imagens/login_logo.png'
import titulo from '../imagens/login_titulo.png'
import { Button } from 'react-bootstrap'

interface StateProps {
  login: Login
}

// Variável de controle de exibição
let controleLogin = 0

// Une todos em props antes de passar ao ao componente
type Props = LoginState & StateProps & typeof LoginActions & RouteComponentProps
class LoginPage extends Component<Props> {
  constructor(props: Props) {
    super(props)
    if (!this.props.data || !this.props.isLogged) {
      this.props.history.replace('/')
    }
    if (this.props.isLogged) {
      this.props.history.replace('CAMPOS DOS GOYTACAZES_RJ/home')
    }
    // Define controle de renderizacao
    controleLogin = 0
    this.forceUpdate()
    // Vincula palavra this a função
    this.login = this.login.bind(this)
  }

  // Função principal de renderização
  render(): JSX.Element {
    return (
      <div>
        {controleLogin <= 1 ? this.formularioLogin() : this.formularioLoging()}
        {this.corpo()}
      </div>
    )
  }

  // Formulario login antes do login
  formularioLogin(): JSX.Element {
    return (
      <header className="barraSuperior">
        <div>
          <form>
            <div>
              <p>Email</p>
              <input type="text" id="nomelogin" placeholder="Digite seu email de login"></input>
            </div>
            <div>
              <p>Senha</p>
              <input type="password" id="senha"></input>
            </div>
            <div>
              <Button onClick={this.login}>Entrar</Button>
            </div>
          </form>
        </div>
      </header>
    )
  }

  // Formulario login durante validação do login
  formularioLoging(): JSX.Element {
    return (
      <header className="barraSuperior">
        <div>
          <div>
            <p>Verificando login...</p>
          </div>
        </div>
      </header>
    )
  }

  // Define corpo do restante da página
  corpo(): JSX.Element {
    return (
      <div className="fundo">
        <div>
          <img className="tituloImagem" src={titulo} alt="Lincar"></img>
          <p className="titulo">Bem-vindo ao portal administrativo!</p>
        </div>
        <div className="caixaLogo">
          <img className="logo" src={logo} alt="Logo lincar"></img>
        </div>
      </div>
    )
  }

  // Função login
  login = async () => {
    const nomelogin = document.getElementById('nomelogin') as HTMLInputElement
    const senha = document.getElementById('senha') as HTMLInputElement
    controleLogin = 2
    this.forceUpdate()
    const adm = await SessionController.login(nomelogin.value, senha.value)

    if (adm) {
      this.props.loginSuccess({
        data: {
          administrador: adm,
        },
        isLogged: true,
      })
      // Direciona página
      this.props.history.replace('CAMPOS DOS GOYTACAZES_RJ/home')
      // Força atualização
      this.forceUpdate()
    } else {
      controleLogin = 1
      this.forceUpdate()
    }
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => ({
  login: state.login.data,
})

const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions => bindActionCreators(LoginActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
