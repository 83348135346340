import React from 'react'
import './imageComponent.css'
import { Image } from 'react-bootstrap'

interface ImageComponentProps {
  src: string
  height: string
  width: string
}
interface ImageComponentState {
  isOpen: boolean
}
export default class ImageComponent extends React.Component<ImageComponentProps, ImageComponentState> {
  state = { isOpen: false }

  handleShowDialog = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <div>
        <Image
          className="small"
          src={this.props.src}
          onClick={this.handleShowDialog}
          alt="no image"
          height={this.props.height}
          width={this.props.width}
        />
        {this.state.isOpen && (
          <dialog className="dialog image-max" style={{ position: 'absolute' }} open onClick={this.handleShowDialog}>
            <Image
              src={this.props.src}
              onClick={this.handleShowDialog}
              alt="no image"
              height={Number(this.props.height) * 2}
              width={Number(this.props.width) * 2}
            />
          </dialog>
        )}
      </div>
    )
  }
}
