// React + redux
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
// Grid
import { SearchState, IntegratedFiltering, IntegratedPaging, PagingState } from '@devexpress/dx-react-grid'
import { Grid, Table, Toolbar, SearchPanel, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui'
import { ActionColumns, ActionColumn } from '../utils/TableActionButtons'
// Menu
import Menu from '../menu/Menu'
// Login + aplication
import * as LoginActions from '../../store/administrador/login/actions'
import { LoginState } from '../../store/administrador/login/types'
import { ApplicationState } from '../../store'
// TipoVeiculo
import * as TipoVeiculosActions from '../../store/models/tiposveiculos/actions'
import TipoVeiculoController from '../../controller/TipoVeiculoController'
// Pega os icones
import Create from '@material-ui/icons/Create'
import Delete from '@material-ui/icons/Delete'
import TipoVeiculo from '../../models/TipoVeiculo'

// Une todos em props antes de passar ao ao componente
type Props = ApplicationState & LoginState & typeof LoginActions & typeof TipoVeiculosActions & RouteComponentProps

// Determinar cabecalho do grid
const cabecalho = [
  { name: 'alterar' },
  { name: 'excluir' },
  { name: 'modelo', title: 'Modelo' },
  { name: 'marca', title: 'Marca' },
  { name: 'tamanho', title: 'Tamanho' },
]

interface RowTipoVeiculo {
  modelo: string
  marca: string
  tamanho: string
  tipoVeiculo: TipoVeiculo
}

// Valores padrão no grid
const tableMessages = {
  noData: 'Sem registros',
}
const searchMessages = {
  searchPlaceholder: 'Pesquisar',
}

class HomeTipoVeiculos extends Component<Props> {
  constructor(props: Props) {
    super(props)
    if (!this.props.login.data || !this.props.login.isLogged) {
      this.props.history.replace('/')
    }
    // Vincula palavra this a função
    this.goToAdicionar = this.goToAdicionar.bind(this)
    this.handleClickAlterar = this.handleClickAlterar.bind(this)
    this.handleClickExcluir = this.handleClickExcluir.bind(this)
  }

  render(): JSX.Element {
    return this.props.login.isLogged ? this.renderLogged() : this.renderNotLogged()
  }

  renderLogged(): JSX.Element {
    return (
      <div>
        <Menu {...this.props} {...this.props.login}></Menu>
        <div className="conteudo">
          <h1 className="tituloPagina">Tipos de Veículos</h1>
          <button onClick={this.goToAdicionar} className="botaoCrud">
            Novo TipoVeiculo
          </button>
          {this.renderGrid()}
        </div>
      </div>
    )
  }

  goToAdicionar(): void {
    this.props.history.push('tiposVeiculos/adicionar')
  }

  renderNotLogged(): JSX.Element {
    return (
      <div>
        <h1>Você deve estar logado</h1>
      </div>
    )
  }

  // Recupera lista Tipo Serviço
  async componentDidMount(): Promise<void> {
    this.props.limparTiposVeiculos()
    this.props.atualizarTiposVeiculos({
      selecionado: new TipoVeiculo(),
      listaCarregada: true,
      data: await TipoVeiculoController.pegarTodos(),
    })
    this.props.modificarListaCarregadaTiposVeiculos(true)
  }

  private handleClickAlterar(row: RowTipoVeiculo): void {
    this.props.modificarTipoVeiculoSelecionado(row.tipoVeiculo)
    this.props.history.push('tiposVeiculos/' + row.tipoVeiculo.id + '/alterar')
  }

  private handleClickExcluir(row: RowTipoVeiculo): void {
    TipoVeiculoController.deletar(row.tipoVeiculo)
    this.props.removerTipoVeiculo(row.tipoVeiculo)
  }

  renderGrid(): JSX.Element {
    // Busca serviços e estrutura registros
    const listTipoVeiculos = this.props.models.tiposVeiculos ? this.props.models.tiposVeiculos.data : []
    let tuplas: readonly RowTipoVeiculo[] = []
    if (listTipoVeiculos) {
      tuplas = listTipoVeiculos.map(tipoVeiculo => {
        return {
          modelo: tipoVeiculo.modelo,
          marca: tipoVeiculo.marca,
          tamanho: tipoVeiculo.tamanho,
          tipoVeiculo: tipoVeiculo,
        }
      })
    }
    const actionColumns: ActionColumn[] = [
      { columnName: 'alterar', label: 'Abrir modo edição', onClick: this.handleClickAlterar, icon: <Create /> },
      { columnName: 'excluir', label: 'Excluir linha', onClick: this.handleClickExcluir, icon: <Delete /> },
    ]
    // Monta Grid
    return (
      <div className="grid">
        <Grid rows={tuplas} columns={cabecalho}>
          <PagingState defaultCurrentPage={0} defaultPageSize={5} />
          <SearchState defaultValue="" />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table messages={tableMessages} />
          <TableHeaderRow />
          <Toolbar />
          <SearchPanel messages={searchMessages} />
          <ActionColumns actionColumns={actionColumns} />
          <PagingPanel
            pageSizes={[5, 10, 20]}
            messages={{
              showAll: 'Mostrar todos',
              rowsPerPage: 'Linhas por página',
              info: (parameters: { from: number; to: number; count: number }): string =>
                parameters.from + '-' + parameters.to + ' de ' + parameters.count,
            }}
          />
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): ApplicationState => state
const mapDispatchToProps = (dispatch: Dispatch): typeof LoginActions & typeof TipoVeiculosActions =>
  bindActionCreators({ ...LoginActions, ...TipoVeiculosActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HomeTipoVeiculos)
