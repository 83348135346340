import BaseDocument from './BaseDocument'
import { ParceiroInterface, Fatura } from './model_interfaces'
import { firestore } from 'firebase'
import Endereco from './Endereco'

export default class Parceiro extends BaseDocument<Parceiro> implements ParceiroInterface {
  bankAg!: string
  accountType!: string
  bankCC!: string
  tokenParceiro!: string
  nome!: string
  cidade!: string[]
  telefone!: string
  nascimento!: Date
  sexo!: string
  cpf!: string
  internetMovel!: boolean
  deslocamento!: boolean
  meioTransporte!: number[]
  comoConheceu!: number
  mediaNota!: number
  validado!: boolean
  documento!: string
  pparceiro!: firestore.DocumentReference
  uid!: string
  faturas!: Fatura[]
  aptoPontoFixo!: boolean
  aptoLoja!: boolean
  endereco!: Endereco
  pidExtrato!: string
  tiposServicosAptos!: firestore.DocumentReference[]

  constructor() {
    super()
    this.tiposServicosAptos = []
  }

  async fillFromJson(json?: any, recursive?: string[] | undefined): Promise<Parceiro> {
    if (!json) {
      json = this.dados
    }

    recursive = recursive || []
    this.nome = json.nome

    const pparceiro: firestore.DocumentReference = json.pparceiro
    if (!recursive.includes('not_pparceiro') && pparceiro) {
      this.pparceiro = pparceiro
      const snapshot = await pparceiro.get()
      const data = snapshot.data()
      if (data) {
        if (data.cidade) {
          this.cidade = []
          for (const c of data.cidade) {
            this.cidade.push(c)
          }
        }
        this.nome = data.nome
        this.tiposServicosAptos = data.tiposServicosAptos ? data.tiposServicosAptos : []
      }
    }

    this.nascimento = new Date(json.nascimento)
    this.telefone = json.telefone
    this.sexo = json.sexo
    this.cpf = json.cpf

    this.internetMovel = json.internetMovel
    this.deslocamento = json.deslocamento
    this.meioTransporte = json.meioTransporte
    this.comoConheceu = json.comoConheceu
    this.mediaNota = json.mediaNota
    this.validado = json.validado
    this.documento = json.documento
    this.aptoLoja = json.aptoLoja
    this.aptoPontoFixo = json.aptoPontoFixo

    this.tokenParceiro = json.tokenParceiro
    const endereco: firestore.DocumentReference = json.endereco
    if (!recursive.includes('not_endereco') && endereco) {
      this.endereco = await new Endereco().fillFromJson(endereco)
    }
    return this
  }

  canInsertOrUpdate() {
    if (this.nome.length < 3) {
      throw Error('Nome muito curto')
    }
  }
}
