import FirebaseManager from '../utils/FirebaseManager'
import ServicoPrestado from '../models/ServicoPrestado'
import Endereco from '../models/Endereco'
import BaseDocument from '../models/BaseDocument'
import { Situacoes } from '../models/model_interfaces'
import Parceiro from '../models/Parceiro'
import moment from 'moment-timezone'
import ParceiroRepository from './ParceiroRepository'

class ServicoPrestadoRepository {
  async *getAllServicosPrestados(
    cidadeAtua: string,
    recursive?: string[],
  ): AsyncGenerator<ServicoPrestado, void, ServicoPrestado> {
    const queryFalse: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('servicosprestados')
      .where('ativo', '==', false)
      .get()
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('servicosprestados')
      .where('cidade', '==', cidadeAtua)
      .get()
    const queryDocsSnapshot = query.docs.filter(sp => !queryFalse.docs.some(v => v.id === sp.id))
    if (queryDocsSnapshot) {
      for (const doc of queryDocsSnapshot) {
        const servicoPrestado = await new ServicoPrestado().convert(doc, recursive)
        let endereco: BaseDocument<Endereco> | Endereco | undefined
        if (recursive && recursive.includes('endereco')) {
          const end = await doc.data().endereco.get()

          if (end) {
            endereco = await new Endereco().convert(end)
          }
        }

        if (servicoPrestado instanceof ServicoPrestado) {
          if (endereco instanceof Endereco) {
            servicoPrestado.cliente.enderecos = [endereco]
          }

          if (recursive?.includes('parceiro') && !servicoPrestado.parceiro && servicoPrestado.dados.favoritado) {
            servicoPrestado.parceiro = await ParceiroRepository.pegarPorParceiroUid(servicoPrestado.dados.parceiroUid)
          }
          yield servicoPrestado
        }
      }
    }
  }

  async pegarQuantidadeServicosPrestado(cidade: string): Promise<number> {
    const queryFalse: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('servicosprestados')
      .where('ativo', '==', false)
      .get()
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('servicosprestados')
      .where('cidade', '==', cidade)
      .get()
    return query.docs.filter(sp => !queryFalse.docs.some(v => v.id === sp.id)).length
  }

  async getServicoPrestadoById(documentId: string, recursive?: string[]): Promise<ServicoPrestado> {
    const reference: firebase.firestore.DocumentReference = await FirebaseManager.database
      .collection('servicosprestados')
      .doc(documentId)
    const doc = await reference.get()
    if (doc.exists) {
      const servicoPrestado = await new ServicoPrestado().convert(doc, recursive)
      let endereco: BaseDocument<Endereco> | Endereco | undefined
      if (recursive && recursive.includes('endereco')) {
        const end = await doc.data()?.endereco.get()

        if (end) {
          endereco = await new Endereco().convert(end)
        }
      }

      if (servicoPrestado instanceof ServicoPrestado) {
        if (endereco instanceof Endereco) {
          servicoPrestado.cliente.enderecos = [endereco]
        }
        return servicoPrestado
      }
    }
    return new ServicoPrestado()
  }

  async *getServicosPrestadosByDateAndParceiro(
    parceiro: Parceiro,
    dataInicio: Date,
    dataFim: Date,
    recursive?: string[],
  ): AsyncGenerator<ServicoPrestado, void, ServicoPrestado> {
    const docParceiro: firebase.firestore.DocumentSnapshot = await FirebaseManager.database
      .collection('usuarios')
      .doc(parceiro.uid)
      .get()
    const parceiroData = docParceiro.data()
    if (!docParceiro.exists || !parceiroData) {
      throw new Error('Parceiro não existe')
    }
    const querySituacoes: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('situacoes')
      .where('estado', '==', Situacoes.FINALIZADO)
      .where('pid', '==', parceiroData.publicUid.situacoes)
      .get()
    const queryFalse: firebase.firestore.QuerySnapshot = await FirebaseManager.database
      .collection('servicosprestados')
      .where('ativo', '==', false)
      .get()
    const query: firebase.firestore.QuerySnapshot = await FirebaseManager.database.collection('servicosprestados').get()
    const queryDocsSnapshot = query.docs.filter(sp => !queryFalse.docs.some(v => v.id === sp.id))
    if (queryDocsSnapshot) {
      for (const doc of queryDocsSnapshot) {
        const servicoData = doc.data()
        if (servicoData && servicoData.fechadoExtrato) {
          continue
        }
        if (
          !servicoData ||
          !querySituacoes.docs.some(r =>
            (servicoData.situacoes as firebase.firestore.DocumentReference[])
              .map<string>(value => value.id)
              .includes(r.id),
          )
        ) {
          continue
        }
        let hasContinue = true
        for (const situacao of querySituacoes.docs) {
          for (const situacaoServico of servicoData.situacoes as firebase.firestore.DocumentReference[]) {
            if (situacao.id === situacaoServico.id) {
              const situacaoData = situacao.data()
              if (
                situacaoData &&
                moment(situacaoData.dataHora).isAfter(moment(dataInicio).subtract(1, 'day')) &&
                moment(situacaoData.dataHora).isBefore(moment(dataFim).add(1, 'day'))
              ) {
                hasContinue = false
              }
            }
          }
        }
        if (hasContinue) {
          continue
        }
        const servicoPrestado = await new ServicoPrestado().convert(doc, recursive)
        let endereco: BaseDocument<Endereco> | Endereco | undefined
        if (recursive && recursive.includes('endereco')) {
          const end = await doc.data().endereco.get()

          if (end) {
            endereco = await new Endereco().convert(end)
          }
        }

        if (servicoPrestado instanceof ServicoPrestado) {
          if (endereco instanceof Endereco) {
            servicoPrestado.cliente.enderecos = [endereco]
          }
          servicoPrestado.setAtualTipoServico()
          yield servicoPrestado
        }
      }
    }
  }
}

export default new ServicoPrestadoRepository()
