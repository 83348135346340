import CidadeAtua from '../../../models/CidadeAtua'

export enum CidadesAtuaTypes {
  ATUALIZAR_CIDADES_ATUA = '@cidadeAtua/ATUALIZAR_TODOS',
  ADICIONAR_CIDADE_ATUA = '@cidadeAtua/ADICIONAR',
  REMOVER_CIDADE_ATUA = '@cidadeAtua/REMOVER',
  MODIFICAR_CIDADE_ATUA_SELECIONADO = '@cidadeAtua/MODIFICAR_SELECIONADO',
  MODIFICAR_LISTA_CARREGADA = '@cidadeAtua/MODIFICAR_LISTA_CARREGADA',
  LIMPAR_CIDADES_ATUA = '@cidadeAtua/LIMPAR',
}

export interface CidadesAtuaState {
  readonly data: CidadeAtua[]
  readonly selecionado: CidadeAtua
  readonly listaCarregada: boolean
}

export interface AtualizarCidadesAtua {
  type: typeof CidadesAtuaTypes.ATUALIZAR_CIDADES_ATUA
}

export interface AdicionarCidadeAtua {
  type: typeof CidadesAtuaTypes.ADICIONAR_CIDADE_ATUA
}

export interface RemoverCidadeAtua {
  type: typeof CidadesAtuaTypes.REMOVER_CIDADE_ATUA
}

export interface LimparCidadesAtua {
  type: typeof CidadesAtuaTypes.LIMPAR_CIDADES_ATUA
}

export interface ModificarListaCarregadaCidadesAtua {
  type: typeof CidadesAtuaTypes.MODIFICAR_LISTA_CARREGADA
}

export interface ModificarCidadeAtuaSelecionado {
  type: typeof CidadesAtuaTypes.MODIFICAR_CIDADE_ATUA_SELECIONADO
}
