import ServicoPrestado from '../../../models/ServicoPrestado'

export enum ServicosPrestadosTypes {
  ATUALIZAR_TODOS_SERVICOS = '@servicosprestados/ATUALIZAR_TODOS_SERVICOS',
  ADICIONAR_SERVICO_PRESTADO = '@servicosprestados/ADICIONAR_SERVICO_PRESTADO',
  MODIFICAR_SERVICO_PRESTADO_SELECIONADO = '@servicosprestados/MODIFICAR_SERVICO_PRESTADO_SELECIONADO',
  MODIFICAR_LISTA_CARREGADA = '@servicosprestados/MODIFICAR_LISTA_CARREGADA',
}

export interface ServicosPrestadosState {
  readonly data: ServicoPrestado[]
  readonly selecionado: ServicoPrestado
  readonly listaCarregada: boolean
}

export interface AtualizarTodosServicos {
  type: typeof ServicosPrestadosTypes.ATUALIZAR_TODOS_SERVICOS
}

export interface AdicionarServicoPrestado {
  type: typeof ServicosPrestadosTypes.ADICIONAR_SERVICO_PRESTADO
}

export interface ModificarServicoPrestadoSelecionado {
  type: typeof ServicosPrestadosTypes.MODIFICAR_SERVICO_PRESTADO_SELECIONADO
}

export interface ModificarListaCarregadaServicoPrestado {
  type: typeof ServicosPrestadosTypes.MODIFICAR_LISTA_CARREGADA
}
