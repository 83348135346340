import FirebaseManager from '../utils/FirebaseManager'

class ConfiguracoesRepository {
  async alterarCupom(desconto: number): Promise<void> {
    const document = await FirebaseManager.database
      .collection('configuracoes')
      .doc('cupom')
      .get()
    const map = {
      desconto,
    }
    await document.ref.update(map)
  }

  async pegarCupom(): Promise<number> {
    const document = await FirebaseManager.database
      .collection('configuracoes')
      .doc('cupom')
      .get()

    return document.data()!.desconto || 20
  }
}

export default new ConfiguracoesRepository()
