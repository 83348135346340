import BaseDocument from './BaseDocument'
import { TipoServicoInterface, InfoTipoServico } from './model_interfaces'

export default class TipoServico extends BaseDocument<TipoServico> implements TipoServicoInterface {
  infosTipoServico!: InfoTipoServico[]
  descricao!: string
  dica!: string
  cidade!: string
  ativo!: boolean
  atualInfoTipoServico!: InfoTipoServico
  pai!: TipoServico
  paiRef!: firebase.firestore.DocumentReference | null
  aptoLoja!: boolean
  aptoPontoFixo!: boolean
  aptoDelivery!: boolean

  async fillFromJson(json?: any, recursive?: string[] | undefined): Promise<TipoServico> {
    if (!json) {
      json = this.dados
    }

    this.descricao = json.descricao
    this.dica = json.dica
    this.ativo = json.ativo
    this.aptoDelivery = json.aptoDelivery
    this.cidade = json.cidade
    this.aptoPontoFixo = json.aptoPontoFixo
    this.aptoLoja = json.aptoLoja
    this.infosTipoServico = []
    for (const info of json.infosTipoServico) {
      this.infosTipoServico.push(info as InfoTipoServico)
    }

    const paiRef: firebase.firestore.DocumentReference = json.paiRef
    if (paiRef !== undefined && recursive && recursive.includes('pai')) {
      const paiRefSnap = await paiRef.get()
      const pai = await new TipoServico().convert(paiRefSnap, recursive)
      if (pai instanceof TipoServico) {
        this.pai = pai
      }
    }
    this.paiRef = paiRef

    return this
  }

  canInsertOrUpdate() {
    if (this.descricao.length < 3) {
      throw Error('Nome muito curto')
    }
    for (const info of this.infosTipoServico) {
      if (!['Pequeno', 'Medio', 'Grande'].includes(info.tamanho)) {
        throw Error('Tamanho diferente de Pequeno, Medio e Grande')
      }
      if (info.tempoMedio <= 0) {
        throw Error('Tempo medio menor ou igual a zero')
      }
      if (info.valor <= 0) {
        throw Error('Valor menor ou igual a zero')
      }
    }
  }
}
