import Parceiro from '../models/Parceiro'
import ServicoPrestado from '../models/ServicoPrestado'
import ServicoPrestadoRepository from '../repositories/ServicoPrestadoRepository'

class ServicoPrestadoController {
  filtrarPorParceiroEData(
    parceiro: Parceiro,
    dataInicio: Date,
    dataFim: Date,
    recursive?: string[],
  ): AsyncGenerator<ServicoPrestado, void, ServicoPrestado> {
    return ServicoPrestadoRepository.getServicosPrestadosByDateAndParceiro(parceiro, dataInicio, dataFim, recursive)
  }

  pegarQuantidadeServicosPrestados(cidade: string): Promise<number> {
    return ServicoPrestadoRepository.pegarQuantidadeServicosPrestado(cidade)
  }
}

export default new ServicoPrestadoController()
