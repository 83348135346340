import TipoVeiculo from '../../../models/TipoVeiculo'

export enum TiposVeiculosTypes {
  ATUALIZAR_TIPO_VEICULOS = '@tiposVeiculos/ATUALIZAR_TODOS',
  ADICIONAR_TIPO_VEICULO = '@tiposVeiculos/ADICIONAR',
  REMOVER_TIPO_VEICULO = '@tiposVeiculos/REMOVER',
  MODIFICAR_TIPO_VEICULO_SELECIONADO = '@tiposVeiculos/MODIFICAR_SELECIONADO',
  MODIFICAR_LISTA_CARREGADA = '@tiposVeiculos/MODIFICAR_LISTA_CARREGADA',
  LIMPAR_TIPO_VEICULOS = '@tiposVeiculos/LIMPAR',
}

export interface TiposVeiculosState {
  readonly data: TipoVeiculo[]
  readonly selecionado: TipoVeiculo
  readonly listaCarregada: boolean
}

export interface AtualizarTiposVeiculos {
  type: typeof TiposVeiculosTypes.ATUALIZAR_TIPO_VEICULOS
}

export interface AdicionarTipoVeiculo {
  type: typeof TiposVeiculosTypes.ADICIONAR_TIPO_VEICULO
}

export interface RemoverTipoVeiculo {
  type: typeof TiposVeiculosTypes.REMOVER_TIPO_VEICULO
}

export interface LimparTiposVeiculos {
  type: typeof TiposVeiculosTypes.LIMPAR_TIPO_VEICULOS
}

export interface ModificarListaCarregadaTiposVeiculos {
  type: typeof TiposVeiculosTypes.MODIFICAR_LISTA_CARREGADA
}

export interface ModificarTipoVeiculoSelecionado {
  type: typeof TiposVeiculosTypes.MODIFICAR_TIPO_VEICULO_SELECIONADO
}
