// React + redux
import React, { Component } from 'react'
import { RouteComponentProps, Route, Switch } from 'react-router-dom'
import { Home } from './Home'
// Login + aplication

// Une todos em props antes de passar ao ao componente
type Props = RouteComponentProps

export class LandingPage extends Component<Props> {
  render(): JSX.Element {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
      </Switch>
    )
  }
}
