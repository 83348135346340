import BaseDocument from './BaseDocument'
import { EnderecoInterface } from './model_interfaces'

export default class Endereco extends BaseDocument<Endereco> implements EnderecoInterface {
  apelido!: string
  endereco1!: string
  endereco2!: string
  cep!: string
  bairro!: string
  cidade!: string
  complemento!: string
  estado!: string
  pontoFixo!: boolean
  loja!: boolean
  async fillFromJson(json?: any, recursive?: string[] | undefined): Promise<Endereco> {
    if (!json) {
      json = this.dados
    }

    this.estado = json.estado
    this.endereco1 = json.endereco1
    this.endereco2 = json.endereco2
    this.pontoFixo = json.pontoFixo
    this.loja = json.loja
    this.cep = json.cep
    this.bairro = json.bairro
    this.cidade = json.cidade
    this.apelido = json.apelido
    this.complemento = json.complemento
    return this
  }

  formatarEndereco(): string {
    const complemento = this.complemento ? ' - ' + this.complemento : ''
    return (
      this.endereco1 +
      ', ' +
      this.endereco2 +
      ', ' +
      this.bairro +
      complemento +
      '. ' +
      this.cidade +
      ' - ' +
      this.estado
    )
  }
}
