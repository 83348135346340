import { action } from 'typesafe-actions'
import {
  TiposVeiculosState,
  TiposVeiculosTypes,
  AtualizarTiposVeiculos,
  AdicionarTipoVeiculo,
  LimparTiposVeiculos,
  ModificarTipoVeiculoSelecionado,
  ModificarListaCarregadaTiposVeiculos,
  RemoverTipoVeiculo,
} from './types'
import TipoVeiculo from '../../../models/TipoVeiculo'
export const atualizarTiposVeiculos = (data: TiposVeiculosState): AtualizarTiposVeiculos =>
  action(TiposVeiculosTypes.ATUALIZAR_TIPO_VEICULOS, data)

export const adicionarTipoVeiculo = (newTipoVeiculo: TipoVeiculo): AdicionarTipoVeiculo => {
  return action(TiposVeiculosTypes.ADICIONAR_TIPO_VEICULO, newTipoVeiculo)
}

export const removerTipoVeiculo = (newTipoVeiculo: TipoVeiculo): RemoverTipoVeiculo => {
  return action(TiposVeiculosTypes.REMOVER_TIPO_VEICULO, newTipoVeiculo)
}

export const limparTiposVeiculos = (): LimparTiposVeiculos => {
  return action(TiposVeiculosTypes.LIMPAR_TIPO_VEICULOS)
}

export const modificarTipoVeiculoSelecionado = (newTipoVeiculo: TipoVeiculo): ModificarTipoVeiculoSelecionado => {
  return action(TiposVeiculosTypes.MODIFICAR_TIPO_VEICULO_SELECIONADO, newTipoVeiculo)
}

export const modificarListaCarregadaTiposVeiculos = (estaCarregada: boolean): ModificarListaCarregadaTiposVeiculos => {
  return action(TiposVeiculosTypes.MODIFICAR_LISTA_CARREGADA, estaCarregada)
}
